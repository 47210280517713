var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { useTranslation } from 'react-i18next';
import HelperFunctions from 'utils/HelperFunctions';
var ActivityValidationLevelSelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'validationLevel' : _b, value = _a.value, _c = _a.label, label = _c === void 0 ? 'Уровень валидации' : _c, handleChange = _a.handleChange;
    var formatValidationLevel = HelperFunctions.formatValidationLevel;
    var _d = useTranslation(), t = _d.t, i18n = _d.i18n;
    return (_jsxs(FormControl, __assign({ className: "w-full" }, { children: [_jsx(InputLabel, { children: label }, void 0), _jsx(Select, __assign({ required: true, value: value, onChange: function (_event, value) {
                    if (value) {
                        handleChange(name, value.props.value);
                    }
                }, label: t(label), input: _jsx(OutlinedInput, { className: "\n\t\tbg-bg-container-inner-lt \n\t\tdark:bg-bg-container-inner-dk  \n\t\tdark:text-white", label: t(label) }, void 0) }, { children: [ActivityValidationLevels.Base, ActivityValidationLevels.SportEvent].map(function (v) {
                    return (_jsx(MenuItem, __assign({ value: v }, { children: formatValidationLevel(v, i18n.language) }), ActivityValidationLevels[v]));
                }) }), void 0)] }), void 0));
};
export default ActivityValidationLevelSelect;
