import { Sports } from 'common/enums/sport/sports.enum';
import * as Yup from 'yup';
var activityValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(1)
        .max(256, 'activityValidationSchema_name_max')
        .required('activityValidationSchema_name_required'),
    approved: Yup.boolean().required('activityValidationSchema_approved_required'),
    distance: Yup.number()
        .min(0)
        .max(10000000, 'activityValidationSchema_distance_max')
        .required('activityValidationSchema_distance_required'),
    movingTime: Yup.number()
        .min(0)
        .max(10000000, 'activityValidationSchema_movingTime_max')
        .required('activityValidationSchema_movingTime_required'),
    averageTempo: Yup.number()
        .min(0)
        .max(1000, 'activityValidationSchema_averageTempo_max')
        .required('activityValidationSchema_averageTempo_required'),
    userMessage: Yup.string()
        .trim()
        .min(1)
        .max(512, 'activityValidationSchema_userMessage_max')
        .required('activityValidationSchema_userMessage_required'),
    startDate: Yup.date()
        .typeError('createActivityValidationSchema_startDate_type')
        .max(new Date())
        .required('createActivityValidationSchema_startDate_required'),
    validationMessage: Yup.string().trim().nullable().min(0).max(512, 'activityValidationSchema_validationMessage_max'),
    adminValidationMessage: Yup.string()
        .trim()
        .nullable()
        .min(0)
        .max(256, 'activityValidationSchema_adminValidationMessage_max'),
    sport: Yup.mixed().oneOf(Object.values(Sports)).required('activityValidationSchema_sport_required'),
    duplicatesActivityId: Yup.string().uuid('activityValidationSchema_duplicatesActivityId_uuid').nullable()
});
export default activityValidationSchema;
