var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Tab, Tabs } from '@mui/material';
import { CREATE_ACTIVITY_FROM_GPX } from 'common/graphql/mutations/activity/createActivity/createActivityFromGpx';
import LoadingFilesForm from 'components/Forms/LoadingFilesForm';
import TabPanel from 'components/TabPanel/TabPanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateActivityManualForm from './CreateActivityManualForm/CreateActivityManualForm';
import SelectActivitiesSourceForm from './SelectActivitiesSourceForm/SelectActivitiesSourceForm';
var AddActivitiesDialog = function (_a) {
    var polarAccountId = _a.polarAccountId, stravaAccountId = _a.stravaAccountId, handleClose = _a.handleClose, userId = _a.userId, userActivitiesQueueRequests = _a.userActivitiesQueueRequests;
    var _b = useState(undefined), loadingActivities = _b[0], setLoadingActivities = _b[1];
    var _c = useState(0), tabValue = _c[0], setTabValue = _c[1];
    var t = useTranslation().t;
    var handleChangeTab = function (_event, tabValue) {
        setTabValue(tabValue);
    };
    var executableMutation = function (file) {
        return useMutation(CREATE_ACTIVITY_FROM_GPX, {
            variables: {
                file: file
            }
        });
    };
    return (_jsxs("div", __assign({ className: "flex flex-col pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Добавить тренировки') }), void 0), _jsxs(Tabs, __assign({ sx: { height: '4.4rem' }, value: tabValue, onChange: handleChangeTab }, { children: [_jsx(Tab, { className: "xs:text-xs md:text-xs", sx: { height: '4.4rem' }, label: t('Из источника') }, void 0), _jsx(Tab, { className: "xs:text-xs md:text-xs", sx: { height: '4.4rem' }, label: t('Вручную') }, void 0)] }), void 0), _jsxs("div", __assign({ className: "pt-20" }, { children: [_jsx(TabPanel, __assign({ value: tabValue, index: 0 }, { children: loadingActivities ? (_jsx(LoadingFilesForm, { formText: t('Загрузка GPX файлов'), loadingFiles: loadingActivities, executableMutation: executableMutation }, void 0)) : (_jsx(SelectActivitiesSourceForm, { userId: userId, polarAccountId: polarAccountId, stravaAccountId: stravaAccountId, setLoadingActivities: setLoadingActivities, userActivitiesQueueRequests: userActivitiesQueueRequests }, void 0)) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 1 }, { children: _jsx(CreateActivityManualForm, { userId: userId, handleClose: handleClose }, void 0) }), void 0)] }), void 0)] }), void 0));
};
export default AddActivitiesDialog;
