var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import _ from 'lodash';
var DateRangeFilterItem = function (_a) {
    var name = _a.name, value = _a.value, setRange = _a.setRange, filterBy = _a.filterBy, activityEndDate = _a.activityEndDate;
    var setDateRange = function (value) {
        var _a;
        setRange('dateRange', value);
        var setValues = function (year, month, period) {
            setRange('year', year);
            setRange('month', month);
            setRange('period', period);
        };
        switch (value) {
            case DateRangeFilterItems.allTime: {
                setValues();
                break;
            }
            case DateRangeFilterItems.year: {
                setValues(filterBy.year || new Date().getFullYear());
                break;
            }
            case DateRangeFilterItems.month: {
                setValues(filterBy.year || new Date().getFullYear(), (_a = filterBy.month) !== null && _a !== void 0 ? _a : new Date().getMonth());
                break;
            }
            case DateRangeFilterItems.period: {
                setValues(undefined, undefined, {
                    startDate: activityEndDate
                        ? _.min([new Date(activityEndDate), new Date()]) || new Date()
                        : new Date(),
                    endDate: activityEndDate
                        ? _.min([new Date(activityEndDate), new Date()]) || new Date()
                        : new Date(),
                    key: 'selection'
                });
                break;
            }
            default: {
                break;
            }
        }
    };
    return (_jsx("div", __assign({ className: clsx("tw-date-range-filter-item", value && filterBy.dateRange === value && "selected"), onClick: function () {
            setDateRange(value);
        } }, { children: name }), void 0));
};
export default DateRangeFilterItem;
