var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import * as PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PageCardedHeader from './PageCardedHeader';
import PageCardedSidebar from './PageCardedSidebar';
var drawerWidth = 240;
var headerHeight = 200;
var toolbarHeight = 56;
var headerContentHeight = headerHeight - toolbarHeight;
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e;
    return ({
        root: {},
        topBg: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            height: headerHeight,
            background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.main + ' 100%)',
            backgroundSize: 'cover',
            pointerEvents: 'none'
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            /*       padding: '0 2.4rem', */
            flex: '1 1 100%',
            zIndex: 2,
            maxWidth: '100%',
            minWidth: 0,
            minHeight: 0
        },
        header: {
            height: headerContentHeight,
            minHeight: headerContentHeight,
            maxHeight: headerContentHeight,
            display: 'flex',
            color: theme.palette.primary.contrastText
        },
        headerSidebarToggleButton: {
            color: theme.palette.primary.contrastText
        },
        toolbar: {
            height: toolbarHeight,
            minHeight: toolbarHeight,
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid ' + theme.palette.divider
        },
        content: {
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            '-webkit-overflow-scrolling': 'touch'
        },
        sidebarWrapper: {
            position: 'absolute',
            backgroundColor: 'transparent',
            zIndex: 5,
            overflow: 'hidden',
            '&.permanent': (_a = {},
                _a[theme.breakpoints.up('md')] = {
                    zIndex: 1,
                    position: 'relative'
                },
                _a)
        },
        sidebar: {
            position: 'absolute',
            '&.permanent': (_b = {},
                _b[theme.breakpoints.up('md')] = {
                    backgroundColor: 'transparent',
                    position: 'relative',
                    border: 'none',
                    overflow: 'hidden'
                },
                _b),
            width: drawerWidth,
            height: '100%'
        },
        contentCard: {},
        leftSidebar: {},
        rightSidebar: {},
        sidebarHeader: {
            height: headerHeight,
            minHeight: headerHeight,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
            '&.permanent': (_c = {},
                _c[theme.breakpoints.up('lg')] = {
                    backgroundColor: 'transparent'
                },
                _c)
        },
        sidebarContent: (_d = {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                color: theme.palette.text.primary
            },
            _d[theme.breakpoints.up('lg')] = {
                overflow: 'auto',
                '-webkit-overflow-scrolling': 'touch'
            },
            _d[theme.breakpoints.down('md')] = {
                backgroundColor: 'transparent'
            },
            _d),
        sidebarPaper: (_e = {},
            _e[theme.breakpoints.up('md')] = {
                minHeight: '250px'
            },
            _e.maxHeight = 'max-content',
            _e),
        backdrop: {
            position: 'absolute'
        }
    });
});
var PageCarded = React.forwardRef(function (props, ref) {
    var location = useLocation();
    var leftSidebarRef = useRef(null);
    var rightSidebarRef = useRef(null);
    var rootRef = useRef(null);
    var classes = useStyles(props);
    var isRightSidebar = props.rightSidebarHeader || props.rightSidebarContent;
    var isLeftSidebar = props.leftSidebarHeader || props.leftSidebarContent;
    /*     React.useImperativeHandle(ref, () => {
            return {
                rootRef           : rootRef,
                toggleLeftSidebar : () => {
                    leftSidebarRef.current?.toggleSidebar()
                },
                toggleRightSidebar: () => {
                    rightSidebarRef.current.toggleSidebar()
                }
            }
        }); */
    return (_jsxs("div", __assign({ className: clsx('flex flex-row justify-center min-h-full h-auto relative bg-bg-container-outer-lt dark:bg-bg-container-outer-dk', classes.root, props.className, props.innerScroll && 'flex-auto h-full'), ref: rootRef }, { children: [_jsx("div", { className: classes.topBg }, void 0), _jsxs(Box, __assign({ className: clsx('flex-col page container w-full max-w-1570 flex grow xs:px-8 md:px-12 xl:px-24 xs:mt-8 md:mt-12') }, { children: [props.breadcrumbs && location.key && _jsx("div", __assign({ className: clsx('xs:mb-4') }, { children: props.breadcrumbs }), void 0), _jsxs("div", __assign({ className: clsx('w-full flex grow justify-center md:flex-row', isRightSidebar ? 'xs:flex-col-reverse' : 'xs:flex-col') }, { children: [isLeftSidebar && (_jsx(PageCardedSidebar, { position: "left", header: props.leftSidebarHeader, content: props.leftSidebarContent, variant: props.leftSidebarVariant || 'permanent', mobile: props.mobileSidebarContent, innerScroll: props.innerScroll, classes: classes, ref: leftSidebarRef, rootRef: rootRef }, void 0)), _jsxs("div", __assign({ className: clsx(classes.contentWrapper, isLeftSidebar &&
                                    (props.leftSidebarVariant === undefined || props.leftSidebarVariant === 'permanent') &&
                                    'lg:pl-0', isRightSidebar &&
                                    (props.rightSidebarVariant === undefined ||
                                        props.rightSidebarVariant === 'permanent') &&
                                    'lg:pr-0') }, { children: [_jsx(PageCardedHeader, { header: props.header, classes: classes }, void 0), _jsxs("div", __assign({ className: clsx(classes.contentCard, 'flex flex-auto flex-col bg-bg-container-inner-lt dark:bg-bg-container-inner-dk rounded-t-8 shadow', props.innerScroll && 'inner-scroll') }, { children: [props.contentToolbar && _jsx("div", __assign({ className: classes.toolbar }, { children: props.contentToolbar }), void 0), props.content && (_jsx(Scrollbars, __assign({ className: classes.content, enable: props.innerScroll, scrollToTopOnChildChange: props.innerScroll }, { children: props.content }), void 0))] }), void 0)] }), void 0), isRightSidebar && (_jsx(PageCardedSidebar, { position: "right", header: props.rightSidebarHeader, mobile: props.mobileSidebarContent, content: props.rightSidebarContent, variant: props.rightSidebarVariant || 'permanent', innerScroll: props.innerScroll, classes: classes, ref: rightSidebarRef, rootRef: rootRef }, void 0))] }), void 0)] }), void 0)] }), void 0));
});
PageCarded.propTypes = {
    className: PropTypes.string || undefined,
    rightSidebarHeader: PropTypes.node,
    rightSidebarContent: PropTypes.node,
    rightSidebarVariant: PropTypes.node,
    leftSidebarHeader: PropTypes.node,
    leftSidebarContent: PropTypes.node,
    leftSidebarVariant: PropTypes.node,
    mobileSidebarContent: PropTypes.bool,
    breadcrumbs: PropTypes.node,
    header: PropTypes.node,
    content: PropTypes.node,
    contentToolbar: PropTypes.node,
    innerScroll: PropTypes.bool
};
PageCarded.defaultProps = {};
export default React.memo(PageCarded);
