var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import Animate from 'components/Animate/Animate';
import DividerWithText from 'components/DividerWithText/DividerWithText';
import PasswordField from 'components/Fields/PasswordField';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cleanRegisterError, createUser } from 'store/actions';
import history from 'store/history';
import SocialSignIn from '../LoginPage/SocialSignIn/SocialSignIn';
var RegisterForm = function () {
    var _a;
    var dispatch = useDispatch();
    var register = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.register;
    });
    var t = useTranslation().t;
    useEffect(function () {
        return function () {
            //componentWillUnmount удалить ошибку
            dispatch(cleanRegisterError());
        };
    }, []);
    var handleChangeWrapper = function (e) {
        var _a;
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
        //если при регистрации была ошибка,
        // то при внесении изменений в форму ошибка удаляется
        if ((_a = register.error) === null || _a === void 0 ? void 0 : _a.errorCode) {
            dispatch(cleanRegisterError());
        }
    };
    var defaultFormState = {
        email: '',
        password: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(createUser(value));
        },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: t('Регистрация') }), void 0) }), void 0), _jsx(Animate, __assign({ delay: 1 }, { children: _jsxs(Box, __assign({ sx: { width: '32rem', mb: '0.5rem' } }, { children: [_jsx(SocialSignIn, {}, void 0), _jsx(DividerWithText, { content: t('или') }, void 0)] }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { width: '32rem' } }, { children: [_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(TextField, { label: "E-mail", name: "email", value: formik.values['email'], onChange: handleChangeWrapper, error: Boolean(formik.errors['email']), helperText: formik.errors['email'], autoFocus: true }, void 0), _jsx(PasswordField, { id: "password", title: t('Создайте пароль'), value: formik.values['password'], name: "password", onChange: handleChangeWrapper, isError: Boolean(formik.errors['password']), helperText: formik.errors['password'] }, void 0)] }), void 0), ((_a = register.error) === null || _a === void 0 ? void 0 : _a.errorCode) && (_jsx(Box, __assign({ sx: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '32rem',
                                        color: 'red'
                                    } }, { children: _jsx(Typography, { children: t(register.error.errorCode) }, void 0) }), void 0)), _jsx(Box, __assign({ sx: { width: '32rem', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '32rem' }, type: "submit" }, { children: t('Регистрация') }), void 0) }), void 0)] }), void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', mt: '4.5rem' } }, { children: [_jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx(Divider, {}, void 0) }), void 0), _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'space-between', mt: '1rem' } }, { children: [_jsxs("span", { children: [t('Уже есть аккаунт'), "?"] }, void 0), _jsx(Link, __assign({ to: "/login" }, { children: t('Войти') }), void 0)] }), void 0), _jsxs("div", __assign({ className: "text-center mt-24 text-12" }, { children: [t('Нажимая на кнопку, ты принимаешь'), _jsx("br", {}, void 0), _jsx(Link, __assign({ to: "#", onClick: function () {
                                            history.push("/privacy");
                                        } }, { children: t('политику конфиденциальности') }), void 0), ' ', ' ' + t('и') + ' ', _jsx(Link, __assign({ to: "/terms" }, { children: t('условия использования') }), void 0), "."] }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default RegisterForm;
