var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, Typography } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_ACTIVITY } from 'common/graphql/mutations/activity/delete-activity';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
var DeleteActivityDialog = function (_a) {
    var activity = _a.activity, handleClose = _a.handleClose;
    var history = useHistory();
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var deleteActivity = function (data) {
        return useMutation(DELETE_ACTIVITY, {
            variables: {
                id: data
            }
        });
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Тренировка удалена'),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
        history.goBack();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(deleteActivity, onSuccess, onError).handleExecuteMutation;
    var handleDelete = function (data) {
        handleExecuteMutation(data);
    };
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Удалить тренировку') }), void 0), _jsxs(Typography, __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: [t('Вы уверены, что хотите удалить тренировку'), " ", activity === null || activity === void 0 ? void 0 : activity.name, " ?", ' ', t('Удаление тренировки повлечёт за собой перерасчёт вашего зачёта всех активных турнирных таблиц, в которых она зачтена.')] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleDelete(activity === null || activity === void 0 ? void 0 : activity.id);
                        }, type: "primary sm" }, { children: t('Удалить') }), void 0)] }), void 0)] }), void 0));
};
export default DeleteActivityDialog;
