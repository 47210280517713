var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_NOTIFICATIONN_INPUT } from 'common/graphql/mutations/notification/create-notification-input';
import { NOTIFICATION_INPUTS } from 'common/graphql/queries/notification/notification-inputs';
import { NOTIFICATION_SUBTYPES } from 'common/graphql/queries/notification/notification-subtypes';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import supportRequestSchema from 'common/validationSchemas/supportRequestSchema';
import DraftEditor from 'components/DraftEditor/DraftEditor';
import { useFormik } from 'formik';
import linkifyHtml from 'linkify-html';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var NotificationInputAddNew = function () {
    var _a, _b, _c;
    var _d = useState(''), value = _d[0], setValue = _d[1];
    var _e = useState(undefined), valueObj = _e[0], setValueObj = _e[1];
    var _f = useState([]), options = _f[0], setOptions = _f[1];
    var _g = useState([]), optionObjects = _g[0], setOptionObjects = _g[1];
    var _h = useState(false), isAddNew = _h[0], setIsAddNew = _h[1];
    var _j = useState({}), defaultFormState = _j[0], setDefaultFormState = _j[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _k = useLazyQuery(NOTIFICATION_SUBTYPES), getNotificationSubTypes = _k[0], _l = _k[1], data = _l.data, loading = _l.loading;
    var createNotificationInput = function (data) {
        return useMutation(CREATE_NOTIFICATIONN_INPUT, {
            variables: {
                createNotificationInputData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: NOTIFICATION_INPUTS
                },
            ]
        });
    };
    useEffect(function () {
        getNotificationSubTypes({
            variables: {
                isSystemOne: false
            }
        });
    }, [isAddNew]);
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.notificationSubTypes) === null || _a === void 0 ? void 0 : _a.length) {
            setOptions((_b = data === null || data === void 0 ? void 0 : data.notificationSubTypes) === null || _b === void 0 ? void 0 : _b.map(function (o) { return o.name; }));
            setOptionObjects(data === null || data === void 0 ? void 0 : data.notificationSubTypes);
        }
    }, [data]);
    useEffect(function () {
        var _a, _b, _c;
        if (value) {
            var valueObj_1 = optionObjects.filter(function (o) { return o.name === value; })[0];
            setValueObj(valueObj_1);
            var userTemplateVariables = valueObj_1 && ((_b = (_a = valueObj_1.defaultTemplate) === null || _a === void 0 ? void 0 : _a.requiredTemplateVariables) === null || _b === void 0 ? void 0 : _b.length) > 0
                ? (_c = valueObj_1.defaultTemplate) === null || _c === void 0 ? void 0 : _c.requiredTemplateVariables.filter(function (o) { return !o.resolvedBySystem; })
                : [];
            setDefaultFormState({
                name: '',
                inputData: __assign({}, Object.fromEntries(userTemplateVariables.map(function (o) {
                    return [o.name, ''];
                })))
            });
        }
    }, [value]);
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u0418\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F \u0441\u043E\u0437\u0434\u0430\u043D\u044B \u0443\u0441\u043F\u0435\u0448\u043D\u043E",
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createNotificationInput, onSuccess, onError).handleExecuteMutation;
    var handleReset = function () {
        setIsAddNew(false);
        formik.resetForm();
    };
    var handleSubmit = function (data) {
        var _a;
        // console.log(data);
        var options = { defaultProtocol: 'https' };
        ((_a = data === null || data === void 0 ? void 0 : data.inputData) === null || _a === void 0 ? void 0 : _a.html) && (data.inputData.html = linkifyHtml(data.inputData.html, options));
        handleExecuteMutation(__assign({ notificationSubTypeId: valueObj === null || valueObj === void 0 ? void 0 : valueObj.id }, data));
        formik.resetForm();
        setIsAddNew(false);
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: supportRequestSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: isAddNew ? (_jsxs("form", __assign({ className: "flex flex-col space-y-10", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx(Autocomplete, { value: value, filterOptions: function (x) { return x; }, options: options, disableClearable: false, renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", label: 'Выберите подтип оповещения' }, params), void 0)); }, freeSolo: true, onChange: function (event, newValue) {
                        setValue(newValue);
                    }, loading: loading }, void 0), valueObj && (_jsx(TextField, { className: "", label: "\u041F\u0440\u0438\u0434\u0443\u043C\u0430\u0439\u0442\u0435 \u0443\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u043E\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u043D\u044B\u043C \u0434\u0430\u043D\u043D\u044B\u043C", name: "name", value: formik.values['name'] || '', onChange: handleChangeWrapper, error: Boolean(formik.errors['name']), helperText: formik.errors['name'] }, void 0)), valueObj && ((_b = (_a = valueObj.defaultTemplate) === null || _a === void 0 ? void 0 : _a.requiredTemplateVariables) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: (_c = valueObj.defaultTemplate) === null || _c === void 0 ? void 0 : _c.requiredTemplateVariables.filter(function (templateVariable) { return !templateVariable.resolvedBySystem; }).map(function (templateVariable) {
                        var _a;
                        return templateVariable.name === 'subject' ? (_jsx(TextField, { className: "w-full", label: "\u0422\u0435\u043C\u0430 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F", autoFocus: true, name: "subject", value: formik.values['subject'], onChange: function (e) {
                                formik.setFieldError('subject', undefined);
                                formik.setFieldValue("inputData.subject", e.target.value);
                            }, error: Boolean(formik.errors['subject']), helperText: formik.errors['subject'] }, templateVariable.id)) : (_jsx(DraftEditor, { value: (_a = formik.values.inputData) === null || _a === void 0 ? void 0 : _a[templateVariable.name], onChange: function (data) {
                                formik.setFieldError('text', undefined);
                                formik.setFieldValue("inputData.".concat(templateVariable.name), data);
                            }, required: true, name: templateVariable.name, placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u043C\u0435\u043D\u043D\u043E\u0439 \u0448\u0430\u0431\u043B\u043E\u043D\u0430 \u0441 \u0438\u043C\u0435\u043D\u0435\u043C ".concat(templateVariable.name), error: Boolean(formik.errors[templateVariable.name]), helperText: formik.errors[templateVariable.name], toolbarHidden: true }, templateVariable.id));
                    }) }), void 0)), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "submit", onClick: function () { return setIsAddNew(true); } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0)) }), void 0));
};
export default NotificationInputAddNew;
function getUserAddressSearchResults(arg0) {
    throw new Error('Function not implemented.');
}
