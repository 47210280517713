import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { TEAM } from 'common/graphql/queries/team/team';
import { useUpdateTeamMutation } from 'common/hooks/team/useUpdateTeamMutation';
import Loading from 'components/Loading/Loading';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import SettingsGroup from 'main/pages/SettingsPage/components/SettingsGroup/SettingsGroup';
import teamProfileGroupsConfig from './teamProfileGroupsConfig';
var TeamProfile = function (_a) {
    var teamId = _a.teamId;
    var _b = useQuery(TEAM, {
        variables: {
            id: teamId,
            showBasicFields: true
        }
    }), data = _b.data, loading = _b.loading;
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041F\u0440\u043E\u0444\u0430\u0439\u043B \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }, void 0), content: teamProfileGroupsConfig.length > 0 &&
            teamProfileGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: data === null || data === void 0 ? void 0 : data.team, handleChangeData: useUpdateTeamMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default TeamProfile;
