import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import Loading from 'components/Loading/Loading';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { JoinSportEvent } from '../SportEventsPage/components/JoinSportEvent';
var JoinEventByLink = function (_a) {
    var _b, _c;
    var sportEventId = _a.sportEventId;
    var _d = useLazyQuery(SPORT_EVENT), getSportEventData = _d[0], _e = _d[1], data = _e.data, loading = _e.loading, error = _e.error;
    useEffect(function () {
        if (sportEventId) {
            getSportEventData({
                variables: {
                    id: sportEventId,
                    showBasicFields: true,
                    showMainSettings: true,
                    showTournaments: true
                }
            });
        }
    }, [sportEventId]);
    useEffect(function () {
        var _a, _b, _c;
        if (((_a = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _a === void 0 ? void 0 : _a.id) && !((_c = (_b = data === null || data === void 0 ? void 0 : data.sportEvent.tournaments) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id)) {
            sportEventTournamentNotFound();
        }
        if (data === null || data === void 0 ? void 0 : data.sportEvent.areYouParticipant) {
            sportEventAlreadyJoined();
        }
    }, [data]);
    useEffect(function () {
        if (error) {
            sportEventNotFound();
        }
    }, [error]);
    var dispatch = useDispatch();
    var sportEventNotFound = function () {
        history.push('/events/search');
        dispatch(openSnackbar({
            message: 'Событие не найдено.',
            severity: SnackbarSeverity.WARNING
        }));
    };
    var sportEventAlreadyJoined = function () {
        var _a;
        history.push("/event/".concat((_a = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _a === void 0 ? void 0 : _a.url));
    };
    var sportEventTournamentNotFound = function () {
        var _a;
        history.push("/event/".concat((_a = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _a === void 0 ? void 0 : _a.url));
        dispatch(openSnackbar({
            message: 'Турнир события не найден, обратитесь к администратору',
            severity: SnackbarSeverity.ERROR
        }));
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return data && (data === null || data === void 0 ? void 0 : data.sportEvent) && ((_c = (_b = data === null || data === void 0 ? void 0 : data.sportEvent.tournaments) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) && !(data === null || data === void 0 ? void 0 : data.sportEvent.areYouParticipant) ? (_jsx(JoinSportEvent, { sportEvent: data.sportEvent }, void 0)) : null;
};
export default JoinEventByLink;
