var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var JoinTeamButton = function (_a) {
    var userId = _a.userId, team = _a.team;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var handleClick = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        dispatch(openDialog({
            dialogType: DialogTypes.JoinTeamRequestDialog,
            dialogProps: {
                userId: userId,
                teamId: team.id,
                teamDisplayName: team === null || team === void 0 ? void 0 : team.displayName,
                teamName: team === null || team === void 0 ? void 0 : team.name,
                sportEventId: (_c = (_b = (_a = team === null || team === void 0 ? void 0 : team.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.sportEvent) === null || _c === void 0 ? void 0 : _c.id,
                tournamentId: (_e = (_d = team === null || team === void 0 ? void 0 : team.tournaments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.id,
                sportEventDisplayName: (_j = (_h = (_g = (_f = team === null || team === void 0 ? void 0 : team.tournaments) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.sportEvent) === null || _h === void 0 ? void 0 : _h.mainSettings) === null || _j === void 0 ? void 0 : _j.displayName,
                teamParticipationSettings: team === null || team === void 0 ? void 0 : team.participationSettings
            }
        }));
    };
    return (_jsx(Button, __assign({ onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            handleClick();
        }, type: "primary sm" }, { children: t('Вступить') }), void 0));
};
export default JoinTeamButton;
