var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Gender } from 'common/enums/common/gender';
import { TOURNAMENT_NOMINATION } from 'common/graphql/queries/tournament/tournament-nomination';
import Loading from 'components/Loading/Loading';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import { GiSportMedal } from 'react-icons/gi';
import history from 'store/history';
var UserTournamentsAndRewardsDialogListItem = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var tournamentNominationWinner = _a.tournamentNominationWinner, userGender = _a.userGender, handleClose = _a.handleClose;
    var _j = useTranslation(), t = _j.t, i18n = _j.i18n;
    var _k = useQuery(TOURNAMENT_NOMINATION, {
        variables: {
            id: tournamentNominationWinner.tournamentNomination.id
        }
    }), data = _k.data, loading = _k.loading;
    var splitByGender = (_b = data === null || data === void 0 ? void 0 : data.tournamentNomination) === null || _b === void 0 ? void 0 : _b.splitByGender;
    var place = splitByGender && userGender ? tournamentNominationWinner.genderPlace : tournamentNominationWinner.place;
    var getPlaceText = function (place, splitByGender) {
        var placeString = place
            ? place === 1
                ? t('первое')
                : place === 2
                    ? t('второе')
                    : place === 3
                        ? t('третье')
                        : i18n.language === 'en'
                            ? place
                            : "".concat(place, "-\u043E\u0435")
            : '';
        var genderString = userGender ? (userGender === Gender.MALE ? t('мужчин') : t('женщин')) : '';
        return splitByGender
            ? "".concat(t('Вы заняли'), " ").concat(placeString, " ").concat(t('место'), " ").concat(t('среди'), " ").concat(genderString, "!")
            : "".concat(t('Вы заняли'), " ").concat(placeString, " ").concat(t('место'), "!");
    };
    var medalStyle = {
        color: tournamentNominationWinner.place
            ? tournamentNominationWinner.place === 1
                ? '#D4AF37'
                : tournamentNominationWinner.place === 2
                    ? '#BCC6CC'
                    : tournamentNominationWinner.place >= 3
                        ? '#A97142'
                        : '#A97142'
            : '#D4AF37'
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsxs("div", __assign({ className: "flex items-center w-full" }, { children: [_jsx(UserAvatar, { style: "xs:w-68 xs:h-68 xs:text-20 md:w-88 md:h-88 md:text-24", firstName: tournamentNominationWinner.id, profilePhoto: (_c = data === null || data === void 0 ? void 0 : data.tournamentNomination) === null || _c === void 0 ? void 0 : _c.profilePhoto }, void 0), _jsxs("div", __assign({ className: "flex flex-col w-full" }, { children: [_jsx("div", __assign({ className: "flex xs:flex-col sm:flex-row justify-between w-full" }, { children: _jsx("div", __assign({ className: "flex xs:flex-col sm:flex-row justify-between w-full" }, { children: _jsx("div", __assign({ className: "flex flex-row items-center" }, { children: _jsx("div", __assign({ className: "xs:text-14 md:text-16 font-semibold px-8 py-2 dark:text-white" }, { children: i18n.language === 'en'
                                        ? ((_d = data === null || data === void 0 ? void 0 : data.tournamentNomination) === null || _d === void 0 ? void 0 : _d.displayNameEn) ||
                                            ((_e = data === null || data === void 0 ? void 0 : data.tournamentNomination) === null || _e === void 0 ? void 0 : _e.displayName)
                                        : (_f = data === null || data === void 0 ? void 0 : data.tournamentNomination) === null || _f === void 0 ? void 0 : _f.displayName }), void 0) }), void 0) }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex flex-col xs:text-12 md:text-14" }, { children: [tournamentNominationWinner.nomination.isPlacesApplicable && (_jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx(GiSportMedal, { size: "3em", style: medalStyle }, void 0), _jsx("div", __assign({ className: "flex items-center dark:text-white" }, { children: getPlaceText(place, splitByGender) }), void 0)] }), void 0)), tournamentNominationWinner.diplomaLinks && tournamentNominationWinner.diplomaLinks.length > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10 px-8 py-2" }, { children: tournamentNominationWinner.diplomaLinks.map(function (diplomaLink) {
                                    return (_jsx("div", { children: _jsx("a", __assign({ href: diplomaLink.link, target: "_blank", className: "tw-link-btn", download: true }, { children: diplomaLink.text }), void 0) }, diplomaLink.text));
                                }) }), void 0)), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2 sm:w-110 xs:w-96" }, { children: [t('Событие'), ":"] }), void 0), _jsx("div", __assign({ className: "flex items-center cursor-pointer dark:text-white", onClick: function () {
                                            var _a;
                                            history.push({
                                                pathname: '/event/' + ((_a = tournamentNominationWinner.tournament.sportEvent) === null || _a === void 0 ? void 0 : _a.url)
                                            });
                                            handleClose();
                                        } }, { children: (_h = (_g = tournamentNominationWinner.tournament.sportEvent) === null || _g === void 0 ? void 0 : _g.mainSettings) === null || _h === void 0 ? void 0 : _h.displayName }), void 0)] }), void 0), tournamentNominationWinner.team && (_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-8 py-2 sm:w-110 xs:w-96" }, { children: [t('Команда'), ":"] }), void 0), _jsx("div", __assign({ className: "flex items-center cursor-pointer dark:text-white", onClick: function () {
                                            var _a;
                                            history.push({
                                                pathname: '/team/' + ((_a = tournamentNominationWinner.team) === null || _a === void 0 ? void 0 : _a.name)
                                            });
                                            handleClose();
                                        } }, { children: tournamentNominationWinner.team.displayName }), void 0)] }), void 0))] }), void 0)] }), void 0)] }), void 0));
};
export default UserTournamentsAndRewardsDialogListItem;
