import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import * as Actions from 'store/actions/base/settings.actions';
import { AppContext } from '../../Context';
import Layouts from '../../main/layouts/Layouts';
import _ from '../../utils/lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		'& code:not([class*="language-"])': {
			color: theme.palette.secondary.dark,
			backgroundColor: '#F5F5F5',
			padding: '2px 3px',
			borderRadius: 2,
			lineHeight: 1.7,
		},
		'& table.simple tbody tr td': {
			borderColor: theme.palette.divider,
		},
		'& table.simple thead tr th': {
			borderColor: theme.palette.divider,
		},

		'& a:not([role=button]):not([class*="link-area"]):not([class*="link-table-cell"])': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
			'&:hover': {
				color: theme.palette.primary.dark,
			},
		},
		'& [class*="link-area"]': {
			textDecoration: 'none',
			color: theme.palette.text.primary,
		},
		'& [class*="link-table-cell"]': {
			textDecoration: 'none',
			color: theme.palette.text.primary,
			'&:hover': {
				color: theme.palette.primary.dark,
			},
		},
	},
}));

function Layout(props) {
	const dispatch = useDispatch();
	const defaultSettings = useSelector(({ base }) => base.settings.defaults);
	const settings = useSelector(({ base }) => base.settings.current);

	const classes = useStyles(props);
	const appContext = useContext(AppContext);
	const { routes } = appContext;

	useEffect(() => {
		function routeSettingsCheck() {
			const matched = matchRoutes(routes, props.location.pathname)[0];

			if (matched && matched.route.settings) {
				const routeSettings = _.merge({}, defaultSettings, matched.route.settings);
				if (!_.isEqual(settings, routeSettings)) {
					dispatch(Actions.setSettings(_.merge({}, routeSettings)));
				}
			} else {
				if (!_.isEqual(settings, defaultSettings)) {
					dispatch(Actions.resetSettings());
				}
			}
		}

		routeSettingsCheck();
	}, [defaultSettings, dispatch, props.location.pathname, routes, settings]);

	const Layout = Layouts[settings.layout.style];
	return <Layout classes={{ root: classes.root }} {...props} />;
}

export default withRouter(React.memo(Layout));
