var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SortIcon from '@mui/icons-material/Sort';
import { ListItemText, MenuItem, Popover, Tooltip } from '@mui/material';
import { GetSportClubUsersOrderBy } from 'common/enums/sport-club/get-sport-club-users-order-by.enum';
import { useState } from 'react';
var ParticipantsSortTypeButton = function (_a) {
    var sortType = _a.sortType, setSortType = _a.setSortType;
    var _b = useState(null), sortTypeMenu = _b[0], setSortTypeMenu = _b[1];
    var sortTypeMenuClick = function (event) {
        setSortTypeMenu(event.currentTarget);
    };
    var sortTypeMenuClose = function () {
        setSortTypeMenu(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ title: 'Сортировать по' }, { children: _jsx(SortIcon, { className: "w-24 h-24 xs:mr-8 cursor-pointer", color: "primary", onClick: sortTypeMenuClick }, void 0) }), void 0), _jsx(Popover, __assign({ open: Boolean(sortTypeMenu), anchorEl: sortTypeMenu, onClose: sortTypeMenuClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }, transformOrigin: {
                    vertical: 'center',
                    horizontal: 'left'
                } }, { children: _jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.lastName, onClick: function () { return setSortType(GetSportClubUsersOrderBy.lastName); } }, { children: _jsx(ListItemText, { primary: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.firstName, onClick: function () { return setSortType(GetSportClubUsersOrderBy.firstName); } }, { children: _jsx(ListItemText, { primary: "\u0418\u043C\u044F" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.clubMemberSince, onClick: function () { return setSortType(GetSportClubUsersOrderBy.clubMemberSince); } }, { children: _jsx(ListItemText, { primary: "\u0414\u0430\u0442\u0430 \u0432\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.lastActivityDate, onClick: function () { return setSortType(GetSportClubUsersOrderBy.lastActivityDate); } }, { children: _jsx(ListItemText, { primary: "\u0414\u0430\u0442\u0430 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0435\u0439 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0438" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.isAdmin, onClick: function () { return setSortType(GetSportClubUsersOrderBy.isAdmin); } }, { children: _jsx(ListItemText, { primary: "\u0410\u0434\u043C\u0438\u043D" }, void 0) }), void 0), _jsx(MenuItem, __assign({ selected: sortType === GetSportClubUsersOrderBy.isOwner, onClick: function () { return setSortType(GetSportClubUsersOrderBy.isOwner); } }, { children: _jsx(ListItemText, { primary: "\u0412\u043B\u0430\u0434\u0435\u043B\u0435\u0446" }, void 0) }), void 0)] }, void 0) }), void 0)] }, void 0));
};
export default ParticipantsSortTypeButton;
