var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_STANDINGS } from 'common/graphql/mutations/standings/update-standings';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import standingsValidationSchema from 'common/validationSchemas/standingsValidationSchema';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var StandingsPrivacySettings = function (_a) {
    var _b, _c;
    var standingsId = _a.standingsId;
    var t = useTranslation().t;
    var _d = useState(false), isEditMode = _d[0], setIsEditMode = _d[1];
    var _e = useQuery(STANDINGS, {
        variables: {
            id: standingsId,
            showPrivacy: true
        }
    }), data = _e.data, loading = _e.loading;
    var privacy = (_b = data === null || data === void 0 ? void 0 : data.standings) === null || _b === void 0 ? void 0 : _b.privacy;
    var defaultFormState = {
        publicAvailable: null
    };
    var updateStandings = function (data) {
        return useMutation(UPDATE_STANDINGS, {
            variables: {
                updateStandingsData: {
                    id: standingsId,
                    privacy: __assign({}, data)
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: STANDINGS,
                    variables: {
                        id: standingsId,
                        showPrivacy: true
                    }
                },
            ]
        });
    };
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Турнирная таблица обновлена.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateStandings, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
        setIsEditMode(false);
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: standingsValidationSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var setFields = function () {
        if (privacy) {
            formik.setFieldValue('publicAvailable', privacy.publicAvailable);
        }
    };
    useEffect(function () {
        setFields();
    }, [data]);
    var handleReset = function () {
        setFields();
        setIsEditMode(false);
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0441\u0442\u0438" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: privacy && (_jsxs("form", __assign({ className: "flex flex-col max-w-320", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: isEditMode ? (_jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "button", onClick: function () { return setIsEditMode(!isEditMode); } }, { children: t('Редактировать') }), void 0)) }), void 0), _jsx("div", __assign({ className: "pt-20" }, { children: _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { disabled: !isEditMode, checked: ((_c = formik.values) === null || _c === void 0 ? void 0 : _c.publicAvailable) || false, onChange: function (e) {
                                    formik.setFieldError('publicAvailable', undefined);
                                    formik.setFieldValue('publicAvailable', e.target.checked);
                                } }, void 0), label: "\u041C\u043E\u0436\u043D\u043E \u043B\u0438 \u043F\u0440\u043E\u0441\u043C\u0430\u0442\u0440\u0438\u0432\u0430\u0442\u044C \u0434\u0430\u043D\u043D\u044B\u0435 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0442\u0430\u0431\u043B\u0438\u0446\u044B \u0431\u0435\u0437 \u0430\u0443\u0442\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0446\u0438\u0438" }, void 0) }), void 0)] }), void 0)) }), void 0), innerScroll: false }, void 0));
};
export default StandingsPrivacySettings;
