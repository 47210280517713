var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@mui/styles';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { openDialog } from 'store/actions';
import * as Actions from 'store/actions/base/settings.actions';
import inMemoryJwt from 'utils/inMemoryJwt';
import { AppContext } from '../../Context';
import Layouts from '../../main/layouts/Layouts';
import _ from '../../utils/lodash';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        backgroundColor: theme.palette.background["default"],
        color: theme.palette.text.primary,
        '& code:not([class*="language-"])': {
            color: theme.palette.secondary.dark,
            backgroundColor: '#F5F5F5',
            padding: '2px 3px',
            borderRadius: 2,
            lineHeight: 1.7
        },
        '& table.simple tbody tr td': {
            borderColor: theme.palette.divider
        },
        '& table.simple thead tr th': {
            borderColor: theme.palette.divider
        },
        '& a:not([role=button]):not([class*="link-area"]):not([class*="link-table-cell"])': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.primary.dark
            }
        },
        '& [class*="link-area"]': {
            textDecoration: 'none',
            color: theme.palette.text.primary
        },
        '& [class*="link-table-cell"]': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            '&:hover': {
                color: theme.palette.primary.dark
            }
        }
    }
}); });
function Layout(props) {
    var dispatch = useDispatch();
    var defaultSettings = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.defaults;
    });
    var settings = useSelector(function (_a) {
        var base = _a.base;
        return base.settings.current;
    });
    var classes = useStyles(props);
    var appContext = useContext(AppContext);
    var routes = appContext.routes;
    // const { search } = useLocation();
    // const useQueryParams = () => {
    // 	return useMemo(() => new URLSearchParams(search), [search]);
    // };
    // const query = useQueryParams();
    // console.log(query.get('modal'));
    useEffect(function () {
        function routeSettingsCheck() {
            var matched = matchRoutes(routes, props.location.pathname)[0];
            if (matched && matched.route.settings) {
                var routeSettings = _.merge({}, defaultSettings, matched.route.settings);
                if (!_.isEqual(settings, routeSettings)) {
                    dispatch(Actions.setSettings(_.merge({}, routeSettings)));
                }
            }
            else {
                if (!_.isEqual(settings, defaultSettings)) {
                    dispatch(Actions.resetSettings(undefined));
                }
            }
        }
        routeSettingsCheck();
    }, [defaultSettings, dispatch, props.location.pathname, routes, settings]);
    useEffect(function () {
        if (props.location.search) {
            var query = new URLSearchParams(props.location.search);
            var openModalQuery = query.get('modal');
            if (openModalQuery) {
                if (Object.values(DialogTypes).includes(openModalQuery) && inMemoryJwt.getToken()) {
                    var modalProps = query.get('modalProps');
                    var dialogProps = modalProps ? __assign({}, JSON.parse(modalProps)) : {};
                    dispatch(openDialog({
                        dialogType: openModalQuery,
                        dialogProps: dialogProps
                    }));
                }
            }
        }
    }, [props.location.search]);
    // @ts-ignore
    var Layout = Layouts[settings.layout.style];
    return _jsx(Layout, __assign({ classes: { root: classes.root } }, props), void 0);
}
export default withRouter(React.memo(Layout));
