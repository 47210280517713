var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseErrors } from 'common/enums/common/response-errors.enum';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import jwtService from 'services/auth/jwtService';
import authService from 'services/authServise';
import validator from 'validator';
import history from '../../history';
import { openDialog } from '../base/dialog.actions';
import { resendCode, setInputVerificationKey } from './register.actions';
export var LOGIN_ERROR = 'LOGIN_ERROR';
export var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export var ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export var INVALID_EMAIL_OR_PASSWORD_ERROR = 'INVALID_EMAIL_OR_PASSWORD_ERROR';
export function setLoginError(error) {
    return {
        type: LOGIN_ERROR,
        payload: error ? error : {}
    };
}
export function setLoginSuccess() {
    return {
        type: LOGIN_SUCCESS
    };
}
export function cleanLoginError() {
    return {
        type: LOGIN_ERROR,
        payload: {}
    };
}
export function setInvalidEmailOrPasswordError(payload) {
    return {
        type: INVALID_EMAIL_OR_PASSWORD_ERROR,
        payload: payload
    };
}
export function setAccountData(data, email) {
    return {
        type: ACCOUNT_DATA,
        payload: __assign({ currentEmail: email }, data)
    };
}
/********************************************************/
export function getAccountData(payload) {
    var _this = this;
    if (validator.isEmail(payload.email)) {
        var params_1 = {
            params: payload
        };
        return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, authService.getAccountData(params_1)];
                    case 1:
                        response = _a.sent();
                        if (response === null || response === void 0 ? void 0 : response.email) {
                            dispatch(cleanLoginError());
                            dispatch(setAccountData(response, payload.email));
                        }
                        else {
                            dispatch(setLoginError({ email: ResponseErrors.UserNotFound }));
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        dispatch(setLoginError(error_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }
    else {
        return function (dispatch) { return dispatch(cleanLoginError()); };
    }
}
export function loginByEmail(payload, navigateTo) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_2, errorCode, email;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authService.loginByEmail(payload)];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.accessToken) {
                        dispatch(completeLogin(response.accessToken, navigateTo));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    errorCode = error_2.errorCode;
                    if (errorCode && validator.equals(errorCode, ResponseErrors.VerificationRequired)) {
                        email = payload.email;
                        dispatch(setInputVerificationKey(email));
                        dispatch(resendCode({ email: email }));
                        history.push('/register');
                    }
                    else if (errorCode && validator.equals(errorCode, ResponseErrors.InvalidEmailOrPassword)) {
                        dispatch(setInvalidEmailOrPasswordError({ password: errorCode }));
                    }
                    else {
                        dispatch(setLoginError(error_2));
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function googleSignIn(googleData) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authService.googleSignIn(JSON.stringify({
                            code: googleData.code
                        }))];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.accessToken) {
                        dispatch(completeLogin(response.accessToken));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    dispatch(setLoginError(error_3));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function vkSignIn(code, join) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_4, errorCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authService.vkSignIn(JSON.stringify({
                            code: code,
                            join: join
                        }))];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.accessToken) {
                        dispatch(completeLogin(response.accessToken));
                        sessionStorage.removeItem('join');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    errorCode = error_4.errorCode;
                    if (errorCode && validator.equals(errorCode, ResponseErrors.AccountJoinRequired)) {
                        dispatch(openDialog({
                            dialogType: DialogTypes.VKJoinAccountRequest,
                            dialogProps: {}
                        }));
                    }
                    else {
                        dispatch(setLoginError(error_4));
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function yandexSignIn(code, join) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_5, errorCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authService.yandexSignIn(JSON.stringify({
                            code: code,
                            join: join
                        }))];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.accessToken) {
                        dispatch(completeLogin(response.accessToken));
                        sessionStorage.removeItem('join');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _a.sent();
                    errorCode = error_5.errorCode;
                    if (errorCode && validator.equals(errorCode, ResponseErrors.AccountJoinRequired)) {
                        dispatch(openDialog({
                            dialogType: DialogTypes.YandexJoinAccountRequest,
                            dialogProps: {}
                        }));
                    }
                    else {
                        dispatch(setLoginError(error_5));
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function stravaSignIn(code) {
    var _this = this;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authService.stravaSignIn(JSON.stringify({
                            code: code
                        }))];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.accessToken) {
                        dispatch(completeLogin(response.accessToken));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _a.sent();
                    dispatch(setLoginError(error_6));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
export function completeLogin(accessToken, navigateTo) {
    var _this = this;
    return function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!validator.isEmpty(accessToken)) {
                jwtService.setSession(accessToken);
                jwtService.handleOnLogin();
                setLoginSuccess();
                if (navigateTo) {
                    window.location.href = navigateTo;
                }
                else {
                    window.location.reload();
                }
                //history.push('/');
            }
            else {
                setLoginError('error');
            }
            return [2 /*return*/];
        });
    }); };
}
