var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip, Typography } from '@mui/material';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
var LoadingFilesForm = function (_a) {
    var formText = _a.formText, loadingFiles = _a.loadingFiles, executableMutation = _a.executableMutation;
    var t = useTranslation().t;
    var _b = useState(undefined), filesLoading = _b[0], setFilesLoading = _b[1];
    //файл который сейчас загружается
    var _c = useState(), loadingFileName = _c[0], setLoadingFileName = _c[1];
    //список уже загруженных файлов
    var _d = useState([]), uploadedFilesName = _d[0], setUploadedFilesName = _d[1];
    //список файлов с ошибкой
    var _e = useState([]), errorFilesName = _e[0], setErrorFilesName = _e[1];
    var uploadNextFile = function () {
        if (loadingFileName) {
            setUploadedFilesName(__spreadArray(__spreadArray([], uploadedFilesName, true), [loadingFileName], false));
            var files = filesLoading === null || filesLoading === void 0 ? void 0 : filesLoading.slice(1);
            setFilesLoading(files);
        }
    };
    var onUploadFileError = function (_a) {
        var code = _a.code;
        if (loadingFileName) {
            setErrorFilesName(__spreadArray(__spreadArray([], errorFilesName, true), [{ name: loadingFileName, code: code }], false));
            uploadNextFile();
        }
    };
    var handleExecuteMutation = useDynamicMutation(function (file) {
        return executableMutation(file);
    }, uploadNextFile, onUploadFileError).handleExecuteMutation;
    useEffect(function () {
        if (loadingFiles.length) {
            setFilesLoading(Array.from(loadingFiles));
        }
    }, [loadingFiles]);
    useEffect(function () {
        if (filesLoading === null || filesLoading === void 0 ? void 0 : filesLoading.length) {
            var file_1 = filesLoading[0];
            var fileReader_1 = new FileReader();
            fileReader_1.readAsDataURL(file_1);
            fileReader_1.onload = function () {
                var _a;
                var result = (_a = fileReader_1.result) === null || _a === void 0 ? void 0 : _a.toString();
                if (result) {
                    setLoadingFileName(file_1.name);
                    handleExecuteMutation(file_1);
                }
            };
            fileReader_1.onerror = function () {
                console.log('fileReader error');
            };
        }
    }, [filesLoading]);
    var renderUploadStatus = function (fileName) {
        var isFileUploaded = uploadedFilesName.find(function (uploadedFileName) { return uploadedFileName === fileName; });
        var isError = errorFilesName.find(function (_a) {
            var name = _a.name;
            return name === fileName;
        });
        return (_jsx(_Fragment, { children: (isError === null || isError === void 0 ? void 0 : isError.code) ? (_jsx(Tooltip, __assign({ title: _jsx("div", { children: t(isError.code) }, void 0) }, { children: _jsx(ErrorOutlineIcon, { style: { fontSize: 16, color: 'red' } }, void 0) }), void 0)) : (_jsx(DoneIcon, { style: { fontSize: 16, color: isFileUploaded ? 'green' : 'lightgray' } }, void 0)) }, void 0));
    };
    return (_jsxs("div", __assign({ className: "flex flex-col dark:text-white" }, { children: [_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1.4rem', fontWeight: 400, textAlign: 'center' } }, { children: formText }), void 0) }), void 0), _jsx("div", __assign({ className: "flex flex-col p-16" }, { children: Array.from(loadingFiles).map(function (file, index) { return (_jsxs("div", __assign({ className: "flex p-8 justify-between" }, { children: [_jsx(Typography, __assign({ sx: { fontSize: '1.2rem', fontWeight: 400 } }, { children: file.name }), void 0), renderUploadStatus(file.name)] }), index)); }) }), void 0)] }), void 0));
};
export default LoadingFilesForm;
