var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { standingsFilterOpenKey } from 'common/const/standings-filter-open-key';
import { Sports } from 'common/enums/sport/sports.enum';
import ActivitiesFilter from 'components/ActivitiesFilter/ActivitiesFilter';
import DateRangeFilter from 'components/DateRangeFilter/DateRangeFilter';
import { useEffect, useState } from 'react';
import history from 'store/history';
import ParticipantNameSearchField from './ParticipantNameSearchField';
import ShowUserDataInStandingsTableButton from './ShowUserDataInStandingsTableButton';
var StandingsFilter = function (_a) {
    var filterBy = _a.filterBy, className = _a.className, setFilterBy = _a.setFilterBy, activityStartDate = _a.activityStartDate, activityEndDate = _a.activityEndDate, _b = _a.isFilterBySport, isFilterBySport = _b === void 0 ? true : _b, isFilterByDateRange = _a.isFilterByDateRange, sports = _a.sports, isUserStandingsParticipant = _a.isUserStandingsParticipant, goToLink = _a.goToLink, standingsParticipantType = _a.standingsParticipantType, handleQueryParams = _a.handleQueryParams;
    var _c = useState(localStorage.getItem(standingsFilterOpenKey) === 'true'
        ? true
        : localStorage.getItem(standingsFilterOpenKey) === 'false'
            ? false
            : false), filterOpen = _c[0], setFilterOpen = _c[1];
    useEffect(function () {
        if (filterOpen !== undefined)
            localStorage.setItem(standingsFilterOpenKey, filterOpen.toString());
    }, [filterOpen]);
    var setActivitiyFilter = function (sport) {
        sport && handleQueryParams && handleQueryParams([{ key: 'sport', value: sport }]);
        setFilterBy(function (prevState) { return (__assign(__assign({}, prevState), { sport: sport ? sport : [Sports.Run] })); });
    };
    var setRangeFilter = function (range) {
        if (range) {
            var dateRange = range.dateRange, year = range.year, month = range.month, period = range.period;
            var startDate = (period === null || period === void 0 ? void 0 : period.startDate) && (period === null || period === void 0 ? void 0 : period.startDate.setHours(12));
            var endDate = (period === null || period === void 0 ? void 0 : period.endDate) && (period === null || period === void 0 ? void 0 : period.endDate.setHours(12));
            handleQueryParams &&
                handleQueryParams([
                    { key: 'dateRange', value: dateRange },
                    { key: 'year', value: year },
                    { key: 'month', value: month },
                    { key: 'startDate', value: startDate && new Date(startDate).toISOString().slice(0, 10) },
                    { key: 'endDate', value: endDate && new Date(endDate).toISOString().slice(0, 10) },
                ]);
        }
        setFilterBy(function (prevState) { return (__assign(__assign({}, prevState), { range: range })); });
    };
    var setParticipantNameInput = function (participantNameInput) {
        var _a;
        var input = (_a = participantNameInput === null || participantNameInput === void 0 ? void 0 : participantNameInput.target) === null || _a === void 0 ? void 0 : _a.value;
        handleQueryParams && handleQueryParams([{ key: 'search', value: input }]);
        setFilterBy(function (prevState) { return (__assign(__assign({}, prevState), { participantNameInput: input || '' })); });
    };
    return (_jsx("div", __assign({ className: clsx(className, 'tw-page-container-shadow w-full') }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsxs("div", __assign({ className: "flex flex-row items-center justify-between w-full" }, { children: [_jsx("div", __assign({ className: 'h-full flex items-center justify-end cursor-pointer', onClick: function () { return setFilterOpen(!filterOpen); } }, { children: _jsx(IconButton, __assign({ className: "h-14 w-14 ml-8 my-2", "aria-label": "expand", size: "small" }, { children: filterOpen ? _jsx(FilterAltOffIcon, { color: "primary" }, void 0) : _jsx(FilterAltIcon, { color: "primary" }, void 0) }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [isUserStandingsParticipant && (_jsx(ShowUserDataInStandingsTableButton, { standingsParticipantType: standingsParticipantType, className: "flex px-16 py-8 items-center " }, void 0)), goToLink && (_jsx("div", __assign({ className: "flex items-center pl-10 cursor-pointer", onClick: function () {
                                        history.push(goToLink);
                                    } }, { children: _jsx(LaunchIcon, { className: "h-24 w-24 show-full-info-button xs:mr-8 md:mr-12 ", color: "primary" }, void 0) }), void 0))] }), void 0)] }), void 0), filterOpen && (_jsxs("div", __assign({ className: "flex flex-row justify-between space-x-10" }, { children: [isFilterBySport && sports && (sports === null || sports === void 0 ? void 0 : sports.length) > 1 && (_jsx("div", __assign({ className: "grow-[1]" }, { children: _jsx(ActivitiesFilter, { className: "bg-bg-container-inner-lt \n\t\t\t\t\t\tdark:bg-bg-container-inner-dk rounded-8 w-max shadow", filterBy: filterBy.sport, setFilterBy: setActivitiyFilter, isMultiple: false, sports: sports }, void 0) }), void 0)), isFilterByDateRange && (_jsx("div", __assign({ className: "grow-[4]" }, { children: _jsx(DateRangeFilter, { className: "bg-bg-container-inner-lt \n\t\t\t\t\tdark:bg-bg-container-inner-dk rounded-8 w-max shadow", activityStartDate: activityStartDate, activityEndDate: activityEndDate, filterBy: filterBy.range, setFilterBy: setRangeFilter }, void 0) }), void 0)), _jsx("div", __assign({ className: "flex w-full justify-end" }, { children: _jsx(ParticipantNameSearchField, { participantNameInput: filterBy.participantNameInput || '', setParticipantNameSearchFieldInput: setParticipantNameInput }, void 0) }), void 0)] }), void 0))] }), void 0) }), void 0));
};
export default StandingsFilter;
