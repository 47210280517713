var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Popover } from '@mui/material';
import clsx from 'clsx';
import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import Button from 'components/Buttons/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConvertedDateRange from './components/ConvertedDateRange/ConvertedDateRange';
import DateRangeFilterItem from './components/DateRangeFiltertem/DateRangeFilterItem';
import DateRangeMonth from './components/DateRangeMonth/DateRangeMonth';
import DateRangePeriod from './components/DateRangePeriod/DateRangePeriod';
import DateRangeYear from './components/DateRangeYear/DateRangeYear';
var dateRangeFilterItems = [
    { name: 'Все время', value: DateRangeFilterItems.allTime },
    { name: 'Год', value: DateRangeFilterItems.year },
    { name: 'Месяц', value: DateRangeFilterItems.month },
    { name: 'Период', value: DateRangeFilterItems.period },
];
var DateRangeFilter = function (_a) {
    var calledBy = _a.calledBy, className = _a.className, filterBy = _a.filterBy, setFilterBy = _a.setFilterBy, activityStartDate = _a.activityStartDate;
    var _b = useState(null), editDateRande = _b[0], setEditDateRange = _b[1];
    var _c = useState(filterBy), range = _c[0], setRange = _c[1];
    var t = useTranslation().t;
    var setRangeFilter = function (rangeName, rangeValue) {
        var _a;
        setRange(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[rangeName] = rangeValue, _a)));
        });
        if (calledBy) {
            var prevState = localStorage.getItem("dateRange_".concat(calledBy));
            var prevStateObj = prevState ? JSON.parse(prevState) : {};
            localStorage.setItem("dateRange_".concat(calledBy), JSON.stringify(__assign(__assign({}, prevStateObj), (_a = {}, _a[rangeName] = rangeValue, _a))));
        }
    };
    useEffect(function () {
        if (activityStartDate) {
            //если год не указан, например в url, то будет установлен год начала активности
            if (filterBy.dateRange === DateRangeFilterItems.year) {
                if (!filterBy.year)
                    setFilterBy(__assign(__assign({}, filterBy), { year: new Date(activityStartDate).getFullYear() }));
            }
            if (filterBy.dateRange === DateRangeFilterItems.month) {
                if ((!filterBy.month && filterBy.month !== 0) || !filterBy.year)
                    setFilterBy({
                        dateRange: DateRangeFilterItems.allTime
                    });
            }
        }
    }, [filterBy, activityStartDate]);
    return (_jsx("div", __assign({ className: clsx(className, 'flex flex-wrap') }, { children: activityStartDate && (_jsxs(_Fragment, { children: [_jsx(ConvertedDateRange, { setEditDateRange: setEditDateRange, activityStartDate: activityStartDate, filterBy: filterBy }, void 0), _jsx(Popover, __assign({ open: Boolean(editDateRande), anchorEl: editDateRande, onClose: function () {
                        setEditDateRange(null);
                    }, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    } }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: clsx('p-8 flex flex-col') }, { children: [_jsx("div", __assign({ className: "flex" }, { children: dateRangeFilterItems.map(function (item, index) {
                                            return (_jsx(DateRangeFilterItem, { name: t(item.name), value: item.value, filterBy: range, setRange: setRangeFilter }, index));
                                        }) }), void 0), (range.dateRange === DateRangeFilterItems.year ||
                                        range.dateRange === DateRangeFilterItems.month) && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex flex-col xs:p-4 md:p-8" }, { children: _jsx(Divider, { className: "border-icon-lt" }, void 0) }), void 0), _jsx("div", __assign({ className: "flex flex-wrap" }, { children: _jsx(DateRangeYear, { value: range.year, activityStartYear: activityStartDate
                                                        ? new Date(activityStartDate).getFullYear()
                                                        : new Date().getFullYear(), setRange: setRangeFilter }, void 0) }), void 0)] }, void 0)), range.dateRange === DateRangeFilterItems.month && range.year && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex flex-col xs:p-4 md:p-8" }, { children: _jsx(Divider, { className: "border-icon-lt" }, void 0) }), void 0), _jsx("div", __assign({ className: "flex" }, { children: _jsx(DateRangeMonth, { value: range.month, activityStartDate: new Date(activityStartDate), setRange: setRangeFilter, year: range.year }, void 0) }), void 0)] }, void 0)), _jsx("div", __assign({ className: "flex" }, { children: range.dateRange === DateRangeFilterItems.period && (_jsx(DateRangePeriod, { value: range.period, activityStartDate: new Date(activityStartDate), setRange: setRangeFilter }, void 0)) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex justify-end p-12" }, { children: _jsx(Button, __assign({ onClick: function () {
                                        setFilterBy(range);
                                        setEditDateRange(null);
                                    }, type: "primary sm" }, { children: t('Поиск') }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0)) }), void 0));
};
export default DateRangeFilter;
