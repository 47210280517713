var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { ActivityValidationStates } from 'common/enums/activity/activity-validation-states.enum';
import { ActivityValidationTypes } from 'common/enums/activity/activity-validation-types.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var ValidationStatus = function (props) {
    var t = useTranslation().t;
    var _a = useState(false), openValidationStatusTooltip = _a[0], setOpenValidationStatusTooltip = _a[1];
    var validations = props.validations, filterBase = props.filterBase;
    var validation = filterBase
        ? (validations === null || validations === void 0 ? void 0 : validations.filter(function (validation) {
            // найти последню активную (startDate not null, endDate = null) валидацию на уровне Base
            if (validation.validationLevel === ActivityValidationLevels.Base &&
                (validation === null || validation === void 0 ? void 0 : validation.startDate) &&
                !(validation === null || validation === void 0 ? void 0 : validation.endDate))
                return validation;
        })[0]) || null
        : validations[0];
    var status = {
        color: '',
        text: ''
    };
    var getState = function (validation) {
        if (!validation) {
            status = {
                color: yellow[800],
                text: t('Тренировка ожидает валидации, проверьте что задан вид спорта')
            };
            return;
        }
        if (validation.approved === false && validation.validationState === ActivityValidationStates.PENDING) {
            status = {
                color: yellow[600],
                text: t('Тренировка ожидает валидации')
            };
            return;
        }
        if (validation.approved === true && validation.validationState === ActivityValidationStates.PENDING) {
            status = {
                color: yellow[600],
                text: t('Валидация рассматривается. Изменения ожидают подтверждения администратором.')
            };
            return;
        }
        if (validation.approved === false && validation.validationState === ActivityValidationStates.NOT_PASSED) {
            status = {
                color: red[400],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t("\u0422\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0430 \u043D\u0435 \u043F\u0440\u043E\u0448\u043B\u0430 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044E")
                    : t("\u0422\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0430 \u043E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u0430 \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440\u043E\u043C")
            };
            return;
        }
        if (validation.approved && validation.validationState === ActivityValidationStates.PASSED) {
            status = {
                color: green[500],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t('Тренировка прошла автоматическую валидацию')
                    : t('Тренировка зачтена администратором')
            };
            return;
        }
        if (validation.approved && validation.validationState === ActivityValidationStates.PARTIALLY_PASSED) {
            status = {
                color: yellow[500],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t('Тренировка автоматически зачтена частично')
                    : t('Тренировка зачтена администратором частично')
            };
            return;
        }
        if (validation.validationState === ActivityValidationStates.DECLINED) {
            status = {
                color: green[800],
                text: t('Выполнение этой валидации администратором не требуется, актуальная валидация подтверждена')
            };
        }
    };
    getState(validation);
    return (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenValidationStatusTooltip(true); }, onMouseLeave: function () { return setOpenValidationStatusTooltip(false); }, onClose: function () { return setOpenValidationStatusTooltip(false); }, open: openValidationStatusTooltip, title: status.text }, { children: _jsx(CircleIcon, { style: { fontSize: '14' }, sx: { color: status.color } }, void 0) }), void 0));
};
export default ValidationStatus;
