var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography } from '@mui/material';
import Loading from 'components/Loading/Loading';
import useOnScreen from 'main/pages/AthletesSearchPage/components/AthletesSearchResultList/useOnScreen';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SportEventUserListItem from './SportEventUserListItem';
var SportEventUsersSearchResultList = function (_a) {
    var sportEventUsers = _a.sportEventUsers, isTeamTournament = _a.isTeamTournament, isLoading = _a.isLoading, hasMore = _a.hasMore, loadMore = _a.loadMore, handleClose = _a.handleClose;
    var _b = useOnScreen(), measureRef = _b.measureRef, isIntersecting = _b.isIntersecting, observer = _b.observer;
    var t = useTranslation().t;
    useEffect(function () {
        if (isIntersecting && hasMore) {
            loadMore();
            observer.disconnect();
        }
    }, [isIntersecting, hasMore, loadMore]);
    sportEventUsers = sportEventUsers.filter(function (profile) {
        if (profile.firstName && profile.lastName)
            return profile;
    });
    return (_jsx(Box, __assign({ sx: {
            overflowY: 'scroll'
        }, className: "w-full " }, { children: sportEventUsers.length ? (_jsxs("div", __assign({ className: "flex flex-col xs:my-8 md:my-10 mx-0 xs:w-full" }, { children: [sportEventUsers.map(function (sportEventUser, index) {
                    if (index === sportEventUsers.length - 1) {
                        return (_jsxs("div", { children: [_jsx(SportEventUserListItem, { mesureRef: measureRef, sportEventUser: sportEventUser, isTeamTournament: isTeamTournament, handleClose: handleClose }, void 0), _jsx(Divider, { light: true }, void 0)] }, index));
                    }
                    return (_jsxs("div", { children: [_jsx(SportEventUserListItem, { sportEventUser: sportEventUser, isTeamTournament: isTeamTournament, handleClose: handleClose }, void 0), _jsx(Divider, { light: true }, void 0)] }, index));
                }), isLoading && _jsx(Loading, {}, void 0)] }), void 0)) : (_jsx("div", __assign({ className: "flex grow justify-center dark:text-white" }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1.8rem', fontWeight: 400 } }, { children: t('Ни один спортсмен не найден') }), void 0) }), void 0)) }), void 0));
};
export default SportEventUsersSearchResultList;
