var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import GoogleSignIn from './GoogleSignIn/GoogleSignIn';
import StravaSignIn from './StravaSignIn/StravaSignIn';
import VKSignIn from './VKSignIn/VKSignIn';
import YandexSignIn from './YandexSignIn/YandexSignIn';
var SocialSignIn = function (_a) {
    var _b = _a.socialLogin, socialLogin = _b === void 0 ? { googleAccount: true, vkAccount: true, stravaAccount: false, yandexAccount: true } : _b;
    return (_jsx(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '0.5rem' } }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '22rem' } }, { children: [socialLogin.googleAccount && _jsx(GoogleSignIn, {}, void 0), socialLogin.vkAccount && _jsx(VKSignIn, {}, void 0), socialLogin.stravaAccount && _jsx(StravaSignIn, {}, void 0), socialLogin.yandexAccount && _jsx(YandexSignIn, {}, void 0)] }), void 0) }), void 0));
};
export default SocialSignIn;
