var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { DialogActions, Typography } from '@mui/material';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
var ExpelUserRequestDialog = function (_a) {
    var from = _a.from, handleExpel = _a.handleExpel, handleClose = _a.handleClose;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsxs("div", __assign({ className: "tw-dialog-header" }, { children: ["\u0418\u0441\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F \u0438\u0437 ", from === 'club' ? 'клуба' : 'команды'] }), void 0), _jsxs(Typography, __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: ["\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0438\u0441\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F \u0438\u0437 ", from === 'club' ? 'клуба' : 'команды', "?"] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleExpel();
                            handleClose();
                        }, type: "primary sm" }, { children: "\u0418\u0441\u043A\u043B\u044E\u0447\u0438\u0442\u044C" }), void 0)] }), void 0)] }), void 0));
};
export default ExpelUserRequestDialog;
