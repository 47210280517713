var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Autocomplete, DialogActions, TextField } from '@mui/material';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_ACTIVITY_VALIDATION } from 'common/graphql/mutations/activity/updateActivityValidation/updateActivityValidation';
import { ACTIVITY_VALIDATION } from 'common/graphql/queries/activity/activityValidation';
import { SPORT_EVENTS_SHORT } from 'common/graphql/queries/sportEvent/sportEventsShort';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import ActivityValidationLevelSelect from 'components/Select/ActivityValidationLevelSelect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var ActivityValidationChangeLevelDialog = function (_a) {
    var activityValidation = _a.activityValidation, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var _b = useState(activityValidation.validationLevel), validationLevel = _b[0], setValidationLevel = _b[1];
    var _c = useState(undefined), targetEntityId = _c[0], setTargetEntityId = _c[1];
    var _d = useLazyQuery(SPORT_EVENTS_SHORT), getSportEvents = _d[0], _e = _d[1], sportEventsData = _e.data, loading = _e.loading;
    var sportEvents = (sportEventsData === null || sportEventsData === void 0 ? void 0 : sportEventsData.sportEvents) || [];
    var formatSportEvents = function (sportEvents) {
        return sportEvents.map(function (sportEvent) {
            var _a;
            return {
                label: ((_a = sportEvent.mainSettings) === null || _a === void 0 ? void 0 : _a.displayName) || sportEvent.name,
                id: sportEvent.id
            };
        });
    };
    var _f = useState(null), value = _f[0], setValue = _f[1];
    useEffect(function () {
        if (validationLevel === ActivityValidationLevels.SportEvent) {
            getSportEvents();
        }
    }, [validationLevel]);
    useEffect(function () {
        var _a;
        if ((sportEventsData === null || sportEventsData === void 0 ? void 0 : sportEventsData.sportEvents) && ((_a = sportEventsData === null || sportEventsData === void 0 ? void 0 : sportEventsData.sportEvents) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var sportEventsFormatted = formatSportEvents(sportEvents);
            setValue(sportEventsFormatted.filter(function (se) { var _a; return se.id === ((_a = activityValidation.sportEvent) === null || _a === void 0 ? void 0 : _a.id); })[0] || null);
        }
    }, [sportEventsData]);
    useEffect(function () {
        if (value)
            setTargetEntityId(value.id);
    }, [value]);
    var handleChange = function (name, value) {
        setValidationLevel(value);
    };
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0414\u0430\u043D\u043D\u044B\u0435 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u044B"),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateActivityValidation = function (data) {
        return useMutation(UPDATE_ACTIVITY_VALIDATION, {
            variables: {
                updateActivityValidationData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ACTIVITY_VALIDATION,
                    variables: {
                        id: activityValidation.id
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateActivityValidation, onSuccess, onError).handleExecuteMutation;
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-5 p-0 pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0443\u0440\u043E\u0432\u0435\u043D\u044C \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u0438" }), void 0), _jsx(ActivityValidationLevelSelect, { value: validationLevel, handleChange: handleChange, disableClearable: false }, void 0), validationLevel !== ActivityValidationLevels.Base && (_jsx(Autocomplete, { "aria-required": true, value: value, loading: loading, disablePortal: true, options: formatSportEvents(sportEvents), renderInput: function (params) { return (_jsx(TextField, __assign({ name: "address", label: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 ".concat(validationLevel === ActivityValidationLevels.SportEvent
                                ? 'событие'
                                : validationLevel === ActivityValidationLevels.SportClub
                                    ? 'клуб'
                                    : validationLevel === ActivityValidationLevels.Standings
                                        ? 'турнирную таблицу'
                                        : 'турнир') }, params), void 0)); }, onInputChange: function (event, newInputValue, reason) {
                            if (reason === 'clear') {
                                setTargetEntityId(undefined);
                            }
                        }, onChange: function (event, newValue) {
                            setValue(newValue);
                        }, isOptionEqualToValue: function (option, value) { return option.id === value.id; } }, void 0))] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, onClick: function () {
                            handleExecuteMutation({ id: activityValidation.id, validationLevel: validationLevel, targetEntityId: targetEntityId });
                        }, type: "primary sm" }, { children: t('Сохранить') }), void 0)] }), void 0)] }, void 0));
};
export default ActivityValidationChangeLevelDialog;
