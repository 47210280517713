import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { UserLanguage } from 'common/enums/user/user-language.enum';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeDialog, openDialog } from 'store/actions/base/dialog.actions';
var UserData = function (_a) {
    var children = _a.children, userProfile = _a.userProfile;
    var isShownAddTraningSourceDialog = localStorage.getItem('isShownAddTraningSourceDialog');
    var dispatch = useDispatch();
    var i18n = useTranslation().i18n;
    useEffect(function () {
        if (userProfile) {
            var _a = userProfile || {}, id = _a.id, lang = _a.lang, mainSettings = _a.mainSettings, polarSettings = _a.polarSettings, stravaSettings = _a.stravaSettings, googleSettings = _a.googleSettings;
            var _b = mainSettings || {}, firstName = _b.firstName, lastName = _b.lastName, birthDate = _b.birthDate, gender = _b.gender;
            i18n.changeLanguage(lang === UserLanguage.En ? 'en' : 'ru');
            if (!firstName || !lastName || !birthDate || !gender) {
                dispatch(openDialog({
                    dialogType: DialogTypes.FillUserProfile,
                    dialogProps: {
                        id: id,
                        firstName: firstName,
                        lastName: lastName,
                        birthDate: birthDate,
                        gender: gender
                    }
                }));
            }
            else if (!(polarSettings === null || polarSettings === void 0 ? void 0 : polarSettings.length) && !(stravaSettings === null || stravaSettings === void 0 ? void 0 : stravaSettings.length) && !(googleSettings === null || googleSettings === void 0 ? void 0 : googleSettings.isDownloadFitnessData)) {
                if (isShownAddTraningSourceDialog === 'false' || !isShownAddTraningSourceDialog) {
                    dispatch(openDialog({
                        dialogType: DialogTypes.AddTrainingSource,
                        dialogProps: {}
                    }));
                }
                else {
                    dispatch(closeDialog());
                }
            }
            else {
                dispatch(closeDialog());
            }
        }
    }, [userProfile]);
    return _jsx(_Fragment, { children: children }, void 0);
};
export default UserData;
