var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { closeDialog } from 'store/actions';
import { SpecificDialogsConfig } from './SpecificDialogsConfig';
var RootDialog = function () {
    var _a, _b;
    var dispatch = useDispatch();
    var search = useLocation().search;
    var history = useHistory();
    var _c = useState(undefined), dialogConfig = _c[0], setDialogConfig = _c[1];
    var dialogType = useSelector(function (_a) {
        var base = _a.base;
        return base.dialog.dialog.dialogType;
    });
    var dialogProps = useSelector(function (_a) {
        var base = _a.base;
        return base.dialog.dialog.dialogProps;
    });
    useEffect(function () {
        if (dialogType) {
            setDialogConfig(SpecificDialogsConfig[dialogType]);
            // Вызывает ошибку, нужно придумать другой способ, если нужно при открытии окна нужно менять query params
            // const query = new URLSearchParams(search);
            // query.set('modal', dialogType);
            // history.replace({
            // 	search: query.toString(),
            // });
        }
    }, [dialogType]);
    var SpecificDialog = dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.dialog;
    var dialogName = dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.name;
    var mounted = useRef();
    useEffect(function () {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = dialogName;
        }
        else if (mounted.current !== dialogName) {
            mounted.current = dialogName;
            // do componentDidUpdate logic
        }
    });
    var handleClose = function (event, reason) {
        if ((dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.settings.disableBackdropClick) && reason && reason == 'backdropClick')
            return;
        if (dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.beforeClose) {
            dialogConfig.beforeClose();
        }
        //для того чтобы избежать ситуации, когда открыли сразу другое окно, а предыдущее еще не успелось закрыться
        if (mounted.current === dialogName) {
            dispatch(closeDialog());
        }
        var query = new URLSearchParams(search);
        var openModalQuery = query.get('modal');
        if (openModalQuery) {
            query["delete"]('modal');
            query["delete"]('modalProps');
            history.replace({
                search: query.toString()
            });
        }
    };
    // when going back in browser, close dialog
    window.addEventListener('popstate', function () {
        handleClose({});
    });
    return (_jsx("div", { children: dialogType && (_jsx(Modal, __assign({ open: true, onClose: handleClose, disableEscapeKeyDown: dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.settings.disableEscapeKeyDown, "aria-labelledby": "simple-modal-title", "aria-describedby": "simple-modal-description" }, { children: _jsxs("div", __assign({ className: clsx('xs:p-28 top-1/2 left-1/2  -translate-x-2/4 -translate-y-2/4 ', 'absolute sm:rounded-8 bg-bg-container-outer-lt dark:bg-bg-container-outer-dk sm:shadow', ((_a = dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.settings) === null || _a === void 0 ? void 0 : _a.size) ||
                    'xs:h-full sm:h-auto xs:max-w-full xs:w-full sm:w-auto min-w-320 md:max-h-840 overflow-auto') }, { children: [((_b = dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.settings) === null || _b === void 0 ? void 0 : _b.closeButton) && (_jsx("div", __assign({ className: "absolute flex justify-end dark:text-white top-12 right-12" }, { children: _jsx(IconButton, __assign({ className: "h-16 w-16 p-0", onClick: function (event) { return handleClose(event); } }, { children: _jsx(CloseIcon, {}, void 0) }), void 0) }), void 0)), SpecificDialog && _jsx(SpecificDialog, __assign({}, dialogProps, { handleClose: handleClose }), void 0)] }), void 0) }), void 0)) }, void 0));
};
export default RootDialog;
