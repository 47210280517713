var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { useUpdateSportClubMutation } from 'common/hooks/sportClub/useUpdateSportClubMutation/useUpdateSportClubMutation';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import InvitationLink from '../InvitationLink/InvitationLink';
import JoinClubViaIntitationLinkCheckbox from '../JoinClubViaInvitationLinkCheckbox/JoinClubViaInvitationLinkCheckbox';
import ParticipationSettingsRadioButtonsGroup, { ParticipationSettingsRadioButtonsGroupValue, } from '../ParticipationSettingsRadioButtonsGroup/ParticipationSettingsRadioButtonsGroup';
var ClubParticipantsSettingsContent = function (_a) {
    var _b, _c, _d;
    var sportClub = _a.sportClub;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var handleExecuteMutation = useDynamicMutation(useUpdateSportClubMutation, onSuccess, onError).handleExecuteMutation;
    var handleChangeParticipationSettings = function (e) {
        var value = e.target.value;
        handleExecuteMutation({
            id: sportClub === null || sportClub === void 0 ? void 0 : sportClub.id,
            allUsersCanJoin: value === ParticipationSettingsRadioButtonsGroupValue.allUsersCanJoin,
            joinViaRequest: value !== ParticipationSettingsRadioButtonsGroupValue.allUsersCanJoin
        });
    };
    var handleChangeJoinClubViaIntitationLink = function (e) {
        var value = e.target.checked;
        handleExecuteMutation({
            id: sportClub === null || sportClub === void 0 ? void 0 : sportClub.id,
            joinViaLink: value
        });
    };
    return (_jsx("div", __assign({ className: "pt-20" }, { children: sportClub && (_jsx("form", { children: _jsxs("div", __assign({ className: "w-full flex flex-col" }, { children: [_jsx("div", __assign({ className: "mb-8" }, { children: _jsx(ParticipationSettingsRadioButtonsGroup, { value: ((_b = sportClub === null || sportClub === void 0 ? void 0 : sportClub.participationSettings) === null || _b === void 0 ? void 0 : _b.allUsersCanJoin)
                                ? ParticipationSettingsRadioButtonsGroupValue.allUsersCanJoin
                                : ParticipationSettingsRadioButtonsGroupValue.joinViaRequest, onChange: handleChangeParticipationSettings, name: "participationSettingsValue" }, void 0) }), void 0), _jsx("div", { children: _jsx(JoinClubViaIntitationLinkCheckbox, { value: (_c = sportClub === null || sportClub === void 0 ? void 0 : sportClub.participationSettings) === null || _c === void 0 ? void 0 : _c.joinViaLink, onChange: handleChangeJoinClubViaIntitationLink, name: "isJoinClubViaIntitationLink" }, void 0) }, void 0), ((_d = sportClub === null || sportClub === void 0 ? void 0 : sportClub.participationSettings) === null || _d === void 0 ? void 0 : _d.joinViaLink) && _jsx(InvitationLink, { sportClubId: sportClub.id }, void 0)] }), void 0) }, void 0)) }), void 0));
};
export default ClubParticipantsSettingsContent;
