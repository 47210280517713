var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { Button, DialogActions, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CREATE_STANDINGS_COLUMNS } from 'common/graphql/mutations/standings/create-standings-columns';
import { STANDINGS_COLUMNS } from 'common/graphql/queries/standings/standingsColumns';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import standingsColumnValidationSchema from 'common/validationSchemas/standingsColumnValidationSchema';
import AllowedPropertySelect from 'components/Select/AllowedPropertySelect';
import SportTypeSelect from 'components/Select/SportTypeSelect';
import StandingsColumnSelect from 'components/Select/StandingsColumnSelect';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var AddStandingsColumnDialog = function (_a) {
    var handleClose = _a.handleClose, standingsParticipantType = _a.standingsParticipantType, standingsParentType = _a.standingsParentType, standingsSports = _a.standingsSports, standingsId = _a.standingsId;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var defaultFormState = {
        columnType: undefined,
        sportType: undefined,
        properties: [],
        columnName: ''
    };
    var createStandingsColumn = function (variables) {
        return useMutation(CREATE_STANDINGS_COLUMNS, {
            variables: {
                createStandingsColumnsData: __assign({}, variables)
            },
            refetchQueries: [
                {
                    query: STANDINGS_COLUMNS,
                    variables: {
                        id: standingsId
                    }
                },
            ]
        });
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function (response) {
        handleClose();
        dispatch(openSnackbar({
            message: (response === null || response === void 0 ? void 0 : response.createStandingsColumns[0].columnName)
                ? "\u0421\u0442\u043E\u043B\u0431\u0435\u0446 \"".concat(response === null || response === void 0 ? void 0 : response.createStandingsColumns[0].columnName, "\" \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0437\u0434\u0430\u043D")
                : 'Столбец успешно создан',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var handleExecuteMutation = useDynamicMutation(createStandingsColumn, onSuccess, onError).handleExecuteMutation;
    var convetToCreateStandingsColumnsData = function (data) {
        var _a;
        var column = {
            columnId: (_a = data.columnType) === null || _a === void 0 ? void 0 : _a.id,
            columnName: data.columnName,
            sport: data.sportType,
            properties: data.properties
        };
        !!!data.sportType && delete column['sport'];
        return { standingsColumns: [column] };
    };
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign(__assign({}, convetToCreateStandingsColumnsData(data)), { standingsId: standingsId }));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: standingsColumnValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    return (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", __assign({ className: "flex flex-col pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0441\u0442\u043E\u043B\u0431\u0446\u0430 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0442\u0430\u0431\u043B\u0438\u0446\u044B" }), void 0), _jsxs("div", __assign({ className: "pb-12" }, { children: [_jsx(StandingsColumnSelect, { value: formik.values['columnType'], handleChange: function (name, value) {
                                formik.setFieldValue(name, value);
                                formik.setFieldError(name, undefined);
                            }, disableClearable: true, errorMessage: formik.errors['columnType'], name: "columnType", standingsParticipantType: standingsParticipantType, standingsParentType: standingsParentType }, void 0), formik.values['columnType'] && formik.values['columnType'].sportTypeRequired && (_jsx(SportTypeSelect, { value: formik.values['sportType'], name: "sportType", standingsSports: standingsSports, handleChange: function (name, value) {
                                formik.setFieldValue(name, value === null || value === void 0 ? void 0 : value.name);
                                formik.setFieldError(name, undefined);
                            }, disableClearable: true, errorMessage: formik.errors['sportType'] }, void 0)), formik.values['columnType'] && (_jsx(TextField, { className: "w-full", label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0441\u0442\u043E\u043B\u0431\u0446\u0430", autoFocus: true, name: "columnName", value: formik.values['columnName'], onChange: handleChangeWrapper, error: Boolean(formik.errors['columnName']), helperText: _jsx("div", __assign({ className: "whitespace-pre-wrap" }, { children: formik.errors['columnName'] }), void 0) }, void 0)), formik.values['columnType'] &&
                            formik.values['columnType'].allowedProperties &&
                            formik.values['columnType'].allowedProperties.length > 0 && (_jsxs("div", __assign({ className: "flex flex-col pt-8" }, { children: [_jsx("p", __assign({ className: "dark:text-white" }, { children: "\u0414\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0435 \u0441\u0432\u043E\u0439\u0441\u0442\u0432\u0430:" }), void 0), _jsx("div", __assign({ className: "flex flex-col pt-8" }, { children: formik.values['columnType'].allowedProperties.map(function (property) {
                                        var _a;
                                        var val = (_a = formik.values['properties'].find(function (option) { return option.name === property.name; })) === null || _a === void 0 ? void 0 : _a.value;
                                        return (_jsx(AllowedPropertySelect, { label: property.displayName, handleChange: function (name, value) {
                                                var properties = __spreadArray(__spreadArray([], formik.values['properties'].filter(function (prop) {
                                                    return prop.name !== property.name;
                                                }), true), [
                                                    { name: name, value: value },
                                                ], false);
                                                formik.setFieldValue('properties', properties);
                                                formik.setFieldError(name, undefined);
                                            }, name: property.name, allowedProperties: property.allowedValues, value: val }, property.id));
                                    }) }), void 0)] }), void 0))] }), void 0), _jsxs(DialogActions, __assign({ sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 0
                    } }, { children: [_jsx(Button, __assign({ variant: "text", color: "secondary", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default AddStandingsColumnDialog;
