var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
var BasicTableComponents = {
    TableHeadComponent: function TableHeadComponent(_a) {
        var visibleColumns = _a.visibleColumns, order = _a.order, orderByColumn = _a.orderByColumn, setOrderAndOrderParam = _a.setOrderAndOrderParam, id = _a.id;
        var t = useTranslation().t;
        var handleRequestSort = function (property) { return function () {
            var isAsc = orderByColumn === property && order === 'asc';
            setOrderAndOrderParam({ orderBy: property, orderParam: isAsc ? 'desc' : 'asc' });
        }; };
        return (_jsx(TableHead, __assign({ id: id }, { children: _jsx(TableRow, { children: visibleColumns.map(function (column) { return (_jsx(TableCell, __assign({ className: "bg-bg-container-inner-lt dark:bg-bg-container-inner-dk", sx: __assign({ padding: { xs: '0.6rem', md: '0.8rem' } }, column.style), align: column.align || 'center', sortDirection: orderByColumn === column.id ? order : false }, { children: column.sort ? (_jsx(TableSortLabel, __assign({ active: orderByColumn === column.id, direction: orderByColumn === column.id ? order : 'asc', onClick: handleRequestSort(column.id) }, { children: t(column.label) }), void 0)) : (_jsx(_Fragment, { children: t(column.label) }, void 0)) }), column.id)); }) }, void 0) }), void 0));
    },
    TablePaginationComponent: function TablePaginationComponent(_a) {
        var rowsLength = _a.rowsLength, page = _a.page, setPage = _a.setPage, rowsPerPage = _a.rowsPerPage, setRowsPerPage = _a.setRowsPerPage;
        var t = useTranslation().t;
        var handleChangePage = function (_event, page) {
            setPage(page);
        };
        var handleChangeRowsPerPage = function (event) {
            setRowsPerPage(+event.target.value);
            // setPage(0);
        };
        //MuiToolbar-root
        return (_jsx(TablePagination, { className: "h-48 xs:text12 md:text-14 [&>.MuiToolbar-root]:min-h-40 xs:[&>.MuiToolbar-root]:p-0\n\t\t\t\tsm:[&>.MuiToolbar-root]:p-0", rowsPerPageOptions: [10, 25, 100], component: "div", count: rowsLength, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: t('Показывать по') + ':', labelDisplayedRows: function (_a) {
                var from = _a.from, to = _a.to, count = _a.count;
                return _jsx("span", { children: "".concat(from, "\u2013").concat(to, " ").concat(t('из'), " ").concat(count) }, void 0);
            } }, void 0));
    },
    EmptyDataMessageComponent: function EmptyDataMessageComponent(_a) {
        var onEmptyMessage = _a.onEmptyMessage;
        return (_jsx("div", __assign({ className: "flex justify-center p-18" }, { children: _jsx(Typography, { children: onEmptyMessage }, void 0) }), void 0));
    }
};
export default BasicTableComponents;
