var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading/Loading';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
import SportClubContact from './components/SportClubContact/SportClubContact';
var ClubContacts = function (_a) {
    var clubId = _a.clubId;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useQuery(SPORT_CLUB, {
        variables: {
            id: clubId
        }
    }), data = _b.data, loading = _b.loading;
    var sportClubSocialLinks = (data === null || data === void 0 ? void 0 : data.sportClub.socialLinks) || [];
    var openCreateSportClubSocialLinkDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.CreateSportClubSocialLink,
            dialogProps: {
                sportClubId: clubId
            }
        }));
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Контакты клуба') }, void 0), content: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [sportClubSocialLinks.map(function (socialLink) {
                    return (_jsx("div", __assign({ className: "flex" }, { children: _jsx(SportClubContact, { socialLink: socialLink, sportClubId: clubId }, socialLink.id) }), socialLink.id));
                }), _jsx("div", { children: _jsx(Button, __assign({ className: "xs:my-12", type: "primary", onClick: openCreateSportClubSocialLinkDialog }, { children: t('Добавить') }), void 0) }, void 0)] }), void 0) }, void 0));
};
export default ClubContacts;
