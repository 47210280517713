var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { LinearProgress } from '@mui/material';
import useTimeout from 'common/hooks/useTimeout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var Loading = function (_a) {
    var delay = _a.delay;
    var _b = useState(!delay), showLoading = _b[0], setShowLoading = _b[1];
    var t = useTranslation().t;
    useTimeout(function () {
        setShowLoading(true);
    }, delay);
    if (!showLoading) {
        return null;
    }
    return (_jsxs("div", __assign({ className: "flex flex-1 flex-col items-center justify-center" }, { children: [_jsxs("div", __assign({ className: "text-14 mb-16 dark:text-white" }, { children: [t('Загрузка'), "..."] }), void 0), _jsx(LinearProgress, { className: "xs:w-160 md:w-256", color: "secondary" }, void 0)] }), void 0));
};
export default Loading;
