var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import TelegramContact from 'assets/icons/contacts/telegram';
import VKContact from 'assets/icons/contacts/VK';
import WhatsAppContact from 'assets/icons/contacts/whatsapp';
import clsx from 'clsx';
import { TeamContactTypes } from 'common/enums/team/team-contact-types.enum';
import { TEAM_CONTACTS } from 'common/graphql/queries/team/team-contacts';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import JoinTeamButton from '../../JoinTeamButton';
import TeamAdditionalMenuButton from '../TeamAdditionalMenuButton';
import TeamUsersAvatarGroup from './TeamUsersAvatarGroup';
var TeamContactItem = function (_a) {
    var teamContact = _a.teamContact;
    return (_jsxs("div", __assign({ className: clsx('flex items-center xs:px-8 py-4') }, { children: [teamContact.type === TeamContactTypes.Telegram && _jsx(TelegramContact, { className: "dark:fill-white" }, void 0), teamContact.type === TeamContactTypes.VK && _jsx(VKContact, { className: "dark:fill-white" }, void 0), teamContact.type === TeamContactTypes.Whatsapp && _jsx(WhatsAppContact, { className: "dark:fill-white" }, void 0), _jsx(Link, __assign({ className: "tw-club-contact-link", to: { pathname: teamContact.url }, target: "_blank" }, { children: teamContact.text }), void 0)] }), void 0));
};
var TeamProfilePageHeader = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var team = _a.team, isUserTeamParticipant = _a.isUserTeamParticipant;
    var t = useTranslation().t;
    var data = useQuery(TEAM_CONTACTS, {
        variables: {
            teamId: team.id
        }
    }).data;
    var teamContacts = data === null || data === void 0 ? void 0 : data.teamContacts;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id;
    var isAdmin = !!((_c = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _b === void 0 ? void 0 : _b.map(function (role) { return role.name; })) === null || _c === void 0 ? void 0 : _c.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    var isSportEventRegistrationOpen = ((_g = (_f = (_e = (_d = team === null || team === void 0 ? void 0 : team.tournaments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.sportEvent) === null || _f === void 0 ? void 0 : _f.datesSettings) === null || _g === void 0 ? void 0 : _g.registrationStartDate) &&
        ((_l = (_k = (_j = (_h = team === null || team === void 0 ? void 0 : team.tournaments) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.sportEvent) === null || _k === void 0 ? void 0 : _k.datesSettings) === null || _l === void 0 ? void 0 : _l.registrationEndDate)
        ? new Date() > new Date((_q = (_p = (_o = (_m = team === null || team === void 0 ? void 0 : team.tournaments) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.sportEvent) === null || _p === void 0 ? void 0 : _p.datesSettings) === null || _q === void 0 ? void 0 : _q.registrationStartDate) &&
            new Date() < new Date((_u = (_t = (_s = (_r = team === null || team === void 0 ? void 0 : team.tournaments) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.sportEvent) === null || _t === void 0 ? void 0 : _t.datesSettings) === null || _u === void 0 ? void 0 : _u.registrationEndDate)
        : false;
    return (_jsxs("div", __assign({ className: "club-profile-card flex justify-between" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(UserAvatar, { style: "\n                xs:w-80 md:w-128 xl:w-136 \n                xs:h-80 md:h-128 xl:h-136 text-22", firstName: team.name, profilePhoto: team.profilePhoto || undefined }, void 0), _jsxs("div", __assign({ className: "flex flex-col xs:px-12 md:px-16 xs:max-w-400 sm:max-w-840" }, { children: [_jsxs("div", __assign({ className: clsx('flex xs:flex-col sm:flex-row sm:space-x-10 xs:space-x-0 sm:items-center') }, { children: [_jsx("p", __assign({ className: "xs:text-18 md:text-20 font-semibold [overflow-wrap:anywhere]" }, { children: team.displayName || team.name }), void 0), _jsx("div", __assign({ className: "flex h-full text-gray xs:text-12 md:text-14 items-center" }, { children: team.areYouParticipant ? t('Ваша команда') : t('Команда') }), void 0), team.areYouCaptain && (_jsx("div", __assign({ className: "flex h-full text-green xs:text-12 md:text-14 py-2 items-end" }, { children: t('Вы капитан команды') }), void 0)), currentUserId &&
                                        team.participationSettings.isPrivate === false &&
                                        !isUserTeamParticipant &&
                                        isSportEventRegistrationOpen && (_jsx("div", __assign({ className: "flex items-center sm:pl-10 xs:pt-10 sm:pt-0" }, { children: _jsx(JoinTeamButton, { userId: currentUserId, team: team }, void 0) }), void 0))] }), void 0), _jsxs("div", __assign({ className: "flex flex-col text-14 dark:text-gray-300 text-gray-700 pt-12 space-y-5" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('Турнир'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 cursor-pointer", onClick: function () {
                                                    var _a, _b, _c, _d, _e;
                                                    return history.push(((_b = (_a = team.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.sportEvent)
                                                        ? "/event/".concat((_e = (_d = (_c = team.tournaments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.sportEvent) === null || _e === void 0 ? void 0 : _e.url)
                                                        : "/events");
                                                } }, { children: ((_y = (_x = (_w = (_v = team.tournaments) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.sportEvent) === null || _x === void 0 ? void 0 : _x.mainSettings) === null || _y === void 0 ? void 0 : _y.displayName) || '' }), void 0)] }), void 0), (team === null || team === void 0 ? void 0 : team.id) && (team === null || team === void 0 ? void 0 : team.usersCount) !== undefined && team.usersCount > 0 && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('Участники'), ":\u00A0"] }), void 0), _jsx(TeamUsersAvatarGroup, { teamId: team.id, teamUsersTotalCount: team.usersCount }, void 0)] }), void 0)), teamContacts && teamContacts.length > 0 && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-start" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray py-4" }, { children: [t('Контакты'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex flex-col space-y-5" }, { children: teamContacts.map(function (teamContact) {
                                                    return _jsx(TeamContactItem, { teamContact: teamContact }, teamContact.id);
                                                }) }), void 0)] }), void 0)), team.description && team.description !== '<p></p>' && (_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-y-5 sm:space-y-0" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('О команде'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: _jsx(ReadOnlyEditor, { value: team.description }, void 0) }), void 0)] }), void 0))] }), void 0)] }), void 0)] }), void 0), (team === null || team === void 0 ? void 0 : team.id) && _jsx("div", { children: isAdmin && _jsx(TeamAdditionalMenuButton, { teamId: team.id }, void 0) }, void 0)] }), void 0));
};
export default TeamProfilePageHeader;
