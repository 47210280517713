import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { ORGANIZATION } from 'common/graphql/queries/organization/organization';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Loading from 'components/Loading/Loading';
import PageCarded from 'components/PageLayouts/carded/PageCarded';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import SidebarContent from '../../layouts/shared-components/SidebarContent/SidebarContent';
import organizationSettingsNavigationConfig from './organizationSettingsNavigationConfig';
var OrganizationSettingsPage = function (props) {
    var dispatch = useDispatch();
    var organizationId = useParams().organizationId;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var _a = useState(undefined), isUserCanAdministrateOrganization = _a[0], setIsUserCanAdministrateOrganization = _a[1];
    var _b = useQuery(ORGANIZATION, {
        variables: {
            id: organizationId
        }
    }), data = _b.data, loading = _b.loading;
    var organization = data === null || data === void 0 ? void 0 : data.organization;
    useEffect(function () {
        var _a, _b, _c;
        if (organization && userProfile) {
            var isAdmin = !!((_b = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _a === void 0 ? void 0 : _a.map(function (role) { return role.name; })) === null || _b === void 0 ? void 0 : _b.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
            var isUserOrganizationOwner = ((_c = userProfile === null || userProfile === void 0 ? void 0 : userProfile.organization) === null || _c === void 0 ? void 0 : _c.id) === organization.id;
            if (isAdmin || isUserOrganizationOwner)
                setIsUserCanAdministrateOrganization(true);
        }
    }, [data]);
    useEffect(function () {
        if (isUserCanAdministrateOrganization === false) {
            dispatch(openSnackbar({
                message: 'У вас нет прав на управление этой организацией',
                severity: SnackbarSeverity.ERROR
            }));
        }
    }, [isUserCanAdministrateOrganization]);
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageCarded, { classes: {
            root: 'w-full',
            header: 'hidden',
            topBg: 'hidden',
            content: 'flex flex-col',
            sidebar: 'xs:px-8 md:px-12 xl:px-24'
        }, breadcrumbs: _jsx(Breadcrumbs, {}, void 0), content: isUserCanAdministrateOrganization && _jsx(_Fragment, { children: props.children }, void 0), rightSidebarContent: isUserCanAdministrateOrganization && (_jsx(SidebarContent, { navigationConfigItems: organizationSettingsNavigationConfig, mainPathname: 'organization-settings/' + organizationId }, void 0)), mobileSidebarContent: true }, void 0));
};
export default OrganizationSettingsPage;
