var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, TextField, Typography } from '@mui/material';
import { UserLanguage } from 'common/enums/user/user-language.enum';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import ResendCodeLink from 'components/Link/ResendCodeLink';
import { useFormik } from 'formik';
import i18n from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cleanRegisterError, resendCode, verifyEmailRegistration } from 'store/actions';
var InputVerificationKeyForm = function (_a) {
    var _b, _c, _d;
    var navigateTo = _a.navigateTo;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var register = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.register;
    });
    var email = useSelector(function (_a) {
        var auth = _a.auth;
        return auth.register.currentEmail;
    });
    useEffect(function () {
        return function () {
            //componentWillUnmount удалить ошибку
            dispatch(cleanRegisterError());
        };
    }, []);
    var handleChangeWrapper = function (e) {
        var _a, _b;
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
        //если при регистрации была ошибка,
        // то при внесении изменений в форму ошибка удаляется
        if (((_a = register.error) === null || _a === void 0 ? void 0 : _a.errorCode) || ((_b = register.error) === null || _b === void 0 ? void 0 : _b.verification_key)) {
            dispatch(cleanRegisterError());
        }
    };
    var onResendCodeLinkClick = function () {
        if (email) {
            dispatch(resendCode({ email: email, lang: i18n.language === 'en' ? UserLanguage.En : UserLanguage.Ru }));
        }
    };
    var defaultFormState = {
        email: email,
        key: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(verifyEmailRegistration(value, navigateTo));
            // navigateToVar({
            // 	navigateTo: undefined,
            // });
        },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsxs(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: [_jsx(Typography, __assign({ sx: { fontSize: '2rem', mb: '2rem', fontWeight: 500, lineHeight: 1 } }, { children: t('Подтверждение регистрации') }), void 0), _jsxs(Typography, __assign({ sx: { fontSize: '1.4rem', mb: '1rem', fontWeight: 400, lineHeight: 1, textAlign: 'center' } }, { children: [t('Мы отправили 6-значный код на'), " ", _jsxs("strong", { children: [email, " "] }, void 0)] }), void 0), _jsx(Typography, __assign({ sx: { fontSize: '1.4rem', fontWeight: 300, lineHeight: 1, textAlign: 'center' } }, { children: t('Подтвердите, что e-mail принадлежит вам, чтобы обезопасить свою учетную запись.') }), void 0)] }), void 0), _jsxs(Box, __assign({ sx: { width: '32rem' } }, { children: [_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: _jsx(TextField, { label: t('Проверочный код'), name: "key", value: formik.values['key'], onChange: handleChangeWrapper, error: Boolean(formik.errors['key']), helperText: formik.errors['key'], autoFocus: true }, void 0) }), void 0), (((_b = register.error) === null || _b === void 0 ? void 0 : _b.errorCode) || ((_c = register.error) === null || _c === void 0 ? void 0 : _c.verification_key)) && (_jsx(Box, __assign({ sx: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '32rem',
                                        color: 'red'
                                    } }, { children: _jsx(Typography, { children: t(register.error.errorCode || ((_d = register.error) === null || _d === void 0 ? void 0 : _d.verification_key)) }, void 0) }), void 0)), _jsx(Box, __assign({ sx: { width: '32rem', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '32rem' }, type: "submit" }, { children: t('Продолжить0') }), void 0) }), void 0)] }), void 0) }), void 0), _jsx(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', mt: '4.5rem' } }, { children: _jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx(ResendCodeLink, { initialMinute: 0, initialSeconds: 60, callBackFunction: onResendCodeLinkClick }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0));
};
export default InputVerificationKeyForm;
