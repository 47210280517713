var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import HikingIcon from '@mui/icons-material/Hiking';
import PoolIcon from '@mui/icons-material/Pool';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Tooltip } from '@mui/material';
import { yellow } from '@mui/material/colors';
import clsx from 'clsx';
import { ActivityValidationTypes } from 'common/enums/activity/activity-validation-types.enum';
import { Gender } from 'common/enums/common/gender';
import { BasicTableColumnType } from 'common/enums/components/basic-table-column-type.enum';
import { VirtualPointsBoosterTypesReadableRu } from 'common/enums/feature/virtual-points-booster-types.enum';
import ActivityDownloadStatus from 'components/Badge/ActivityDownloadStatus/ActivityDownloadStatus';
import PointsWithBoosterApplied from 'components/Badge/PointsWithBoosterApplied/PointsWithBoosterApplied';
import ValidationStatus from 'components/Badge/ValidationStatus/ValidationStatus';
import ActivitySourceLinkButton from 'components/Buttons/ActivitySourceLinkButton/ActivitySourceLinkButton';
import { format } from 'date-fns';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import HelperFunctions from 'utils/HelperFunctions';
import { FinishingConditionStatus } from './celComponents/FinishingConditionStatusCell';
import { IsGoalReachedCell } from './celComponents/IsGoalReachedCell';
import { SportClubUserStatus } from './celComponents/SportClubUserStatusCell';
var BasicTableHelper = /** @class */ (function () {
    function BasicTableHelper() {
        var _this = this;
        this.getRowNumberColumn = function (rank) {
            return rank
                ? { id: 'rank', data: 'rank', value: 'rank', label: '№', style: 'w-28 text-center' }
                : { id: 'rowNumber', data: 'rowNumber', value: 'rowNumber', label: '№', style: 'w-28 text-center' };
        };
        this.getComparator = function (order, orderByColumn) {
            return order === 'desc'
                ? function (a, b) { return _this.descendingComparator(a, b, orderByColumn); }
                : function (a, b) { return -_this.descendingComparator(a, b, orderByColumn); };
        };
        this.descendingComparator = function (a, b, orderByColumn) {
            if (b[orderByColumn] < a[orderByColumn]) {
                return -1;
            }
            if (b[orderByColumn] > a[orderByColumn]) {
                return 1;
            }
            return 0;
        };
        this.convertStandingsColumnsToBasic = function (standingsColumns, lang, rank, columnsFormatStyle) {
            var convertedColumns = standingsColumns.map(function (_a) {
                var id = _a.id, columnName = _a.columnName, name = _a.name, isCollapsed = _a.isCollapsed;
                return _this.getSimpleConvertedColumn(id, columnName, name, isCollapsed, lang, columnsFormatStyle);
            });
            return __spreadArray([_this.getRowNumberColumn(rank)], convertedColumns, true);
        };
        this.getSimpleConvertedColumn = function (id, label, name, isCollapsed, lang, columnsFormatStyle) {
            var _a = _this.getColumnFormatByName(name, lang, { collapsible: isCollapsed }, columnsFormatStyle), format = _a.format, sort = _a.sort, style = _a.style, collapsible = _a.collapsible;
            return {
                id: id,
                label: label,
                value: id,
                data: id,
                format: format,
                sort: sort,
                style: style,
                collapsible: collapsible
            };
        };
        this.addColumnFormatToColumnsList = function (columns, lang, rank) {
            var convertedColumns = columns.map(function (column) {
                column.format = _this.getColumnFormatByName(column.data, lang, column).format;
                return column;
            });
            return __spreadArray([_this.getRowNumberColumn(rank)], convertedColumns, true);
        };
        this.getColumnFormatByName = function (name, lang, column, columnsFormatStyle) {
            var formatStyle = columnsFormatStyle && columnsFormatStyle[name === null || name === void 0 ? void 0 : name.toLowerCase()];
            var timeFormat = HelperFunctions.timeFormat;
            switch (name === null || name === void 0 ? void 0 : name.toLowerCase()) {
                case BasicTableColumnType.userName:
                    return {
                        format: function (value, rowMeta) {
                            return (_jsx("div", __assign({ className: clsx((formatStyle === null || formatStyle === void 0 ? void 0 : formatStyle.style) ||
                                    'user-name flex items-start xs:ml-10 sm:ml-0 w-full md:w-1/2 text-left tw-link-btn') }, { children: _jsx(Link, __assign({ to: "/profile/".concat(rowMeta.UserName), onClick: function (e) { return e.stopPropagation(); }, className: "xs:underline md:no-underline" }, { children: (value === null || value === void 0 ? void 0 : value.trim()) ? value : "-" }), void 0) }), void 0));
                        },
                        sort: true,
                        style: 'max-w-180 text-center',
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false
                    };
                case BasicTableColumnType.distance: {
                    var format_1;
                    if (column === null || column === void 0 ? void 0 : column.distanceConvertToKm) {
                        format_1 = function (value) {
                            return _jsx(_Fragment, { children: value ? Math.trunc(parseFloat((value / 1000).toFixed(2)) * 100) / 100 : "-" }, void 0);
                        };
                    }
                    else {
                        format_1 = function (value) {
                            return _jsx(_Fragment, { children: value ? Math.trunc(value * 100) / 100 : "-" }, void 0);
                        };
                    }
                    return {
                        format: format_1,
                        sort: true,
                        style: 'w-96 text-center',
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false
                    };
                }
                case BasicTableColumnType.positiveElevation: {
                    var format_2 = function (value) {
                        return _jsx(_Fragment, { children: value ? Math.trunc(value * 100) / 100 : "-" }, void 0);
                    };
                    return {
                        format: format_2,
                        sort: true,
                        style: 'w-96 text-center',
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                }
                case BasicTableColumnType.tempo:
                    return {
                        format: function (value) { return HelperFunctions.getReadableTempo(value) || "-"; },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.ageGroup:
                    return {
                        format: function (value) { return value || "-"; },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.activitiesNumber:
                    return {
                        format: function (value) { return value || "-"; },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                //value && Math.floor((value / 1000) * 100) / 100 };
                case BasicTableColumnType.gender:
                    return {
                        format: function (value) {
                            return value ? (value.toLowerCase() === Gender.FEMALE.toLowerCase() ? 'Жен.' : 'Муж.') : '';
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'max-w-96  text-center'
                    };
                case BasicTableColumnType.sportType:
                    return {
                        format: function (_a) {
                            var name = _a.name;
                            switch (name) {
                                case 'Run':
                                    return _jsx(DirectionsRunIcon, {}, void 0);
                                case 'Ride':
                                    return _jsx(DirectionsBikeIcon, {}, void 0);
                                case 'Walking':
                                    return _jsx(HikingIcon, {}, void 0);
                                case 'Swimming':
                                    return _jsx(PoolIcon, {}, void 0);
                                case 'Skiing':
                                    return _jsx(DownhillSkiingIcon, {}, void 0);
                                case 'Unknown':
                                    return _jsx(_Fragment, { children: 'Не задан' }, void 0);
                                default:
                                    return _jsx(_Fragment, { children: name }, void 0);
                            }
                        },
                        style: 'items-center',
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.startDate:
                    return {
                        format: function (value) { return value && HelperFunctions.getActivityDate(value, lang); }
                    };
                case BasicTableColumnType.startDateLocal:
                    return {
                        // format: (value: any) => value && format(new Date(value), 'dd MMMM yyyy', { locale: ru }),
                        format: function (value) { return value && HelperFunctions.getActivityDate(value, lang); },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.movingTime:
                    return {
                        format: function (value) {
                            value && format(new Date(value), 'HH:mm');
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.elapsedTime:
                    return {
                        format: function (value) {
                            return timeFormat(value);
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.validation:
                    return {
                        format: function (_a) {
                            var validationType = _a.validationType, createdAt = _a.createdAt, id = _a.id;
                            var date = new Date(createdAt).toLocaleString();
                            return (_jsx("div", __assign({ className: "tw-link-btn" }, { children: _jsx(Link, __assign({ to: "/activity-validation/".concat(id), onClick: function (e) { return e.stopPropagation(); }, className: "xs:underline md:no-underline" }, { children: validationType === ActivityValidationTypes.Auto
                                        ? i18n.t("\u0410\u0432\u0442\u043E\u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044F") + " ".concat(date)
                                        : i18n.t("\u0412\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044F \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440\u043E\u043C") + " ".concat(date) }), void 0) }), void 0));
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.validationStatus:
                    return {
                        format: function (value) {
                            return _jsx(ValidationStatus, { validations: [value] }, void 0);
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'validation-status text-center'
                    };
                case BasicTableColumnType.validationsStatus:
                    return {
                        format: function (value) {
                            return (_jsx(ValidationStatus, { validations: value.validations, isShowTapText: true, isShowSeeDetailsText: true }, void 0));
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'validations-status text-center'
                    };
                case BasicTableColumnType.fullyDownloaded:
                    return {
                        format: function (value) {
                            return _jsx(ActivityDownloadStatus, { fullyDownloaded: value }, void 0);
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'validations-status text-center'
                    };
                case BasicTableColumnType.source:
                    return {
                        format: function (_a) {
                            var name = _a.name, sourceActivityId = _a.sourceActivityId;
                            return _jsx(ActivitySourceLinkButton, { name: name, sourceActivityId: sourceActivityId }, void 0);
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.points:
                    return {
                        format: function (value) {
                            if (value)
                                return _jsx("p", { children: value }, void 0);
                            return _jsx("p", { children: "-" }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.pointsWithBoosterApplied:
                    return {
                        format: function (value) {
                            var _a;
                            return (value === null || value === void 0 ? void 0 : value.boosterApplied) ? (_jsx(PointsWithBoosterApplied, { points: value.points, boosterApplied: value.boosterApplied }, void 0)) : (_jsx("p", { children: (_a = value === null || value === void 0 ? void 0 : value.points) !== null && _a !== void 0 ? _a : '-' }, void 0));
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.coins:
                    return {
                        format: function (value) {
                            if (value)
                                return _jsx("p", { children: value }, void 0);
                            return _jsx("p", { children: "-" }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.activeness:
                    return {
                        format: function (value) {
                            if (value)
                                return _jsx("p", __assign({ className: "activeness" }, { children: value }), void 0);
                            return _jsx("p", __assign({ className: "activeness" }, { children: "-" }), void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.sportClubUserStatus:
                    return {
                        format: function (value) {
                            if (!value)
                                return _jsx("p", __assign({ className: "sportClubUserStatus" }, { children: "-" }), void 0);
                            if (typeof value === 'string')
                                return _jsx("p", __assign({ className: "sportClubUserStatus" }, { children: value }), void 0);
                            return _jsx(SportClubUserStatus, { value: value }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.averageKmPerUser:
                    return {
                        format: function (value) {
                            if (value)
                                return _jsx("p", { children: value }, void 0);
                            return _jsx("p", { children: "-" }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.participantsNumber:
                    return {
                        format: function (value) {
                            if (value)
                                return _jsx("p", { children: value }, void 0);
                            return _jsx("p", { children: "-" }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-96  text-center'
                    };
                case BasicTableColumnType.sportClubDisplayName:
                    return {
                        format: function (value, rowMeta) {
                            return (_jsx("div", __assign({ className: (formatStyle === null || formatStyle === void 0 ? void 0 : formatStyle.container) || 'container flex justify-center' }, { children: _jsx("div", __assign({ className: clsx((formatStyle === null || formatStyle === void 0 ? void 0 : formatStyle.inner) ||
                                        'sport-club-display-name items-start xs:ml-10 sm:ml-0 w-full md:w-1/2 text-left', 'tw-link-btn') }, { children: _jsx(Link, __assign({ to: "/club/".concat(rowMeta.SportClubName), onClick: function (e) { return e.stopPropagation(); }, className: "xs:underline md:no-underline" }, { children: (value === null || value === void 0 ? void 0 : value.trim()) ? value : "-" }), void 0) }), void 0) }), void 0));
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false,
                        style: 'w-160'
                    };
                case BasicTableColumnType.teamDisplayName:
                    return {
                        format: function (value, rowMeta) {
                            return (_jsx("div", __assign({ className: "team-display-name flex items-start xs:ml-10 sm:ml-0 w-full md:w-1/2 text-left tw-link-btn" }, { children: _jsx(Link, __assign({ to: "/team/".concat(rowMeta.TeamName), onClick: function (e) { return e.stopPropagation(); }, className: "xs:underline md:no-underline" }, { children: (value === null || value === void 0 ? void 0 : value.trim()) ? value : "-" }), void 0) }), void 0));
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false
                    };
                case BasicTableColumnType.userTeam:
                    return {
                        format: function (value) {
                            return value ? (_jsx("div", __assign({ className: "flex items-start xs:ml-10 sm:ml-0 w-full md:w-1/2 text-left tw-link-btn" }, { children: _jsx(Link, __assign({ to: "/team/".concat(value.teamName), onClick: function (e) { return e.stopPropagation(); }, className: "xs:underline md:no-underline" }, { children: value.teamDisplayName }), void 0) }), void 0)) : ('-');
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false
                    };
                case BasicTableColumnType.activeBoosters:
                    return {
                        format: function (value) {
                            return (_jsx("div", __assign({ className: clsx((formatStyle === null || formatStyle === void 0 ? void 0 : formatStyle.style) || '') }, { children: value ? (_jsx(Tooltip, __assign({ title: "\u0410\u043A\u0442\u0438\u0432\u0435\u043D \u0434\u043E ".concat(new Date(value.deactivateAt).toLocaleString()) }, { children: _jsx("div", { children: VirtualPointsBoosterTypesReadableRu[value.boosterName] }, void 0) }), void 0)) : ('-') }), void 0));
                        },
                        sort: false,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                case BasicTableColumnType.name: {
                    return {
                        format: function (value) {
                            var _a;
                            if (typeof value === 'string')
                                return value || "-";
                            var hasDuplicates = ((_a = value.duplicateActivities) === null || _a === void 0 ? void 0 : _a.length) > 0;
                            var isDuplicate = value.duplicatesActivity ? true : false;
                            var warningText = hasDuplicates
                                ? i18n.t('Тренировка имеет дубликаты')
                                : i18n.t('Тренировка является дубликатом другой тренировки');
                            return (_jsx("div", __assign({ className: clsx((formatStyle === null || formatStyle === void 0 ? void 0 : formatStyle.style) || 'xs:max-w-148 sm:max-w-200 break-words') }, { children: hasDuplicates || isDuplicate ? (_jsxs("div", __assign({ className: "flex items-center" }, { children: [value.name || '-', ' ', _jsx(Tooltip, __assign({ title: warningText }, { children: _jsx(WarningAmberIcon, { sx: { color: yellow[600] } }, void 0) }), void 0)] }), void 0)) : (value.name || '-') }), void 0));
                        },
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : false
                    };
                }
                case BasicTableColumnType.IsEliminated: {
                    return {
                        format: function (value) {
                            return ((value === null || value === void 0 ? void 0 : value.isEliminated) === true && (value === null || value === void 0 ? void 0 : value.isTournamentComplete) === true) ||
                                ((value === null || value === void 0 ? void 0 : value.isEliminated) === true &&
                                    (value === null || value === void 0 ? void 0 : value.isOnlyLastPeriodMissing) === false &&
                                    (value === null || value === void 0 ? void 0 : value.isTournamentComplete) === false) ? (_jsx("div", __assign({ className: "flex text-red items-center" }, { children: "\u0412\u044B\u0431\u044B\u043B" }), void 0)) : (value === null || value === void 0 ? void 0 : value.isEliminated) === false ||
                                ((value === null || value === void 0 ? void 0 : value.isEliminated) === true &&
                                    (value === null || value === void 0 ? void 0 : value.isOnlyLastPeriodMissing) === true &&
                                    (value === null || value === void 0 ? void 0 : value.isTournamentComplete) === false) ? (_jsx("div", __assign({ className: "flex text-green items-center" }, { children: "\u0423\u0447\u0430\u0441\u0442\u0432\u0443\u0435\u0442" }), void 0)) : ('-');
                        },
                        sort: false,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true
                    };
                }
                case BasicTableColumnType.IsGoalReached: {
                    return {
                        format: function (value) {
                            return (value === null || value === void 0 ? void 0 : value.isReached) === undefined ? '-' : _jsx(IsGoalReachedCell, { value: value }, void 0);
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-224 text-center'
                    };
                }
                case BasicTableColumnType.FinishingConditionStatus: {
                    return {
                        format: function (value) {
                            var _a;
                            return (value === null || value === void 0 ? void 0 : value.conditionStates) === undefined || !((_a = value === null || value === void 0 ? void 0 : value.conditionStates) === null || _a === void 0 ? void 0 : _a.length) ? ('-') : (_jsx(FinishingConditionStatus, { value: value }, void 0));
                        },
                        sort: true,
                        collapsible: (column === null || column === void 0 ? void 0 : column.collapsible) !== undefined ? column === null || column === void 0 ? void 0 : column.collapsible : true,
                        style: 'w-224 text-center'
                    };
                }
                default:
                    return { format: function (value) { return value || "-"; } };
            }
        };
    }
    return BasicTableHelper;
}());
var instance = new BasicTableHelper();
export default instance;
