var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@mui/material';
import GoogleFitIcon from 'assets/logos/GoogleFitIcon';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SocialTypes } from 'common/enums/user/social-types.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useSocialBindMutation } from 'common/hooks/useSocialBindMutation';
import { googleClientId } from 'common/socialClientIdsSetup';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var GoogleFitConnect = function (_a) {
    var isIconButton = _a.isIconButton, text = _a.text;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onSocialBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSocialBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Подключение аккаунта выполнено.'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var handleExecuteMutation = useDynamicMutation(useSocialBindMutation, onSocialBindSuccess, onSocialBindError).handleExecuteMutation;
    var handleBind = function (code) {
        handleExecuteMutation(__assign(__assign({}, code), { socialType: SocialTypes.Google, googleIsDownloadFitnessData: true }));
    };
    var SCOPES = 'https://www.googleapis.com/auth/fitness.activity.read ' +
        'https://www.googleapis.com/auth/fitness.heart_rate.read ' +
        'https://www.googleapis.com/auth/fitness.location.read';
    return (_jsx(GoogleLogin, { clientId: googleClientId, onSuccess: handleBind, onFailure: handleBind, responseType: "code", prompt: "consent", cookiePolicy: 'single_host_origin', scope: SCOPES, render: function (renderProps) { return (_jsx("div", { children: isIconButton ? (_jsx(Button, __assign({ variant: "contained", endIcon: _jsx(GoogleFitIcon, {}, void 0), onClick: renderProps.onClick }, { children: text || t('Подключить') }), void 0)) : (_jsx("p", __assign({ className: "tw-link-btn", onClick: renderProps.onClick }, { children: text || t('Подключить') }), void 0)) }, void 0)); } }, void 0));
};
export default GoogleFitConnect;
