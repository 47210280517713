var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClubUserJoinRequestsSearchResultListItem from '../ClubUserJoinRequestsSearchResultListItem/ClubUserJoinRequestsSearchResultListItem';
var ClubUserJoinRequestsSearchResultList = function (_a) {
    var userJoinRequests = _a.userJoinRequests, handleConfirmUserJoinRequest = _a.handleConfirmUserJoinRequest, handleDeclineUserJoinRequest = _a.handleDeclineUserJoinRequest;
    var t = useTranslation().t;
    return (_jsx(Box, __assign({ sx: {
            overflowY: 'scroll'
        }, className: "w-full " }, { children: userJoinRequests.length ? (_jsx("div", __assign({ className: "flex flex-col mx-0 w-full sm:max-w-md" }, { children: userJoinRequests.map(function (userJoinRequest, index) {
                return (_jsx(ClubUserJoinRequestsSearchResultListItem, { userProfile: userJoinRequest, handleConfirmUserJoinRequest: handleConfirmUserJoinRequest, handleDeclineUserJoinRequest: handleDeclineUserJoinRequest }, index));
            }) }), void 0)) : (_jsx("div", __assign({ className: "flex grow justify-center" }, { children: _jsx(Typography, __assign({ sx: { fontSize: '1.8rem', fontWeight: 400 } }, { children: t('Нет активных заявок') }), void 0) }), void 0)) }), void 0));
};
export default ClubUserJoinRequestsSearchResultList;
