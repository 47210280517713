var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { yellow } from '@mui/material/colors';
import { StandingsParentType } from 'common/enums/standings/standings-parent-type.enum';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import { useTranslation } from 'react-i18next';
var StandingsDetailsDialog = function (_a) {
    var standings = _a.standings;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 space-y-5" }, { children: [_jsxs("div", __assign({ className: "flex space-x-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: [t('Начало приёма тренировок'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: new Date(standings.activitySettings.activityStartDate).toLocaleString() }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex space-x-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: [t('Окончание приёма тренировок'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: new Date(standings.activitySettings.activityEndDate).getTime() > 4116145470000
                            ? '∞'
                            : new Date(standings.activitySettings.activityEndDate).toLocaleString() }), void 0)] }), void 0), standings.activitySettings.activityStartDateSinceParticipantJoined && (_jsx("div", __assign({ className: "flex dark:text-white" }, { children: _jsxs("p", { children: [t('Тренировки принимаются только с момента вступления пользователя в'), ' ', standings.parentType === StandingsParentType.SportClub
                            ? 'клуб' + '.'
                            : standings.parentType === StandingsParentType.Team
                                ? 'команду' + '.'
                                : 'родительскую сущность' + '.'] }, void 0) }), void 0)), standings.activitySettings.activityStartDateSinceUserJoinedParticipantEntity && (_jsx("div", __assign({ className: "flex dark:text-white" }, { children: _jsxs("p", { children: [t('Тренировки принимаются только с момента вступления пользователя в'), ' ', standings.participantType === StandingsParticipantType.SportClub
                            ? t('клуб') + '.'
                            : standings.participantType === StandingsParticipantType.Team
                                ? t('команду') + '.'
                                : t('сущность участника') + '.'] }, void 0) }), void 0)), standings.description && (_jsxs("div", __assign({ className: "flex space-x-0 flex-col space-y-5 pt-12" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: [t('Информация'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: _jsx("div", __assign({ className: "overflow-x-hidden max-h-100 break-words" }, { children: _jsx(ReadOnlyEditor, { value: standings.description }, void 0) }), void 0) }), void 0)] }), void 0)), standings.isDataFrozen && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 pt-12" }, { children: [_jsx(WarningAmberIcon, { sx: { color: yellow[600] } }, void 0), _jsx("p", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: t('standingsDataFixed') }), void 0)] }), void 0))] }), void 0));
};
export default StandingsDetailsDialog;
