var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { TEAM_USERS_SEARCH } from 'common/graphql/queries/team/team-users-search';
import Loading from 'components/Loading/Loading';
import { useCallback, useEffect, useState } from 'react';
import { teamUserSearchPageVar } from 'utils/apollo/reactivities/teamUserSearchPageVar';
import Utils from 'utils/Utils';
import TeamUsersSearchField from './TeamUsersSearchField';
import TeamUsersSearchResultList from './TeamUsersSearchResultList';
var TournamentTeamUsersDialog = function (_a) {
    var teamId = _a.teamId, teamUsersTotalCount = _a.teamUsersTotalCount, handleClose = _a.handleClose;
    var perPage = 10;
    var teamUserSearchFieldInput = useReactiveVar(teamUserSearchPageVar).teamUserSearchFieldInput;
    var _b = useState(1), page = _b[0], setPage = _b[1];
    var _c = useState([]), users = _c[0], setUsers = _c[1];
    var _d = useState(false), hasMore = _d[0], setHasMore = _d[1];
    var _e = useLazyQuery(TEAM_USERS_SEARCH), getTeamUsers = _e[0], _f = _e[1], data = _f.data, loading = _f.loading;
    var _g = useState(''), searchInput = _g[0], setSearchInput = _g[1];
    useEffect(function () {
        if (page) {
            getTeamUsers({
                variables: {
                    teamId: teamId,
                    searchQuery: Utils.prepareSearchInputValue(searchInput) || '',
                    limit: perPage,
                    offset: page > 1 ? perPage * (page - 1) : 0
                }
            });
        }
    }, [page, teamUserSearchFieldInput]);
    useEffect(function () {
        var delayDebounceFn = setTimeout(function () {
            setPage(1);
            setSearchInput(teamUserSearchFieldInput);
        }, 500);
        return function () { return clearTimeout(delayDebounceFn); };
    }, [teamUserSearchFieldInput]);
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.teamUsersSearch) === null || _a === void 0 ? void 0 : _a.length) {
            if (page === 1) {
                setUsers(__spreadArray([], data === null || data === void 0 ? void 0 : data.teamUsersSearch, true));
            }
            else {
                setUsers(function (prevUsers) { return __spreadArray(__spreadArray([], prevUsers, true), data === null || data === void 0 ? void 0 : data.teamUsersSearch, true); });
            }
            setHasMore((data === null || data === void 0 ? void 0 : data.teamUsersSearch) && (data === null || data === void 0 ? void 0 : data.teamUsersSearch.length) === perPage);
        }
        if (!((_b = data === null || data === void 0 ? void 0 : data.teamUsersSearch) === null || _b === void 0 ? void 0 : _b.length) && teamUserSearchFieldInput && page === 1) {
            setUsers([]);
        }
    }, [data]);
    var loadMore = useCallback(function () {
        setPage(function (page) { return page + 1; });
    }, []);
    if (page === 1 && loading)
        return _jsx(Loading, {}, void 0);
    return (_jsxs("div", __assign({ className: "h-full max-h-full flex-nowrap" }, { children: [_jsx("div", { children: _jsx(TeamUsersSearchField, {}, void 0) }, void 0), _jsx("div", __assign({ className: "flex xs:pb-16 md:pb-24" }, { children: _jsx(TeamUsersSearchResultList, { teamUsers: users, hasMore: hasMore, isLoading: loading, loadMore: loadMore, handleClose: handleClose }, void 0) }), void 0)] }), void 0));
};
export default TournamentTeamUsersDialog;
