var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { USER_ADDRESSES } from 'common/graphql/queries/user/address/user-addresses';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import UserAddressListItem from './UserAddressListItem';
var UserAddresses = function () {
    var _a = useQuery(USER_ADDRESSES), data = _a.data, loading = _a.loading;
    var addresses = _.orderBy(data === null || data === void 0 ? void 0 : data.userAddresses, 'isCurrent', 'desc');
    if (loading)
        return _jsx(Loading, {}, void 0);
    return addresses && (addresses === null || addresses === void 0 ? void 0 : addresses.length) > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-20" }, { children: addresses.map(function (address) {
            return _jsx(UserAddressListItem, { userAddress: address }, address.id);
        }) }), void 0)) : null;
};
export default UserAddresses;
