var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TelegramContact from 'assets/icons/contacts/telegram';
import VKContact from 'assets/icons/contacts/VK';
import WhatsAppContact from 'assets/icons/contacts/whatsapp';
import clsx from 'clsx';
import { TeamContactTypes } from 'common/enums/team/team-contact-types.enum';
import { TEAM } from 'common/graphql/queries/team/team';
import { TEAM_CONTACTS } from 'common/graphql/queries/team/team-contacts';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import JoinTeamButton from '../../JoinTeamButton';
import TeamAdditionalMenuButton from '../TeamAdditionalMenuButton';
import TeamUsersAvatarGroup from './TeamUsersAvatarGroup';
var TeamContactItem = function (_a) {
    var teamContact = _a.teamContact;
    return (_jsxs("div", __assign({ className: clsx('flex items-center xs:px-8 py-4') }, { children: [teamContact.type === TeamContactTypes.Telegram && _jsx(TelegramContact, { className: "dark:fill-white" }, void 0), teamContact.type === TeamContactTypes.VK && _jsx(VKContact, { className: "dark:fill-white" }, void 0), teamContact.type === TeamContactTypes.Whatsapp && _jsx(WhatsAppContact, { className: "dark:fill-white" }, void 0), _jsx(Link, __assign({ className: "tw-club-contact-link", to: { pathname: teamContact.url }, target: "_blank" }, { children: teamContact.text }), void 0)] }), void 0));
};
var TeamProfilePageHeader = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    var teamPublicData = _a.teamPublicData, isUserTeamParticipant = _a.isUserTeamParticipant;
    var t = useTranslation().t;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id;
    var isAdmin = !!((_c = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _b === void 0 ? void 0 : _b.map(function (role) { return role.name; })) === null || _c === void 0 ? void 0 : _c.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    var canShowTeam = ((!((_f = (_e = (_d = teamPublicData.tournaments) === null || _d === void 0 ? void 0 : _d[0].sportEvent) === null || _e === void 0 ? void 0 : _e.privacy) === null || _f === void 0 ? void 0 : _f.isOpenEvent) && !isUserTeamParticipant) ||
        (((_j = (_h = (_g = teamPublicData.tournaments) === null || _g === void 0 ? void 0 : _g[0].sportEvent) === null || _h === void 0 ? void 0 : _h.privacy) === null || _j === void 0 ? void 0 : _j.isPrivate) && !isUserTeamParticipant)) &&
        !isAdmin
        ? false
        : true;
    var _5 = useLazyQuery(TEAM), getTeam = _5[0], teamData = _5[1].data;
    var _6 = useLazyQuery(TEAM_CONTACTS), getTeamContacts = _6[0], teamContactsData = _6[1].data;
    useEffect(function () {
        if (canShowTeam) {
            getTeam({
                variables: {
                    id: teamPublicData.id,
                    showBasicFields: true,
                    showTournaments: true,
                    showParticipationSettings: true
                }
            });
            getTeamContacts({
                variables: {
                    teamId: teamPublicData.id
                }
            });
        }
    }, [canShowTeam]);
    var team = teamData === null || teamData === void 0 ? void 0 : teamData.team;
    var teamContacts = teamContactsData === null || teamContactsData === void 0 ? void 0 : teamContactsData.teamContacts;
    var isSportEventRegistrationOpen = ((_o = (_m = (_l = (_k = team === null || team === void 0 ? void 0 : team.tournaments) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.sportEvent) === null || _m === void 0 ? void 0 : _m.datesSettings) === null || _o === void 0 ? void 0 : _o.registrationStartDate) &&
        ((_s = (_r = (_q = (_p = team === null || team === void 0 ? void 0 : team.tournaments) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.sportEvent) === null || _r === void 0 ? void 0 : _r.datesSettings) === null || _s === void 0 ? void 0 : _s.registrationEndDate)
        ? new Date() > new Date((_w = (_v = (_u = (_t = team === null || team === void 0 ? void 0 : team.tournaments) === null || _t === void 0 ? void 0 : _t[0]) === null || _u === void 0 ? void 0 : _u.sportEvent) === null || _v === void 0 ? void 0 : _v.datesSettings) === null || _w === void 0 ? void 0 : _w.registrationStartDate) &&
            new Date() < new Date((_0 = (_z = (_y = (_x = team === null || team === void 0 ? void 0 : team.tournaments) === null || _x === void 0 ? void 0 : _x[0]) === null || _y === void 0 ? void 0 : _y.sportEvent) === null || _z === void 0 ? void 0 : _z.datesSettings) === null || _0 === void 0 ? void 0 : _0.registrationEndDate)
        : false;
    return (_jsxs("div", __assign({ className: "club-profile-card flex justify-between" }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(UserAvatar, { style: "\n                xs:w-80 md:w-128 xl:w-136 \n                xs:h-80 md:h-128 xl:h-136 text-22", firstName: teamPublicData.name, profilePhoto: teamPublicData.profilePhoto || undefined }, void 0), _jsxs("div", __assign({ className: "flex flex-col xs:px-12 md:px-16 xs:max-w-400 sm:max-w-840" }, { children: [_jsxs("div", __assign({ className: clsx('flex xs:flex-col sm:flex-row sm:space-x-10 xs:space-x-0 sm:items-center') }, { children: [_jsx("p", __assign({ className: "xs:text-18 md:text-20 font-semibold [overflow-wrap:anywhere]" }, { children: teamPublicData.displayName || teamPublicData.name }), void 0), _jsx("div", __assign({ className: "flex h-full text-gray xs:text-12 md:text-14 items-center" }, { children: team && team.areYouParticipant ? t('Ваша команда') : t('Команда') }), void 0), team && team.areYouCaptain && (_jsx("div", __assign({ className: "flex h-full text-green xs:text-12 md:text-14 py-2 items-end" }, { children: t('Вы капитан команды') }), void 0)), currentUserId &&
                                        teamPublicData &&
                                        (teamPublicData.participationSettings.isPrivate === false ||
                                            (teamPublicData.participationSettings.isPrivate === true &&
                                                teamPublicData.participationSettings.joinViaCode)) &&
                                        !isUserTeamParticipant &&
                                        (isSportEventRegistrationOpen ||
                                            (teamPublicData.participationSettings.isPrivate === true &&
                                                teamPublicData.participationSettings.joinViaCode)) && (_jsx("div", __assign({ className: "flex items-center sm:pl-10 xs:pt-10 sm:pt-0" }, { children: _jsx(JoinTeamButton, { userId: currentUserId, team: teamPublicData }, void 0) }), void 0))] }), void 0), _jsxs("div", __assign({ className: "flex flex-col text-14 dark:text-gray-300 text-gray-700 pt-12 space-y-5" }, { children: [_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('Турнир'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 cursor-pointer", onClick: function () {
                                                    var _a, _b, _c, _d, _e;
                                                    return history.push(((_b = (_a = teamPublicData.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.sportEvent)
                                                        ? "/event/".concat((_e = (_d = (_c = teamPublicData.tournaments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.sportEvent) === null || _e === void 0 ? void 0 : _e.url)
                                                        : "/events");
                                                } }, { children: ((_4 = (_3 = (_2 = (_1 = teamPublicData.tournaments) === null || _1 === void 0 ? void 0 : _1[0]) === null || _2 === void 0 ? void 0 : _2.sportEvent) === null || _3 === void 0 ? void 0 : _3.mainSettings) === null || _4 === void 0 ? void 0 : _4.displayName) || '' }), void 0)] }), void 0), (team === null || team === void 0 ? void 0 : team.id) && (team === null || team === void 0 ? void 0 : team.usersCount) !== undefined && team.usersCount > 0 && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('Участники'), ":\u00A0"] }), void 0), _jsx(TeamUsersAvatarGroup, { teamId: team.id, teamUsersTotalCount: team.usersCount }, void 0)] }), void 0)), teamContacts && teamContacts.length > 0 && (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-start" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray py-4" }, { children: [t('Контакты'), ":\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex flex-col space-y-5" }, { children: teamContacts.map(function (teamContact) {
                                                    return _jsx(TeamContactItem, { teamContact: teamContact }, teamContact.id);
                                                }) }), void 0)] }), void 0)), team && team.description && team.description !== '<p></p>' && (_jsxs("div", __assign({ className: "flex xs:flex-col sm:flex-row xs:space-y-5 sm:space-y-0" }, { children: [_jsxs("div", __assign({ className: "flex items-start min-w-96 xs:text-12 sm:text-14 text-gray" }, { children: [t('О команде'), ":"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840" }, { children: _jsx(ReadOnlyEditor, { value: team.description }, void 0) }), void 0)] }), void 0))] }), void 0), !canShowTeam && (_jsxs("div", __assign({ className: "flex items-end pt-20" }, { children: [_jsx(LockOutlinedIcon, { className: "h-20 w-20" }, void 0), _jsx("div", __assign({ className: "px-4" }, { children: t('Команда приватная или находится в приватном или закрытом событии') }), void 0)] }), void 0))] }), void 0)] }), void 0), (team === null || team === void 0 ? void 0 : team.id) && _jsx("div", { children: isAdmin && _jsx(TeamAdditionalMenuButton, { teamId: team.id }, void 0) }, void 0)] }), void 0));
};
export default TeamProfilePageHeader;
