var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from 'components/Buttons/Button';
var DeclineSportClubUserJoinRequestButton = function (_a) {
    var userId = _a.userId, handleDeclineRequest = _a.handleDeclineRequest;
    return (_jsx(Button, __assign({ onClick: function () {
            handleDeclineRequest(userId);
        }, type: "red" }, { children: "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C" }), void 0));
};
export default DeclineSportClubUserJoinRequestButton;
