var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
import TournamentFeautreListItem from './TournamentFeatureListItem';
var TournamentFeaturesSettingsPage = function (_a) {
    var _b;
    var tournamentId = _a.tournamentId;
    var _c = useQuery(TOURNAMENT, {
        variables: {
            id: tournamentId,
            showMainSettings: true,
            showFeatures: true
        }
    }), data = _c.data, loading = _c.loading;
    var tournamentFeatures = ((_b = data === null || data === void 0 ? void 0 : data.tournament) === null || _b === void 0 ? void 0 : _b.features) || [];
    var dispatch = useDispatch();
    var openCreateTournamentFeatureDialog = function () {
        var _a, _b;
        dispatch(openDialog({
            dialogType: DialogTypes.CreateTournamentFeature,
            dialogProps: {
                tournamentId: tournamentId,
                tournamentName: (_b = (_a = data === null || data === void 0 ? void 0 : data.tournament) === null || _a === void 0 ? void 0 : _a.mainSettings) === null || _b === void 0 ? void 0 : _b.displayName
            }
        }));
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u044B\u0445 \u0444\u0438\u0447" }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [_jsx("div", __assign({ className: "flex flex-col max-w-320" }, { children: _jsx("div", __assign({ className: "max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: openCreateTournamentFeatureDialog }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "pt-20" }, { children: tournamentFeatures && (tournamentFeatures === null || tournamentFeatures === void 0 ? void 0 : tournamentFeatures.length) > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: tournamentFeatures.map(function (tournamentFeature) {
                            return (_jsx(TournamentFeautreListItem, { tournamentId: tournamentId, feature: tournamentFeature }, tournamentFeature.id));
                        }) }), void 0)) }), void 0)] }), void 0), innerScroll: false }, void 0));
};
export default TournamentFeaturesSettingsPage;
