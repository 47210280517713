import { Sports } from 'common/enums/sport/sports.enum';
import * as Yup from 'yup';
var createActivityValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(1, 'Слишком короткое название. Как минимум один символ')
        .max(256, 'Слишком длинное название. Пожалуйста, введите от 1 до 256 символов')
        .required('Не указано название'),
    description: Yup.string()
        .trim()
        .min(0)
        .max(256, 'Слишком длинное описание. Пожалуйста, введите от 0 до 512 символов')
        .nullable(),
    distance: Yup.number()
        .typeError('Дистанция должна быть числом')
        .min(10, 'Слишком маленькая дистанция')
        .max(1000000, 'Слишком большая дистанция')
        .required('Не указана дистанция. Если не требуется - укажите 0'),
    startDate: Yup.date()
        .typeError('Укажите дату начала тренировки')
        .max(new Date())
        .required('Не указана дата начала тренировки'),
    elapsedTime: Yup.number()
        .typeError('Длительность должна быть числом')
        .min(10, 'Слишком маленькая длительность тренировки')
        .max(1000000, 'Слишком большая длительность тренировки')
        .required('Не указана длительность тренировки'),
    averageCadence: Yup.number()
        .typeError('Каденс должен быть числом')
        .min(0)
        .max(200, 'Слишком большой каденс')
        .nullable(),
    maxSpeed: Yup.number()
        .typeError('Максимальная скорость должна быть числом')
        .min(0)
        .max(30, 'Слишком большая скорость. Максимум 30 м/с')
        .nullable(),
    avgHeartRate: Yup.number()
        .typeError('ЧСС должна быть числом')
        .min(0)
        .max(200, 'Слишком большой ЧСС. Укажите до 200 ударов в минуту')
        .nullable(),
    elevation: Yup.number()
        .typeError('Набор высоты должен быть числом')
        .min(0)
        .max(3000, 'Слишком большой набор высоты, максимум 3000 метров')
        .nullable(),
    sport: Yup.mixed().oneOf(Object.values(Sports)).required('Укажите вид спорта')
});
export default createActivityValidationSchema;
