var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { FeatureTypes } from 'common/enums/feature/feature-types.enum';
import { TEAM } from 'common/graphql/queries/team/team';
import { TOURNAMENT_FEATURES } from 'common/graphql/queries/tournament/tournament-features';
import { TOURNAMENT_TEAMS } from 'common/graphql/queries/tournament/tournament-teams';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
var TeamFeatureSettings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var teamId = _a.teamId;
    var _k = useQuery(TEAM, {
        variables: {
            id: teamId,
            showTournaments: true
        }
    }), data = _k.data, loading = _k.loading;
    var _l = useLazyQuery(TOURNAMENT_TEAMS), getTournamentTeams = _l[0], _m = _l[1], tournamentTeamsData = _m.data, tournamentTeamsLoading = _m.loading, tournamentTeamsError = _m.error;
    var _o = useLazyQuery(TOURNAMENT_FEATURES), getTournamentFeatures = _o[0], _p = _o[1], tournamentFeaturesData = _p.data, tournamentFeaturesLoading = _p.loading, tournamentFeaturesError = _p.error;
    var tournament = (_c = (_b = data === null || data === void 0 ? void 0 : data.team) === null || _b === void 0 ? void 0 : _b.tournaments) === null || _c === void 0 ? void 0 : _c[0];
    useEffect(function () {
        if (tournament && tournament.id) {
            getTournamentTeams({
                variables: {
                    teamId: teamId,
                    tournamentId: tournament.id
                }
            });
            getTournamentFeatures({
                variables: {
                    tournamentId: tournament.id
                }
            });
        }
    }, [data]);
    var tournamentTeam = (_d = tournamentTeamsData === null || tournamentTeamsData === void 0 ? void 0 : tournamentTeamsData.tournamentTeams) === null || _d === void 0 ? void 0 : _d[0];
    var featureConfigs = tournamentTeam === null || tournamentTeam === void 0 ? void 0 : tournamentTeam.featureConfigs;
    var getFeatureName = function (featureType) {
        switch (featureType) {
            case FeatureTypes.elimination: {
                return 'Выбываение';
            }
            case FeatureTypes.finishingConditions: {
                return 'Условия выбывания';
            }
            case FeatureTypes.goal: {
                return 'Цели';
            }
            case FeatureTypes.virtualPoints: {
                return 'Виртуальные очки';
            }
            default: {
                return featureType;
            }
        }
    };
    var dispatch = useDispatch();
    var onChangeButtonClick = function (featureType) {
        dispatch(openDialog({
            dialogType: DialogTypes.TeamTournamentFeatureConfiguration,
            dialogProps: {
                tournamentTeam: tournamentTeam,
                featureType: featureType
            }
        }));
    };
    if (loading || tournamentTeamsLoading || tournamentFeaturesLoading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430\u043C\u0438 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u044B\u0445 \u0444\u0438\u0447 \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [!((_e = tournamentFeaturesData === null || tournamentFeaturesData === void 0 ? void 0 : tournamentFeaturesData.tournamentFeatures) === null || _e === void 0 ? void 0 : _e.length) && !((_f = featureConfigs === null || featureConfigs === void 0 ? void 0 : featureConfigs.configs) === null || _f === void 0 ? void 0 : _f.length) && (_jsx("p", { children: "\u041A \u0442\u0443\u0440\u043D\u0438\u0440\u0443 \u043D\u0435 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u044B \u0444\u0438\u0447\u0438" }, void 0)), (tournamentFeaturesData === null || tournamentFeaturesData === void 0 ? void 0 : tournamentFeaturesData.tournamentFeatures) &&
                    ((_g = tournamentFeaturesData === null || tournamentFeaturesData === void 0 ? void 0 : tournamentFeaturesData.tournamentFeatures) === null || _g === void 0 ? void 0 : _g.length) > 0 &&
                    !((_h = featureConfigs === null || featureConfigs === void 0 ? void 0 : featureConfigs.configs) === null || _h === void 0 ? void 0 : _h.length) && (_jsx("div", { children: _jsx(Button, __assign({ type: "button", 
                        // пока что есть настройки только для фичи "Виртуальные очки" на уровне команды
                        onClick: function () { return onChangeButtonClick(FeatureTypes.virtualPoints); } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0) }, void 0)), featureConfigs && ((_j = featureConfigs.configs) === null || _j === void 0 ? void 0 : _j.length) > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: featureConfigs.configs.map(function (featureConfig) {
                        return (_jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center" }, { children: [_jsx("p", { children: getFeatureName(featureConfig.featureType) }, void 0), _jsx(Button, __assign({ type: "button", onClick: function () { return onChangeButtonClick(featureConfig.featureType); } }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" }), void 0)] }), featureConfig.featureType));
                    }) }), void 0))] }), void 0), innerScroll: false }, void 0));
};
export default TeamFeatureSettings;
