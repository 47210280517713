var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import UserTournamentsAndRewardsDialogListItem from './UserTournamentsAndRewardsDialogListItem';
var UserTournamentsAndRewardsDialog = function (_a) {
    var tournamentNominationWinners = _a.tournamentNominationWinners, userGender = _a.userGender, handleClose = _a.handleClose;
    return (tournamentNominationWinners === null || tournamentNominationWinners === void 0 ? void 0 : tournamentNominationWinners.length) > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-20" }, { children: tournamentNominationWinners.map(function (tournamentNominationWinner) {
            return (_jsx(UserTournamentsAndRewardsDialogListItem, { tournamentNominationWinner: tournamentNominationWinner, userGender: userGender, handleClose: handleClose }, tournamentNominationWinner.id));
        }) }), void 0)) : null;
};
export default UserTournamentsAndRewardsDialog;
