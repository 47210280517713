var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useReactiveVar } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { HELPER } from 'common/graphql/queries/helper/helper';
import GoogleFitConnect from 'main/pages/SettingsPage/connections/bindSocialNetwork/GoogleFitConnect';
import PolarBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/PolarBind';
import StravaBind from 'main/pages/SettingsPage/connections/bindSocialNetwork/StravaBind';
import { useTranslation } from 'react-i18next';
import { navigateToVar } from 'utils/apollo/reactivities/navigateToVar';
var AddTraningSourceDialog = function (_a) {
    var _b;
    var handleClose = _a.handleClose;
    var t = useTranslation().t;
    var helper = (_b = useQuery(HELPER, {
        variables: {
            id: 'd687fa7a-2725-41d7-8be2-b390b4cc71c1'
        }
    }).data) === null || _b === void 0 ? void 0 : _b.helper;
    var navigateTo = useReactiveVar(navigateToVar).navigateTo;
    return (_jsxs("div", __assign({ className: "dark:text-white min-w-300 max-w-320" }, { children: [_jsx(DialogContent, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0'
                } }, { children: _jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Подключите источник данных ваших тренировок') }), void 0) }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-content flex-col items-center dark:text-white" }, { children: [helper && (_jsx("div", __assign({ onClick: function () {
                            if (helper === null || helper === void 0 ? void 0 : helper.link) {
                                window.open(helper === null || helper === void 0 ? void 0 : helper.link, '_blank');
                            }
                        } }, { children: _jsx(Button, __assign({ sx: { fontSize: '1.4rem', fontWeight: 600, color: '#818181' }, variant: "text", color: "secondary" }, { children: _jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx("p", { children: t('Узнать подробнее') }, void 0), _jsx(OpenInNewIcon, {}, void 0)] }), void 0) }), void 0) }), void 0)), _jsxs("div", __assign({ className: "flex flex-col space-y-10 pb-40" }, { children: [_jsx("div", __assign({ className: "pb-8" }, { children: _jsx(StravaBind, {}, void 0) }), void 0), _jsx("div", __assign({ className: "w-full pb-8" }, { children: _jsx(PolarBind, { isButton: true, text: t('Подключить Polar') }, void 0) }), void 0), _jsx(GoogleFitConnect, { isIconButton: true, text: t('Подключить Google Fit') }, void 0)] }), void 0), _jsx(Button, __assign({ sx: { fontSize: '1.2rem', fontWeight: 200, color: '#818181' }, onClick: function () {
                            handleClose();
                            if (navigateTo) {
                                window.location.href = navigateTo;
                            }
                        }, variant: "text", color: "secondary" }, { children: t('Продолжить без подключения') }), void 0)] }), void 0)] }), void 0));
};
export default AddTraningSourceDialog;
