var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { appHost, appScheme } from 'common/appURLSetup';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { telegramBotId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var TelegramBind = function () {
    var t = useTranslation().t;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var userId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onTelegramBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onTelegramBindSuccess = function () {
        dispatch(openSnackbar({
            message: 'Подключение аккаунта выполнено.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var _a = useDynamicMutation(useUpdateUserMutation, onTelegramBindSuccess, onTelegramBindError), handleExecuteMutation = _a.handleExecuteMutation, loading = _a.loading;
    useEffect(function () {
        var queryObj = queryString.parse(location.hash);
        if (queryObj && queryObj['#tgAuthResult'] && userId) {
            var telegramUserData = JSON.parse(atob(queryObj['#tgAuthResult']));
            if (telegramUserData === null || telegramUserData === void 0 ? void 0 : telegramUserData.id) {
                handleExecuteMutation({
                    id: userId,
                    contact: {
                        telegram: __assign({}, telegramUserData)
                    }
                });
            }
        }
    }, [location.search, history]);
    var handleRedirect = function () {
        var origin = "".concat(appScheme, "://").concat(appHost);
        var redirectTo = "".concat(appScheme, "://").concat(appHost, "/settings/contacts/telegram");
        window.location.href = "https://oauth.telegram.org/auth?bot_id=".concat(telegramBotId, "&origin=").concat(origin, "&request_access=write&return_to=").concat(redirectTo);
    };
    return (_jsx("div", __assign({ className: "tw-link-btn p-4", onClick: handleRedirect }, { children: t('Подключить') }), void 0));
};
export default TelegramBind;
