var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, Typography } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { DELETE_SPORT_CLUB_USER } from 'common/graphql/mutations/sportClub/deleteSportClubUser';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import { SPORT_CLUBS } from 'common/graphql/queries/sportClub/sportClubs';
import { USER } from 'common/graphql/queries/user/user';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Button from 'components/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var LeaveClubRequestDialog = function (_a) {
    var sportClubId = _a.sportClubId, handleClose = _a.handleClose;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0412\u044B \u0431\u043E\u043B\u044C\u0448\u0435 \u043D\u0435 \u044F\u0432\u043B\u044F\u0435\u0442\u0435\u0441\u044C \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u043C \u043A\u043B\u0443\u0431\u0430."),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: "\u0412\u043E\u0437\u043D\u0438\u043A\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430: ".concat(code),
            severity: SnackbarSeverity.ERROR
        }));
        handleClose();
    };
    var handleExecuteMutation = useDynamicMutation(function (sportClubId) {
        var refetchQueries = [
            {
                query: SPORT_CLUB,
                variables: {
                    id: sportClubId
                }
            },
            {
                query: USER,
                variables: {
                    showSportClub: true,
                    showSportClubPermissions: true
                }
            },
            {
                query: SPORT_CLUBS
            },
        ];
        return useMutation(DELETE_SPORT_CLUB_USER, {
            variables: {
                deleteSportClubUserData: {
                    sportClubId: sportClubId
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: refetchQueries
        });
    }, onSuccess, onError).handleExecuteMutation;
    var handleLeave = function () {
        handleExecuteMutation(sportClubId);
    };
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Покинуть клуб') }), void 0), _jsx(Typography, __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: t('Вы действительно хотите покинуть клуб?') }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ onClick: function () {
                            handleLeave();
                        }, type: "primary sm" }, { children: t('Покинуть') }), void 0)] }), void 0)] }), void 0));
};
export default LeaveClubRequestDialog;
