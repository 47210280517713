var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { USER } from 'common/graphql/queries/user/user';
import Loading from 'components/Loading/Loading';
import LanguageSelect from 'components/Select/LanguageSelect';
import { useTranslation } from 'react-i18next';
var ChangeUserLanguageDialog = function (_a) {
    var userId = _a.userId;
    var t = useTranslation().t;
    var _b = useQuery(USER), data = _b.data, loading = _b.loading;
    if (loading)
        return _jsx(Loading, {}, void 0);
    return data ? (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Выбрать язык') }), void 0), _jsx(LanguageSelect, { userId: userId, currentLang: data.user.lang }, void 0)] }), void 0)) : null;
};
export default ChangeUserLanguageDialog;
