export var BasicTableColumnType;
(function (BasicTableColumnType) {
    //name.toLowerCase() !!!!!!
    BasicTableColumnType["name"] = "name";
    BasicTableColumnType["distance"] = "distance";
    BasicTableColumnType["tempo"] = "tempo";
    BasicTableColumnType["positiveElevation"] = "positiveelevation";
    BasicTableColumnType["gender"] = "gender";
    BasicTableColumnType["sportType"] = "sport";
    BasicTableColumnType["startDate"] = "startdate";
    BasicTableColumnType["startDateLocal"] = "startdatelocal";
    BasicTableColumnType["movingTime"] = "movingtime";
    BasicTableColumnType["elapsedTime"] = "elapsedtime";
    BasicTableColumnType["validationStatus"] = "validationstatus";
    BasicTableColumnType["validationsStatus"] = "validationsstatus";
    BasicTableColumnType["validation"] = "validation";
    BasicTableColumnType["source"] = "source";
    BasicTableColumnType["userName"] = "userdisplayname";
    BasicTableColumnType["userNameLink"] = "usernamelink";
    BasicTableColumnType["activitiesNumber"] = "activitiesnumber";
    BasicTableColumnType["ageGroup"] = "agegroup";
    BasicTableColumnType["points"] = "points";
    BasicTableColumnType["pointsWithBoosterApplied"] = "pointswithboosterapplied";
    BasicTableColumnType["coins"] = "coins";
    BasicTableColumnType["activeBoosters"] = "activeboosters";
    BasicTableColumnType["sportClubUserStatus"] = "sportclubuserstatus";
    BasicTableColumnType["averageKmPerUser"] = "averagekmperuser";
    BasicTableColumnType["activeness"] = "activeness";
    BasicTableColumnType["participantsNumber"] = "participantsnumber";
    BasicTableColumnType["teamDisplayName"] = "teamdisplayname";
    BasicTableColumnType["userTeam"] = "userteam";
    BasicTableColumnType["sportClubDisplayName"] = "sportclubdisplayname";
    BasicTableColumnType["IsEliminated"] = "iseliminated";
    BasicTableColumnType["IsGoalReached"] = "isgoalreached";
    BasicTableColumnType["FinishingConditionStatus"] = "finishingconditionstatus";
    BasicTableColumnType["fullyDownloaded"] = "fullydownloaded";
})(BasicTableColumnType || (BasicTableColumnType = {}));
