var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
var CreateActivityManualDurationNumberInput = function (_a) {
    var handleChange = _a.handleChange;
    var t = useTranslation().t;
    var _b = useState(0), seconds = _b[0], setSeconds = _b[1];
    var _c = useState(0), minutes = _c[0], setMinutes = _c[1];
    var _d = useState(0), hours = _d[0], setHours = _d[1];
    useEffect(function () {
        handleChange('elapsedTime', seconds + minutes * 60 + hours * 3600);
        if (seconds > 60)
            setSeconds(60);
        if (seconds < 0)
            setSeconds(0);
        if (minutes > 60)
            setMinutes(60);
        if (minutes < 0)
            setMinutes(0);
        if (hours > 672)
            setHours(672);
        if (hours < 0)
            setHours(0);
    }, [seconds, minutes, hours]);
    return (_jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsx(TextField, { label: t('часы'), type: "number", value: hours, onChange: function (e) { return setHours(parseInt(e.target.value)); }, error: isNaN(hours), helperText: isNaN(hours) && t('Значение должно быть числом') }, void 0), _jsx(TextField, { label: t('минуты'), value: minutes, type: "number", onChange: function (e) { return setMinutes(parseInt(e.target.value)); }, error: isNaN(minutes) || minutes > 60, helperText: (isNaN(minutes) && t('Значение должно быть числом')) ||
                    (minutes > 60 && t('Значение должно быть меньше или равно') + ' 60') }, void 0), _jsx(TextField, { label: t('секунды'), value: seconds, type: "number", onChange: function (e) { return setSeconds(parseInt(e.target.value)); }, error: isNaN(seconds) || seconds > 60, helperText: (isNaN(seconds) && t('Значение должно быть числом')) ||
                    (seconds > 60 && t('Значение должно быть меньше или равно') + ' 60') }, void 0)] }), void 0));
};
export default CreateActivityManualDurationNumberInput;
