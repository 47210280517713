import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TEAM } from 'common/graphql/queries/team/team';
import { TEAM_USERS } from 'common/graphql/queries/team/team-users';
import Loading from 'components/Loading/Loading';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var JoinTeamByLink = function (_a) {
    var _b;
    var joinLinkString = _a.joinLinkString, teamId = _a.teamId;
    var currentUserId = (_b = useReactiveVar(userProfileDataVar).userProfile) === null || _b === void 0 ? void 0 : _b.id;
    var _c = useLazyQuery(TEAM), getTeamData = _c[0], _d = _c[1], teamData = _d.data, teamDataLoading = _d.loading, teamDataError = _d.error;
    var _e = useLazyQuery(TEAM_USERS), getTeamUserData = _e[0], _f = _e[1], teamUserData = _f.data, teamUserDataLoading = _f.loading, teamUserDataError = _f.error;
    var dispatch = useDispatch();
    var teamNotFound = function () {
        history.push('/events');
        dispatch(openSnackbar({
            message: 'Ошибка. Вероятно команда не найдена. Обратитесь к администратору.',
            severity: SnackbarSeverity.ERROR
        }));
    };
    var teamJoinDialog = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        history.push("/team/".concat((_a = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _a === void 0 ? void 0 : _a.name));
        dispatch(openDialog({
            dialogType: DialogTypes.JoinTeamRequestDialog,
            dialogProps: {
                teamId: teamId,
                joinLinkString: joinLinkString,
                teamName: (_b = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _b === void 0 ? void 0 : _b.name,
                teamDisplayName: (_c = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _c === void 0 ? void 0 : _c.displayName,
                sportEventId: (_g = (_f = (_e = (_d = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _d === void 0 ? void 0 : _d.tournaments) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.sportEvent) === null || _g === void 0 ? void 0 : _g.id,
                tournamentId: (_k = (_j = (_h = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _h === void 0 ? void 0 : _h.tournaments) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.id,
                sportEventDisplayName: (_q = (_p = (_o = (_m = (_l = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _l === void 0 ? void 0 : _l.tournaments) === null || _m === void 0 ? void 0 : _m[0]) === null || _o === void 0 ? void 0 : _o.sportEvent) === null || _p === void 0 ? void 0 : _p.mainSettings) === null || _q === void 0 ? void 0 : _q.displayName,
                teamParticipationSettings: (_r = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _r === void 0 ? void 0 : _r.participationSettings
            }
        }));
    };
    useEffect(function () {
        if (teamId && currentUserId) {
            getTeamData({
                variables: {
                    id: teamId,
                    showParticipationSettings: true,
                    showBasicFields: true,
                    showTournaments: true
                }
            });
        }
    }, [currentUserId]);
    useEffect(function () {
        var _a;
        if (teamData && ((_a = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _a === void 0 ? void 0 : _a.name)) {
            getTeamUserData({
                variables: {
                    teamId: teamId,
                    userId: currentUserId
                }
            });
        }
    }, [teamData]);
    useEffect(function () {
        var _a, _b, _c;
        if ((teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) && ((_a = teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            history.push("/team/".concat((_b = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _b === void 0 ? void 0 : _b.name));
            dispatch(openSnackbar({
                message: 'Вы уже являетесь членом этой команды.',
                severity: SnackbarSeverity.INFO
            }));
        }
        if ((teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) && ((_c = teamUserData === null || teamUserData === void 0 ? void 0 : teamUserData.teamUsers) === null || _c === void 0 ? void 0 : _c.length) === 0) {
            teamJoinDialog();
        }
    }, [teamUserData]);
    useEffect(function () {
        if (teamUserDataError || teamDataError) {
            teamNotFound();
        }
    }, [teamUserDataError, teamDataError]);
    if (teamDataLoading || teamUserDataLoading)
        return _jsx(Loading, {}, void 0);
    return _jsx(_Fragment, {}, void 0);
};
export default JoinTeamByLink;
