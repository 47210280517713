var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, TextField } from '@mui/material';
import InstagramContact from 'assets/icons/contacts/Instagram';
import TelegramContact from 'assets/icons/contacts/telegram';
import VKContact from 'assets/icons/contacts/VK';
import clsx from 'clsx';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SocialLinkTypes } from 'common/enums/sport-club/social-link-types.enum';
import { UPDATE_SPORT_CLUB_SOCIAL_LINK } from 'common/graphql/mutations/sportClub/updateSportClubSocialLink';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import contactValidationSchema from 'common/validationSchemas/contactValidationSchema copy';
import Button from 'components/Buttons/Button';
import ClubContactTypeField from 'components/Fields/ClubContactTypeField';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openDialog, openSnackbar } from 'store/actions';
var SportClubContact = function (_a) {
    var socialLink = _a.socialLink, sportClubId = _a.sportClubId;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    var updateSportClubSocialLink = function (data) {
        return useMutation(UPDATE_SPORT_CLUB_SOCIAL_LINK, {
            variables: {
                updateSportClubSocialLinkData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB,
                    variables: {
                        id: sportClubId
                    }
                },
            ]
        });
    };
    var openDeleteSportClubSocialLinkDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.DeleteSportClubSocialLink,
            dialogProps: {
                sportClubId: sportClubId,
                sportClubSocialLinkId: socialLink.id
            }
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
        setEdit(false);
    };
    var handleExecuteMutation = useDynamicMutation(updateSportClubSocialLink, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign({}, data));
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var defaultFormState = {
        id: socialLink.id,
        type: socialLink.type || SocialLinkTypes.Telegram,
        text: socialLink.text || '',
        url: socialLink.url || ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: contactValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: function () { return setEdit(false); },
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("div", __assign({ className: clsx('flex max-w-full w-full flex-col') }, { children: [_jsxs("div", __assign({ className: clsx('flex w-full justify-between py-14') }, { children: [_jsxs("div", __assign({ className: clsx('flex xs:items-start md:items-center xs:flex-col md:flex-row overflow-hidden') }, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [socialLink.type === SocialLinkTypes.Telegram && (_jsx(TelegramContact, { className: "dark:fill-white" }, void 0)), socialLink.type === SocialLinkTypes.VK && _jsx(VKContact, { className: "dark:fill-white" }, void 0), socialLink.type === SocialLinkTypes.Instagram && (_jsx(InstagramContact, { className: "dark:fill-white" }, void 0)), _jsx(Link, __assign({ className: "tw-club-contact-link font-semibold text-14 break-words", to: { pathname: socialLink.url }, target: "_blank" }, { children: socialLink.text }), void 0)] }), void 0), _jsxs("div", __assign({ className: "xs:pl-28 md:pl-4 break-words" }, { children: ["url: ", socialLink.url] }), void 0)] }), socialLink.id), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx(EditIcon, { className: "mr-12 cursor-pointer hover:fill-icon-lt-active", onClick: function () { return setEdit(!edit); } }, void 0), _jsx(DeleteIcon, { className: "cursor-pointer hover:fill-icon-lt-active", onClick: function () {
                                    openDeleteSportClubSocialLinkDialog();
                                } }, void 0)] }), void 0)] }), socialLink.id), edit && (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", __assign({ className: clsx('flex  xs:max-w-full sm:max-w-256 flex-col py-8') }, { children: [_jsxs("div", __assign({ className: clsx('flex  xs:max-w-full sm:max-w-256 flex-col py-8') }, { children: [_jsx(TextField, { className: "", label: "\u0422\u0435\u043A\u0441\u0442", name: "text", value: formik.values['text'], onChange: handleChangeWrapper, error: Boolean(formik.errors['text']), helperText: formik.errors['text'], required: true }, void 0), _jsx(TextField, { className: "", label: "URL", name: "url", value: formik.values['url'], onChange: handleChangeWrapper, error: Boolean(formik.errors['url']), helperText: formik.errors['url'], required: true }, void 0), _jsx(ClubContactTypeField, { name: "type", label: "\u0421\u043E\u0446. \u0441\u0435\u0442\u044C", value: formik.values['type'], isError: Boolean(formik.errors['type']), helperText: formik.errors['type'], onChange: formik.setFieldValue, required: true }, void 0)] }), void 0), _jsxs("div", __assign({ className: "flex justify-end" }, { children: [_jsx(Button, __assign({ className: "mr-8", type: "reset", onClick: function () { return setEdit(false); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "primary sm", isSubmitButton: true }, { children: t('Сохранить') }), void 0)] }), void 0)] }), void 0) }), void 0)), _jsx(Divider, { light: true }, void 0)] }), void 0));
};
export default SportClubContact;
