var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { appHost, appScheme } from 'common/appURLSetup';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { SocialTypes } from 'common/enums/user/social-types.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useSocialBindMutation } from 'common/hooks/useSocialBindMutation';
import { vkClientId, yandexClientId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
var SocialBind = function (_a) {
    var socialType = _a.socialType;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onSocialBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSocialBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Подключение аккаунта выполнено.'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var _b = useDynamicMutation(useSocialBindMutation, onSocialBindSuccess, onSocialBindError), handleExecuteMutation = _b.handleExecuteMutation, loading = _b.loading;
    useEffect(function () {
        var _a, _b;
        var handleLogin = function (socialType, code) {
            handleExecuteMutation({ socialType: socialType, code: code });
        };
        var queryObj = queryString.parse(location.search);
        var code = queryObj && queryObj['?code'];
        // e.g. Vk === /settings/connections/vk/
        if (code && (socialType === null || socialType === void 0 ? void 0 : socialType.toLowerCase()) === ((_b = (_a = location === null || location === void 0 ? void 0 : location.pathname) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b[3])) {
            //Todo в настройках полара
            history.push(location.pathname);
            handleLogin(socialType, code);
        }
    }, [location.search, history]);
    var handleRedirect = function () {
        switch (socialType) {
            case SocialTypes.Yandex: {
                var cbLink = "".concat(appScheme, "://").concat(appHost, "/settings/connections/yandex");
                window.location.href = "https://oauth.yandex.ru/authorize?client_id=".concat(yandexClientId, "\n\t\t\t&redirect_uri=").concat(cbLink, "&response_type=code");
                break;
            }
            case SocialTypes.Vk: {
                var cbLink = "".concat(appScheme, "://").concat(appHost, "/settings/connections/vk/");
                window.location.href = "https://oauth.vk.com/authorize?client_id=".concat(vkClientId, "&display=popup&redirect_uri=").concat(cbLink, "&scope=email&response_type=code&v=5.130");
                break;
            }
        }
    };
    return (_jsx(_Fragment, { children: !loading && (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "tw-link-btn p-4", onClick: handleRedirect }, { children: t('Подключить') }), void 0) }, void 0)) }, void 0));
};
export default SocialBind;
