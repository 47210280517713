var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { saveNewPassword } from 'store/actions/';
import { useFormik } from 'formik';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import PasswordField from 'components/Fields/PasswordField';
var InputNewPasswordForm = function (props) {
    var dispatch = useDispatch();
    var verificationKey = props === null || props === void 0 ? void 0 : props.verificationKey;
    var verificationId = props === null || props === void 0 ? void 0 : props.verificationId;
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var defaultFormState = {
        password: '',
        confirmPassword: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(saveNewPassword({
                password: value.password,
                verificationId: verificationId,
                key: verificationKey
            }));
        },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0430\u0440\u043E\u043B\u044C" }), void 0) }), void 0), _jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(PasswordField, { id: 'password', title: '\u041F\u0430\u0440\u043E\u043B\u044C', value: formik.values['password'], name: 'password', onChange: handleChangeWrapper, isError: Boolean(formik.errors['password']), helperText: formik.errors['password'] }, void 0), _jsx(PasswordField, { id: 'confirmPassword', title: '\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C', value: formik.values['confirmPassword'], name: 'confirmPassword', onChange: handleChangeWrapper, isError: Boolean(formik.errors['confirmPassword']), helperText: formik.errors['confirmPassword'] }, void 0)] }), void 0), _jsx(Box, __assign({ sx: { width: '32rem', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '32rem' }, type: "submit" }, { children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default InputNewPasswordForm;
