var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material';
import { ru } from 'date-fns/locale';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import client from 'utils/apollo/apollo';
import Authorization from './components/Authorization';
import Layout from './components/Layout/Layout';
import Theme from './components/Theme';
import routes from './config/routesConfig';
import { AppContext } from './Context';
import Auth from './services/auth/Auth';
import history from './store/history';
import store from './store/store';
import './utils/i18n/i18n';
import JssExtendPlugin from './utils/plugins/jssExtendPlugin';
if (process.env.NODE_ENV === 'development') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}
function App() {
    return (_jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(LocalizationProvider, __assign({ locale: ru, dateAdapter: AdapterDateFns }, { children: _jsx(AppContext.Provider, __assign({ value: { routes: routes } }, { children: _jsx(JssExtendPlugin, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(Auth, { children: _jsx(Router, __assign({ history: history }, { children: _jsx(Authorization, { children: _jsx(Theme, { children: _jsx(Layout, {}, void 0) }, void 0) }, void 0) }), void 0) }, void 0) }), void 0) }), void 0) }, void 0) }), void 0) }), void 0) }), void 0));
}
export default App;
