var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { addDays } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useTranslation } from 'react-i18next';
var DateRangePeriod = function (_a) {
    var value = _a.value, activityStartDate = _a.activityStartDate, setRange = _a.setRange;
    var i18n = useTranslation().i18n;
    var _b = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        },
    ]), state = _b[0], setState = _b[1];
    useEffect(function () {
        if ((value === null || value === void 0 ? void 0 : value.startDate) && value.endDate) {
            setState([
                {
                    startDate: new Date(value.startDate),
                    endDate: new Date(value.endDate),
                    key: value.key
                },
            ]);
        }
    }, [value]);
    var setPeriod = function (period) {
        if (period.startDate && period.endDate) {
            setRange('period', {
                startDate: new Date(period.startDate) >= new Date(activityStartDate) &&
                    new Date(period.startDate) <= new Date()
                    ? new Date(period.startDate)
                    : new Date(activityStartDate),
                endDate: new Date(period.endDate) <= new Date() && new Date(period.endDate) >= new Date(activityStartDate)
                    ? new Date(period.endDate)
                    : new Date(),
                key: period.key
            });
        }
    };
    return (_jsx("div", __assign({ className: "flex" }, { children: _jsx(DateRange, { locale: i18n.language === 'en' ? enUS : ru, className: "p-4", editableDateInputs: true, onChange: function (item) { return setPeriod(item.selection || item.range1); }, 
            //onChange={(item: any) => setRange('period',[item.selection])}
            moveRangeOnFirstSelection: false, ranges: state, rangeColors: ['#dbb400'], minDate: addDays(new Date(activityStartDate), 0), maxDate: addDays(new Date(), 0) }, void 0) }), void 0));
};
export default DateRangePeriod;
