var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { IconButton } from '@mui/material';
import StravaConnectWithIcon from 'assets/logos/StravaConnectWithIcon';
import { appHost, appScheme } from 'common/appURLSetup';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useStravaBindMutation } from 'common/hooks/useStravaBindMutation';
import { stravaClientId } from 'common/socialClientIdsSetup';
import * as queryString from 'querystring';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openDialog, openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var StravaBind = function () {
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var t = useTranslation().t;
    var userId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var dispatch = useDispatch();
    var location = useHistory().location;
    var onStravaBindError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onStravaBindSuccess = function () {
        dispatch(openSnackbar({
            message: t('Подключение Strava аккаунта запущено.'),
            severity: SnackbarSeverity.SUCCESS
        }));
        dispatch(openDialog({
            dialogType: DialogTypes.AddTrainingSourceSuccessInfo,
            dialogProps: {
                userId: userId
            }
        }));
    };
    var _a = useDynamicMutation(useStravaBindMutation, onStravaBindSuccess, onStravaBindError), handleExecuteMutation = _a.handleExecuteMutation, loading = _a.loading;
    useEffect(function () {
        var handleLogin = function (code) {
            handleExecuteMutation({ code: code });
        };
        var queryObj = queryString.parse(location.search);
        var code = queryObj && queryObj['code'];
        if (code) {
            history.push('/settings/connections');
            handleLogin(queryObj['code']);
        }
    }, [location.search, history]);
    var cbLink = "".concat(appScheme, "://").concat(appHost, "/settings/connections");
    var handleRedirect = function () {
        localStorage.setItem('isShownAddTraningSourceDialog', 'true');
        window.location.href = "https://www.strava.com/oauth/authorize?client_id=".concat(stravaClientId, "\n    &display=popup&redirect_uri=").concat(cbLink, "&scope=profile:read_all,activity:read_all&response_type=code");
    };
    return (_jsx(_Fragment, { children: !loading && (_jsx(IconButton, __assign({ onClick: handleRedirect, style: { backgroundColor: 'transparent', padding: 0 } }, { children: _jsx(StravaConnectWithIcon, {}, void 0) }), void 0)) }, void 0));
};
export default StravaBind;
