var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
var DateRangeMonth = function (_a) {
    var value = _a.value, activityStartDate = _a.activityStartDate, activityEndDate = _a.activityEndDate, year = _a.year, setRange = _a.setRange;
    var i18n = useTranslation().i18n;
    var monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Арель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    var monthNamesEn = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'Septemper',
        'October',
        'November',
        'December',
    ];
    var _b = useState([]), monthList = _b[0], setMonthList = _b[1];
    useEffect(function () {
        if (activityStartDate && year) {
            var list = [];
            //1.первый месяц в списке будет такой же какой у activityStartDate и до текущего месяца - x=activityStartDate.getMonth() y=new Date().getMonth()
            //2.первый месяц в списке будет такой же какой у activityStartDate и до конца года - x=activityStartDate.getMonth() y=11
            //3.первый месяц январь - последний текущий - x=0 y=new Date().getMonth()
            //4.первый месяц январь - последний декабрь выбранного года - x=0 y=11
            //5.один вариант когда месяц activityStartDate = текущему месяцу
            var x = activityStartDate.getFullYear() === year ? activityStartDate.getMonth() : 0;
            var y = new Date().getFullYear() === year
                ? activityEndDate
                    ? _.min([new Date(activityEndDate).getMonth(), new Date().getMonth()]) || new Date().getMonth()
                    : new Date().getMonth()
                : 11;
            for (var i = x; i <= y; i++) {
                list.push(i);
            }
            setMonthList(list);
        }
    }, [activityStartDate, year]);
    var dispayMonthName = function (month, index) {
        return (_jsx("div", __assign({ onClick: function () {
                setRange('month', month);
            }, className: clsx("tw-date-range-filter-item", typeof value === 'number' && month === value && "selected") }, { children: i18n.language === 'en' ? monthNamesEn[month] : monthNames[month] }), index));
    };
    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("div", __assign({ className: "flex " }, { children: monthList.length && monthList.slice(0, 4).map(function (month, index) { return dispayMonthName(month, index); }) }), void 0), monthList.length > 3 && (_jsx("div", __assign({ className: "flex " }, { children: monthList.length && monthList.slice(4, 8).map(function (month, index) { return dispayMonthName(month, index); }) }), void 0)), monthList.length > 6 && (_jsx("div", __assign({ className: "flex " }, { children: monthList.length && monthList.slice(8).map(function (month, index) { return dispayMonthName(month, index); }) }), void 0))] }), void 0));
};
export default DateRangeMonth;
