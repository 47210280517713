var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useUpdateUserMutation } from 'common/hooks/useUpdateUserMutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var TelegramUnBind = function () {
    var t = useTranslation().t;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var userId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var dispatch = useDispatch();
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: t(code),
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Telegram аккаунт успешно откреплен.'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var handleExecuteMutation = useDynamicMutation(useUpdateUserMutation, onSuccess, onError).handleExecuteMutation;
    var handleDisconnect = function () {
        handleExecuteMutation({
            id: userId,
            contact: {
                telegram: null
            }
        });
    };
    return (_jsx("div", __assign({ className: "tw-link-btn p-4", onClick: handleDisconnect }, { children: t('Отключить') }), void 0));
};
export default TelegramUnBind;
