var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from '@mui/icons-material/Edit';
import { MenuItem, Select, Typography } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import ControlledSwitches from 'components/Switch/ControlledSwitches';
import { format, parseISO } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import EditAccordionSettings from '../EditAccordionSettings/EditAccordionSettings';
import ProfilePhotoUpload from '../ProfilePhotoUpload/ProfilePhotoUpload';
import CheckboxSettingsParam from './CheckboxSettingsParam/CheckboxSettingsParam';
var AccordionSettings = function (props) {
    var dispatch = useDispatch();
    var settingsGroupParams = props.settingsGroupParams, data = props.data, handleChangeData = props.handleChangeData;
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    var _c = useState(undefined), errorData = _c[0], setErrorData = _c[1];
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails, code = _a.code, errorField = _a.errorField;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
        setErrorData({ code: code, errorField: errorField });
    };
    var exitEditMode = function () {
        setEdit(false);
        setErrorData(undefined);
    };
    var successUpdate = function () {
        exitEditMode();
        onSuccess();
    };
    var handleExecuteMutation = useDynamicMutation(handleChangeData, successUpdate, onError).handleExecuteMutation;
    var renderParams = function (_a) {
        var value = _a.value, id = _a.id, type = _a.type, selectValues = _a.selectValues;
        switch (type) {
            case 'selectField': {
                return (_jsx(Select, __assign({ value: data[value] || '-', onChange: function (e) {
                        var _a;
                        return handleExecuteMutation((_a = {
                                id: data.id
                            },
                            _a[value] = e.target.value,
                            _a));
                    } }, { children: selectValues &&
                        (selectValues === null || selectValues === void 0 ? void 0 : selectValues.length) > 0 &&
                        (selectValues === null || selectValues === void 0 ? void 0 : selectValues.map(function (obj) {
                            return (_jsx(MenuItem, __assign({ value: obj.value }, { children: t(obj.text) }), obj.value));
                        })) }), id));
            }
            case 'location': {
                return (data[value] && (_jsxs(Typography, __assign({ display: "inline", sx: { fontSize: '1.4rem' } }, { children: [data[value].nameRu || data[value].nameEn, "\u00A0"] }), id)));
            }
            case 'textField': {
                return (_jsxs(Typography, __assign({ className: "[overflow-wrap:anywhere]", display: "inline", sx: { fontSize: '1.4rem' } }, { children: [data[value], "\u00A0"] }), id));
            }
            case 'textEditor': {
                return (_jsx("div", { children: _jsx(ReadOnlyEditor, { value: data[value] }, void 0) }, id));
            }
            case 'dateField': {
                var formattedDate = data[value] &&
                    format(parseISO(data[value]), 'dd MMMM yyyy', { locale: i18n.language === 'en' ? enUS : ru });
                return (_jsx(Typography, __assign({ sx: { fontSize: '1.4rem' } }, { children: formattedDate }), id));
            }
            case 'passwordField': {
                return (_jsx(Typography, __assign({ display: "inline", sx: { fontSize: '1.6rem' } }, { children: "\u2022\u2022\u2022\u2022\u2022" }), id));
            }
            case 'photo-upload': {
                return (_jsx(ProfilePhotoUpload, { handleChangeData: handleChangeData, id: data.id, profilePhoto: data[value], fieldName: value, firstName: data['firstName'] }, id));
            }
            case 'checkbox': {
                return (_jsx(CheckboxSettingsParam, { id: data.id, handleChangeData: data.handleChangeData || handleExecuteMutation, isChecked: !!data[value], fieldName: value }, id));
            }
            case 'switch': {
                return (_jsx(ControlledSwitches, { id: data.id, isChecked: data[value], fieldName: value, handleChangeData: handleExecuteMutation }, void 0));
            }
        }
    };
    return (_jsx("div", __assign({ className: "accordion-settings w-full" }, { children: !edit ? (_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("div", __assign({ className: "flex" }, { children: settingsGroupParams.values.length > 0 &&
                        settingsGroupParams.values.map(function (value, index) {
                            return _jsx("div", { children: renderParams(value) }, index);
                        }) }), void 0), settingsGroupParams.editable && _jsx(EditIcon, { onClick: function () { return setEdit(!edit); } }, void 0)] }), void 0)) : (_jsx(EditAccordionSettings, { settingsGroupParams: settingsGroupParams, data: data, handleChangeData: handleExecuteMutation, exitEditMode: exitEditMode, errorData: errorData }, void 0)) }), void 0));
};
export default AccordionSettings;
