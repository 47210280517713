var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { NOTIFICATION_INPUTS } from 'common/graphql/queries/notification/notification-inputs';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import NotificationInputAddNew from './NotificationInputAddNew';
import { NotificationInputsList } from './NotificationInputsList';
var NotificationManagementPage = function () {
    var _a = useQuery(NOTIFICATION_INPUTS), data = _a.data, loading = _a.loading;
    var notificationInputs = data === null || data === void 0 ? void 0 : data.notificationInputs;
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u043E\u043F\u043E\u0432\u0435\u0449\u0435\u043D\u0438\u044F\u043C\u0438. \u0414\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439." }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [_jsx(NotificationInputAddNew, {}, void 0), _jsx(NotificationInputsList, { notificationInputs: notificationInputs }, void 0)] }), void 0), innerScroll: false }, void 0));
};
export default NotificationManagementPage;
