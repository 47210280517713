var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Divider } from '@mui/material';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import { useUpdateSportClubMutation } from 'common/hooks/sportClub/useUpdateSportClubMutation/useUpdateSportClubMutation';
import Loading from 'components/Loading/Loading';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import SettingsGroup from 'main/pages/SettingsPage/components/SettingsGroup/SettingsGroup';
import { useTranslation } from 'react-i18next';
import clubProfileGroupsConfig from './clubProfileGroupsConfig';
var ClubProfile = function (_a) {
    var clubId = _a.clubId;
    var t = useTranslation().t;
    var _b = useQuery(SPORT_CLUB, {
        variables: {
            id: clubId
        }
    }), data = _b.data, loading = _b.loading;
    //конвертация необходима из-за вложенности mainSettings и др.полей
    var convertClubData = function (club) {
        var userData = {};
        if (club) {
            var mainSettings = club.mainSettings, userInfo = __rest(club, ["mainSettings"]);
            userData = __assign(__assign({}, userInfo), mainSettings);
        }
        return userData;
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: t('Профайл клуба') }, void 0), content: clubProfileGroupsConfig.length > 0 &&
            clubProfileGroupsConfig.map(function (settingsGroup) { return (_jsxs("div", { children: [_jsx(SettingsGroup, { settingsGroup: settingsGroup, data: convertClubData(data === null || data === void 0 ? void 0 : data.sportClub), handleChangeData: useUpdateSportClubMutation }, void 0), _jsx(Divider, { light: true }, void 0)] }, settingsGroup.id)); }) }, void 0));
};
export default ClubProfile;
