var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { GiSportMedal } from 'react-icons/gi';
import history from 'store/history';
import TournamentNominationWinnerStandingsDataConverted from './TournamentNominationWinnerStandingsDataConverted';
var TournamentNominationWinnersGroupListItem = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var nomination = _a.nomination, winner = _a.winner, gender = _a.gender, standingsParticipantType = _a.standingsParticipantType, handleClose = _a.handleClose;
    var place = gender ? winner.genderPlace : winner.place;
    var medalStyle = {
        color: place
            ? place === 1
                ? '#D4AF37'
                : place === 2
                    ? '#BCC6CC'
                    : place >= 3
                        ? '#A97142'
                        : '#A97142'
            : '#D4AF37'
    };
    var openUserProfile = function () {
        var _a, _b;
        if ((_a = winner.user) === null || _a === void 0 ? void 0 : _a.userName) {
            history.push("/profile/".concat((_b = winner.user) === null || _b === void 0 ? void 0 : _b.userName));
            handleClose();
        }
    };
    var openTeamProfile = function () {
        var _a, _b;
        if ((_a = winner.team) === null || _a === void 0 ? void 0 : _a.name) {
            history.push("/team/".concat((_b = winner.team) === null || _b === void 0 ? void 0 : _b.name));
            handleClose();
        }
    };
    return (gender && gender === ((_b = winner.user) === null || _b === void 0 ? void 0 : _b.mainSettings.gender)) || !gender ? (_jsxs("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: [_jsx(GiSportMedal, { size: "2em", style: medalStyle }, void 0), _jsx(UserAvatar, { style: "cursor-pointer xs:w-28 xs:h-28 text-16", firstName: standingsParticipantType === StandingsParticipantType.User
                    ? (_c = winner.user) === null || _c === void 0 ? void 0 : _c.mainSettings.firstName
                    : (_d = winner.team) === null || _d === void 0 ? void 0 : _d.displayName, profilePhoto: standingsParticipantType === StandingsParticipantType.User
                    ? (_e = winner.user) === null || _e === void 0 ? void 0 : _e.mainSettings.profilePhoto
                    : (_f = winner.team) === null || _f === void 0 ? void 0 : _f.profilePhoto }, void 0), _jsxs("div", __assign({ className: "flex flex-row space-x-10 items-center" }, { children: [_jsxs("p", __assign({ className: "w-136 min-h-40 cursor-pointer flex items-center", onClick: standingsParticipantType === StandingsParticipantType.User ? openUserProfile : openTeamProfile }, { children: [standingsParticipantType === StandingsParticipantType.User
                                ? (_g = winner.user) === null || _g === void 0 ? void 0 : _g.mainSettings.firstName
                                : (_h = winner.team) === null || _h === void 0 ? void 0 : _h.displayName, ' ', standingsParticipantType === StandingsParticipantType.User && ((_j = winner.user) === null || _j === void 0 ? void 0 : _j.mainSettings.lastName)] }), void 0), _jsx(TournamentNominationWinnerStandingsDataConverted, { nomination: nomination, standingsData: winner.standingsData }, void 0)] }), void 0)] }), void 0)) : null;
};
export default TournamentNominationWinnersGroupListItem;
