var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { GetSportClubUsersOrderBy } from 'common/enums/sport-club/get-sport-club-users-order-by.enum';
import { DELETE_SPORT_CLUB_USER } from 'common/graphql/mutations/sportClub/deleteSportClubUser';
import { UPDATE_SPORT_CLUB_USER } from 'common/graphql/mutations/sportClub/updateSportClubUser';
import { SPORT_CLUB_USERS_SEARCH } from 'common/graphql/queries/sportClub/sportClubUsersSearch';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Loading from 'components/Loading/Loading';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
import { clubSettingsPageVar } from 'utils/apollo/reactivities/clubSettingsPageVar';
import Utils from 'utils/Utils';
import ClubParticipantsSearchField from './ClubParticipantsSearchField/ClubParticipantsSearchField';
import ClubParticipantsSearchResultList from './ClubParticipantsSearchResultList/ClubParticipantsSearchResultList';
import ParticipantsSortTypeButton from './ParticipantsSortTypeButton/ParticipantsSortTypeButton';
var AllClubParticipants = function (_a) {
    var clubId = _a.clubId, currentUserRole = _a.currentUserRole;
    var perPage = 10;
    var t = useTranslation().t;
    var clubParticipantsSearchFieldInput = useReactiveVar(clubSettingsPageVar).clubParticipantsSearchFieldInput;
    var _b = useState(false), showSearch = _b[0], setShowSearch = _b[1];
    var _c = useState([]), users = _c[0], setUsers = _c[1];
    var _d = useState(false), hasMore = _d[0], setHasMore = _d[1];
    var _e = useLazyQuery(SPORT_CLUB_USERS_SEARCH), getSportClubUsersSearch = _e[0], _f = _e[1], data = _f.data, loading = _f.loading, error = _f.error;
    var _g = useState(1), page = _g[0], setPage = _g[1];
    var _h = useState({
        orderBy: GetSportClubUsersOrderBy.firstName,
        orderParam: 'desc'
    }), orderByAndOrderParam = _h[0], setOrderByAndOrderParam = _h[1];
    var _j = useState(''), clubParticipantsSearchInput = _j[0], setClubParticipantsSearchInput = _j[1];
    var variables = {
        sportClubId: clubId,
        searchQuery: Utils.prepareSearchInputValue(clubParticipantsSearchFieldInput) || '',
        limit: perPage,
        offset: page > 1 ? perPage * (page - 1) : 0,
        orderBy: orderByAndOrderParam.orderBy,
        orderParam: orderByAndOrderParam.orderParam
    };
    useEffect(function () {
        if (page) {
            getSportClubUsersSearch({
                fetchPolicy: 'network-only',
                variables: variables
            });
        }
    }, [page, orderByAndOrderParam, clubParticipantsSearchInput]);
    useEffect(function () {
        if (orderByAndOrderParam) {
            setPage(1);
        }
    }, [orderByAndOrderParam]);
    useEffect(function () {
        var _a, _b;
        if ((_a = data === null || data === void 0 ? void 0 : data.sportClubUsersSearch) === null || _a === void 0 ? void 0 : _a.length) {
            if (page === 1) {
                setUsers(__spreadArray([], data === null || data === void 0 ? void 0 : data.sportClubUsersSearch, true));
            }
            else {
                setUsers(function (prevUsers) { return __spreadArray(__spreadArray([], prevUsers, true), data === null || data === void 0 ? void 0 : data.sportClubUsersSearch, true); });
            }
            setHasMore((data === null || data === void 0 ? void 0 : data.sportClubUsersSearch) && (data === null || data === void 0 ? void 0 : data.sportClubUsersSearch.length) === perPage);
        }
        if (!((_b = data === null || data === void 0 ? void 0 : data.sportClubUsersSearch) === null || _b === void 0 ? void 0 : _b.length) && clubParticipantsSearchInput && page === 1) {
            setUsers([]);
        }
    }, [data]);
    var loadMore = useCallback(function () {
        setPage(function (page) { return page + 1; });
    }, []);
    useEffect(function () {
        var delayDebounceFn = setTimeout(function () {
            setPage(1);
            setClubParticipantsSearchInput(clubParticipantsSearchFieldInput);
        }, 500);
        return function () { return clearTimeout(delayDebounceFn); };
    }, [clubParticipantsSearchFieldInput]);
    var dispatch = useDispatch();
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onUpdateIsAdminRoleSuccess = function () {
        setPage(1);
        onSuccess();
    };
    var handleExecuteMutation = useDynamicMutation(function (userId) {
        return useMutation(DELETE_SPORT_CLUB_USER, {
            variables: {
                deleteSportClubUserData: {
                    sportClubId: clubId,
                    userId: userId
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB_USERS_SEARCH,
                    variables: variables
                },
            ]
        });
    }, onUpdateIsAdminRoleSuccess, onError).handleExecuteMutation;
    var handleUpdateUserMutation = useDynamicMutation(function (data) {
        return useMutation(UPDATE_SPORT_CLUB_USER, {
            variables: {
                updateSportClubUserdata: {
                    userId: data === null || data === void 0 ? void 0 : data.userId,
                    isAdmin: data === null || data === void 0 ? void 0 : data.isAdmin,
                    isOwner: data === null || data === void 0 ? void 0 : data.isOwner
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB_USERS_SEARCH,
                    variables: variables
                },
            ]
        });
    }, onUpdateIsAdminRoleSuccess, onError).handleExecuteMutation;
    var handleChangeIsAdminRole = function (e, userId) {
        var isAdmin = e.target.checked;
        handleUpdateUserMutation({ userId: userId, isAdmin: isAdmin });
    };
    var handleChangeIsOwnerRole = function (e, userId) {
        var isOwner = e.target.checked;
        handleUpdateUserMutation({ userId: userId, isOwner: isOwner });
    };
    var handleExpelUser = function (userId) {
        dispatch(openDialog({
            dialogType: DialogTypes.ExpelUserRequestDialog,
            dialogProps: { from: 'club', handleExpel: function () { return handleExecuteMutation(userId); } }
        }));
    };
    if (page === 1 && loading)
        return _jsx(Loading, {}, void 0);
    return (_jsxs("div", __assign({ className: "h-full max-h-full " }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsxs("div", { children: [_jsx(ParticipantsSortTypeButton, { sortType: orderByAndOrderParam.orderBy, setSortType: function (orderBy) {
                                    setOrderByAndOrderParam({
                                        orderBy: orderBy,
                                        orderParam: orderByAndOrderParam.orderParam
                                    });
                                } }, void 0), _jsx(Tooltip, __assign({ title: orderByAndOrderParam.orderParam === 'asc'
                                    ? t('По убыванию')
                                    : t('По возрастанию') }, { children: _jsx(SwapVertIcon, { className: clsx('w-24 h-24 xs:mr-8 cursor-pointer', orderByAndOrderParam.orderParam === 'asc' && 'scale-x-180'), color: "disabled", onClick: function () {
                                        setOrderByAndOrderParam({
                                            orderBy: orderByAndOrderParam.orderBy,
                                            orderParam: orderByAndOrderParam.orderParam === 'asc' ? 'desc' : 'asc'
                                        });
                                    } }, void 0) }), void 0)] }, void 0), showSearch ? (_jsx(ClubParticipantsSearchField, {}, void 0)) : (_jsx(SearchIcon, { className: "h-20 w-20 cursor-pointer", onClick: function () { return setShowSearch(!showSearch); } }, void 0))] }), void 0), _jsx("div", __assign({ className: "flex xs:pb-16 md:pb-24 mt-8" }, { children: _jsx(ClubParticipantsSearchResultList, { userProfiles: users, hasMore: hasMore, isLoading: loading, currentUserRole: currentUserRole, loadMore: loadMore, handleChangeIsAdminRole: handleChangeIsAdminRole, handleChangeIsOwnerRole: handleChangeIsOwnerRole, handleExpelUser: handleExpelUser }, void 0) }), void 0)] }), void 0));
};
export default AllClubParticipants;
