var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Actions from '../../actions/base/dialog.actions';
var initialState = {
    dialog: {
        dialogType: null,
        dialogProps: {}
    },
    snackbar: {
        message: null,
        severity: null
    }
};
var alertReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var payload = action.payload;
    switch (action.type) {
        case Actions.OPEN_DIALOG:
            return __assign(__assign({}, state), { dialog: {
                    dialogType: payload.dialogType,
                    dialogProps: payload.dialogProps
                } });
        case Actions.CLOSE_DIALOG:
            return __assign(__assign({}, state), { dialog: {
                    dialogType: null,
                    dialogProps: {}
                } });
        case Actions.OPEN_SNACKBAR:
            return __assign(__assign({}, state), { snackbar: {
                    message: payload.message,
                    severity: payload.severity
                } });
        case Actions.CLOSE_SNACKBAR:
            return __assign(__assign({}, state), { snackbar: {
                    message: null,
                    severity: null
                } });
        default:
            return state;
    }
};
export default alertReducer;
