var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { CONFIRM_SPORT_CLUB_USER_JOIN_REQUEST } from 'common/graphql/mutations/sportClub/confirmSportClubUserJoinRequest';
import { DECLINE_SPORT_CLUB_USER_JOIN_REQUEST } from 'common/graphql/mutations/sportClub/declineSportClubUserJoinRequest';
import { SPORT_CLUB } from 'common/graphql/queries/sportClub/sportClub';
import { SPORT_CLUB_USER_JOIN_REQUESTS } from 'common/graphql/queries/sportClub/sportClubUserJoinRequests';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Loading from 'components/Loading/Loading';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import ClubUserJoinRequestsSearchResultList from './ClubUserJoinRequestsSearchResultList/ClubUserJoinRequestsSearchResultList';
var ClubUserJoinRequests = function (_a) {
    var clubId = _a.clubId;
    var _b = useLazyQuery(SPORT_CLUB_USER_JOIN_REQUESTS), getSportClubUserJoinRequest = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    useEffect(function () {
        if (clubId) {
            getSportClubUserJoinRequest({
                variables: {
                    sportClubId: clubId
                }
            });
        }
    }, [clubId]);
    var dispatch = useDispatch();
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var onSuccess = function (message) {
        dispatch(openSnackbar({
            message: message,
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onHandleDeclineUserJoinRequestSuccess = function () {
        onSuccess('Заявка пользователя отклонена.');
    };
    var onHandleConfirmUserJoinRequestSuccess = function () {
        onSuccess('Заявка принята! Пользователь добавлен в клуб.');
    };
    var handleExecuteMutation = useDynamicMutation(function (userId) {
        return useMutation(CONFIRM_SPORT_CLUB_USER_JOIN_REQUEST, {
            variables: {
                userId: userId
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB,
                    variables: {
                        id: clubId
                    }
                },
                {
                    query: SPORT_CLUB_USER_JOIN_REQUESTS,
                    variables: {
                        sportClubId: clubId
                    }
                },
            ]
        });
    }, onHandleConfirmUserJoinRequestSuccess, onError).handleExecuteMutation;
    var handleDeclineUserJoinRequest = useDynamicMutation(function (userId) {
        return useMutation(DECLINE_SPORT_CLUB_USER_JOIN_REQUEST, {
            variables: {
                userId: userId
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB,
                    variables: {
                        id: clubId
                    }
                },
                {
                    query: SPORT_CLUB_USER_JOIN_REQUESTS,
                    variables: {
                        sportClubId: clubId
                    }
                },
            ]
        });
    }, onHandleDeclineUserJoinRequestSuccess, onError).handleExecuteMutation;
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx("div", __assign({ className: "h-full max-h-full " }, { children: (data === null || data === void 0 ? void 0 : data.sportClubUserJoinRequestsSearch) && (_jsx("div", __assign({ className: "flex xs:pb-16 md:pb-24 mt-8" }, { children: _jsx(ClubUserJoinRequestsSearchResultList, { userJoinRequests: data === null || data === void 0 ? void 0 : data.sportClubUserJoinRequestsSearch, handleConfirmUserJoinRequest: handleExecuteMutation, handleDeclineUserJoinRequest: handleDeclineUserJoinRequest }, void 0) }), void 0)) }), void 0));
};
export default ClubUserJoinRequests;
