var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import HikingIcon from '@mui/icons-material/Hiking';
import PoolIcon from '@mui/icons-material/Pool';
import Cycle from 'assets/icons/activities/Cycle';
import Hike from 'assets/icons/activities/Hike';
import Run from 'assets/icons/activities/Run';
import Ski from 'assets/icons/activities/Ski';
import Swim from 'assets/icons/activities/Swim';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { Gender } from 'common/enums/common/gender';
import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import { Sports } from 'common/enums/sport/sports.enum';
import { SupportRequestStatus } from 'common/enums/support-request/support-request-status.enum';
import { intervalToDuration } from 'date-fns';
import Utils from './Utils';
var HelperFunctions = /** @class */ (function () {
    function HelperFunctions() {
        this.getFirstDayOfCurrentWeek = this.getFirstDayOfCurrentWeek.bind(this);
        this.getWeekNumber = this.getWeekNumber.bind(this);
        this.getStartDateOfWeek = this.getStartDateOfWeek.bind(this);
        this.convertDateToStandingsQueryFormat = this.convertDateToStandingsQueryFormat.bind(this);
    }
    HelperFunctions.getAge = function (dateString, lang) {
        if (dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            var splitAgeArr = age.toString().split('');
            var ageFirstNumber = parseInt(splitAgeArr[0] || '1');
            var ageLastNumber = parseInt(splitAgeArr.pop() || '0');
            var ageStr = '';
            if (lang === 'ru') {
                ageStr = ' лет';
                if (ageLastNumber === 1 && ageFirstNumber !== 1)
                    ageStr = ' год';
                if ([2, 3, 4].includes(ageLastNumber) && ageFirstNumber !== 1)
                    ageStr = ' года';
            }
            return "".concat(age.toString()).concat(ageStr);
        }
    };
    HelperFunctions.formatValidationLevel = function (validationLevel, lang) {
        switch (validationLevel) {
            case ActivityValidationLevels.Base:
                return lang === 'en' ? 'Base' : 'Базовый';
            case ActivityValidationLevels.SportClub:
                return lang === 'en' ? 'Club' : 'Клуб';
            case ActivityValidationLevels.SportEvent:
                return lang === 'en' ? 'Event' : 'Событие';
            case ActivityValidationLevels.Standings:
                return lang === 'en' ? 'Standings' : 'Турнирная таблица';
            case ActivityValidationLevels.Tournament:
                return lang === 'en' ? 'Tournament' : 'Турнир';
            default:
                return validationLevel;
        }
    };
    HelperFunctions.getGender = function (gender, lang) {
        if (!gender)
            return '-';
        if (lang === 'ru') {
            return gender === Gender.MALE ? 'Мужской' : 'Женский';
        }
        else {
            return gender === Gender.MALE ? 'Male' : 'Female';
        }
    };
    HelperFunctions.getAverageSpeed = function (distance, movingTime) {
        if (!distance)
            return 0;
        if (!movingTime)
            return 0;
        return (distance / 1000 / (movingTime / 3600)).toFixed(1);
    };
    HelperFunctions.getReadableTempo = function (tempo) {
        tempo = typeof tempo === 'string' ? parseFloat(tempo) : tempo;
        if (tempo === 0)
            return '00:00';
        var minute = Math.floor(tempo);
        var minuteStr = minute.toString();
        if (minute < 10)
            minuteStr = "0".concat(minute);
        var secondsFloat = tempo - minute;
        var seconds = Math.floor(secondsFloat * 60);
        var secondsStr = seconds.toString();
        if (seconds < 10)
            secondsStr = "0".concat(seconds);
        return "".concat(minuteStr, ":").concat(secondsStr);
    };
    HelperFunctions.getActivityDate = function (startDate, lang) {
        var d = new Date(startDate);
        return "".concat(Utils.getDateWithRuMonth(startDate, lang), ", ").concat(('0' + d.getHours()).slice(-2), ":").concat(('0' + d.getMinutes()).slice(-2));
    };
    HelperFunctions.timeFormat = function (value) {
        if (!value)
            return '-';
        //value в секундах
        var duration = intervalToDuration({ start: 0, end: value * 1000 });
        var s = "".concat("0".concat(duration.minutes).slice(-2), ":").concat("0".concat(duration.seconds).slice(-2));
        if (value >= 3600) {
            s = "".concat("0".concat(duration.hours).slice(-2), ":") + s;
        }
        if (value >= 86400) {
            s = "".concat("0".concat(duration.days).slice(-2), ":") + s;
        }
        return s;
    };
    HelperFunctions.prototype.getFirstDayOfCurrentWeek = function () {
        var todayObj = new Date();
        var todayDate = todayObj.getDate();
        var todayDay = todayObj.getDay();
        return new Date(new Date(todayObj.setDate(todayDate - todayDay + 1)).toISOString().slice(0, 10));
    };
    HelperFunctions.prototype.isDateWithinLastWeek = function (date) {
        var firstDayOfWeek = this.getFirstDayOfCurrentWeek();
        var lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
        return date >= firstDayOfWeek && date <= lastDayOfWeek;
    };
    HelperFunctions.isDateWithinLastHalfYear = function (date) {
        var d = new Date();
        var m = d.getMonth();
        d.setMonth(d.getMonth() - 6);
        var diff = (m + 12 - d.getMonth()) % 12;
        if (diff < 6)
            d.setDate(0);
        return date > d;
    };
    HelperFunctions.getLastHalfYearMonthsRu = function () {
        var date = new Date();
        var months = 'Янв Фев Март Апр Май Июнь Июль Авг Сент Окт Ноя Дек';
        var monthsArr = months.split(' ');
        var labels = [];
        var currentMonth = date.getMonth();
        var startMonth = currentMonth < 6 ? 0 : currentMonth - 5;
        if (currentMonth < 6) {
            for (var i = 14 - (6 - currentMonth + 1); i <= 11; i++) {
                labels.push(monthsArr[i]);
            }
        }
        for (var i = startMonth; i <= currentMonth; i++) {
            labels.push(monthsArr[i]);
        }
        return { labels: labels };
    };
    HelperFunctions.getLastYears = function (years) {
        var date = new Date();
        var currentYear = date.getFullYear();
        var labels = [];
        for (var year = currentYear - (years - 1); year <= currentYear; year++) {
            labels.push(year.toString());
        }
        return { labels: labels };
    };
    HelperFunctions.prototype.getStartDateOfWeek = function (week, year) {
        // Create a date for 1 Jan in required year
        var d = new Date(Date.UTC(parseInt(year), 0, 1));
        // Get day of week number, sun = 0, mon = 1, etc.
        var dayNum = d.getDay();
        // Get days to add
        var requiredDate = --week * 7;
        // For ISO week numbering
        // If 1 Jan is Friday to Sunday, go to next week
        if (dayNum === 0 || dayNum > 4) {
            d.setDate(d.getDate() + (7 - --dayNum) + requiredDate);
        }
        else {
            d.setDate(d.getDate() - --dayNum + requiredDate);
        }
        // Add required number of days
        return d;
    };
    HelperFunctions.prototype.getWeekNumber = function (d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(new Date(d).getFullYear(), new Date(d).getMonth(), new Date(d).getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
    };
    HelperFunctions.prototype.getDateRangeOfWeek = function (weekNo, year) {
        var dateStart = this.getStartDateOfWeek(weekNo, year);
        var dateEnd = this.getStartDateOfWeek(weekNo, year);
        dateEnd.setDate(dateEnd.getDate() + 6);
        var labels = [];
        var days = [];
        var daysOfWeek = 'пн вт ср чт пт сб вс';
        var daysOfweekArr = daysOfWeek.split(' ');
        for (var i = 0; i < 7; i++) {
            var dayOfWeekDate = new Date(dateStart);
            dayOfWeekDate.setDate(dayOfWeekDate.getDate() + i);
            days.push(dayOfWeekDate.toISOString().slice(0, 10));
            labels.push("".concat(dayOfWeekDate.getDate(), " ").concat(daysOfweekArr[i]));
        }
        return {
            dateStart: dateStart.toISOString().slice(0, 10),
            dateEnd: dateEnd.toISOString().slice(0, 10),
            days: days,
            labels: labels
        };
    };
    HelperFunctions.toHoursAndMinutes = function (totalSeconds) {
        if (!totalSeconds)
            return { h: 0, m: 0, s: 0 };
        var totalMinutes = Math.floor(totalSeconds / 60);
        var seconds = Math.floor(totalSeconds % 60);
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        return { h: hours, m: minutes, s: seconds };
    };
    HelperFunctions.formatChartMinutes = function (totalMinutes) {
        if (!totalMinutes)
            return '0';
        var minutesNum = parseFloat(totalMinutes.replace(/\s/g, ''));
        var days = Math.floor(minutesNum / 60 / 24);
        var hours = Math.floor(minutesNum / 60) - days * 24;
        var minutes = Math.floor(minutesNum - days * 24 * 60 - hours * 60);
        return days > 0 ? "".concat(days, "\u0434 ").concat(hours, "\u0447 ").concat(minutes, "\u043C\u0438\u043D") : hours > 0 ? "".concat(hours, "\u0447 ").concat(minutes, "\u043C\u0438\u043D") : "".concat(minutes, "\u043C\u0438\u043D");
    };
    HelperFunctions.prototype.convertDateToStandingsQueryFormat = function (dateType, value) {
        if (dateType === 'month' && typeof value === 'number')
            return ++value < 10 ? "0".concat(value) : "".concat(value);
        if (dateType === 'day' && typeof value === 'number')
            return value < 10 ? "0".concat(value) : "".concat(value);
        if (dateType === 'date' && value instanceof Date) {
            value.setHours(1);
            return "".concat(value.getFullYear(), "-").concat(this.convertDateToStandingsQueryFormat('month', value.getMonth()), "-").concat(this.convertDateToStandingsQueryFormat('day', value.getDate()));
        }
    };
    HelperFunctions.getSportDetailsBySportName = function (sportName, iconStyle) {
        if (iconStyle === void 0) { iconStyle = 'filter'; }
        if (sportName === Sports.Run) {
            return {
                activityType: Sports.Run,
                children: iconStyle === 'filter' ? _jsx(Run, {}, void 0) : _jsx(DirectionsRunIcon, {}, void 0),
                tooltip: 'Бег'
            };
        }
        if (sportName === Sports.Ride) {
            return {
                activityType: Sports.Ride,
                children: iconStyle === 'filter' ? _jsx(Cycle, {}, void 0) : _jsx(DirectionsBikeIcon, {}, void 0),
                tooltip: 'Велосипед'
            };
        }
        if (sportName === Sports.Walking) {
            return {
                activityType: Sports.Walking,
                children: iconStyle === 'filter' ? _jsx(Hike, {}, void 0) : _jsx(HikingIcon, {}, void 0),
                tooltip: 'Ходьба'
            };
        }
        if (sportName === Sports.Swimming) {
            return {
                activityType: Sports.Swimming,
                children: iconStyle === 'filter' ? _jsx(Swim, {}, void 0) : _jsx(PoolIcon, {}, void 0),
                tooltip: 'Плавание'
            };
        }
        if (sportName === Sports.Skiing) {
            return {
                activityType: Sports.Skiing,
                children: iconStyle === 'filter' ? _jsx(Ski, {}, void 0) : _jsx(DownhillSkiingIcon, {}, void 0),
                tooltip: 'Лыжи'
            };
        }
    };
    HelperFunctions.prototype.getStandingsVariablesFromFilter = function (filterByRange, standingsId) {
        switch (filterByRange.dateRange) {
            case DateRangeFilterItems.year: {
                return {
                    id: standingsId,
                    detailedStatsType: DateRangeFilterItems.year,
                    date: "".concat(filterByRange.year),
                    dateEnd: undefined
                };
            }
            case DateRangeFilterItems.month: {
                var month = (filterByRange.month || filterByRange.month === 0) &&
                    this.convertDateToStandingsQueryFormat('month', filterByRange.month);
                return {
                    id: standingsId,
                    detailedStatsType: DateRangeFilterItems.month,
                    date: "".concat(filterByRange.year, "-").concat(month),
                    dateEnd: undefined
                };
            }
            case DateRangeFilterItems.period: {
                if (filterByRange.period) {
                    var startDate = filterByRange.period.startDate || new Date();
                    var endDate = filterByRange.period.endDate || new Date();
                    return this.convertDateToStandingsQueryFormat('date', startDate) ===
                        this.convertDateToStandingsQueryFormat('date', endDate)
                        ? {
                            id: standingsId,
                            detailedStatsType: DateRangeFilterItems.day,
                            date: this.convertDateToStandingsQueryFormat('date', startDate)
                        }
                        : {
                            id: standingsId,
                            detailedStatsType: DateRangeFilterItems.period,
                            date: this.convertDateToStandingsQueryFormat('date', startDate),
                            dateEnd: this.convertDateToStandingsQueryFormat('date', endDate)
                        };
                }
                break;
            }
            default: {
                return {
                    id: standingsId,
                    detailedStatsType: undefined,
                    date: undefined,
                    dateEnd: undefined
                };
            }
        }
    };
    HelperFunctions.formatSupportRequestStatus = function (supportRequest, lang) {
        switch (supportRequest.status) {
            case SupportRequestStatus.closed:
                return _jsx("p", __assign({ className: "text-grey" }, { children: lang === 'en' ? 'Closed' : 'Закрыт' }), void 0);
            case SupportRequestStatus.inProgress:
                return _jsx("p", __assign({ className: "text-green" }, { children: lang === 'en' ? 'In progress' : 'На рассмотрении' }), void 0);
            case SupportRequestStatus["new"]:
                return _jsx("p", __assign({ className: "text-green" }, { children: lang === 'en' ? 'New' : 'Новое' }), void 0);
            case SupportRequestStatus.pendingCustomer:
                return (_jsx("p", __assign({ className: "text-yellow" }, { children: lang === 'en' ? 'Pending requestor' : 'Ожидает ответа заявителя' }), void 0));
            case SupportRequestStatus.updatedByCustomer:
                return (_jsx("p", __assign({ className: "text-mainYellow" }, { children: lang === 'en' ? 'Updated by requestor' : 'Обновлено пользователем' }), void 0));
            default:
                return _jsx("p", __assign({ className: "text-grey" }, { children: supportRequest.status }), void 0);
        }
    };
    HelperFunctions.getSportNameRu = function (sportName) {
        if (sportName === Sports.Run) {
            return 'Бег';
        }
        if (sportName === Sports.Ride) {
            return 'Велосипед';
        }
        if (sportName === Sports.Walking) {
            return 'Ходьба';
        }
        if (sportName === Sports.Swimming) {
            return 'Плавание';
        }
        if (sportName === Sports.Skiing) {
            return 'Лыжи';
        }
        return sportName;
    };
    return HelperFunctions;
}());
export default HelperFunctions;
