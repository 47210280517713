var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Tab, Tabs } from '@mui/material';
import { CREATE_ACTIVITY_FROM_GPX } from 'common/graphql/mutations/activity/createActivity/createActivityFromGpx';
import { USER } from 'common/graphql/queries/user/user';
import LoadingFilesForm from 'components/Forms/LoadingFilesForm';
import Loading from 'components/Loading/Loading';
import TabPanel from 'components/TabPanel/TabPanel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import CreateActivityManualForm from './CreateActivityManualForm/CreateActivityManualForm';
import SelectActivitiesSourceForm from './SelectActivitiesSourceForm/SelectActivitiesSourceForm';
var AddActivitiesDialog = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var _j = _a.defaultTabValue, defaultTabValue = _j === void 0 ? 0 : _j, handleClose = _a.handleClose, userId = _a.userId, userActivitiesQueueRequests = _a.userActivitiesQueueRequests;
    var _k = useState(undefined), loadingActivities = _k[0], setLoadingActivities = _k[1];
    var _l = useState(defaultTabValue), tabValue = _l[0], setTabValue = _l[1];
    var t = useTranslation().t;
    var currentUserId = userId || ((_b = useReactiveVar(userProfileDataVar).userProfile) === null || _b === void 0 ? void 0 : _b.id);
    var _m = useLazyQuery(USER), getUser = _m[0], _o = _m[1], data = _o.data, loading = _o.loading;
    useEffect(function () {
        if (currentUserId) {
            getUser({
                variables: {
                    id: currentUserId,
                    showSocialSettings: true
                }
            });
        }
    }, [currentUserId]);
    var polarAccountId = (_e = (_d = (_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.polarSettings) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.serviceBindingId;
    var stravaAccountId = (_h = (_g = (_f = data === null || data === void 0 ? void 0 : data.user) === null || _f === void 0 ? void 0 : _f.stravaSettings) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.serviceBindingId;
    var handleChangeTab = function (_event, tabValue) {
        setTabValue(tabValue);
    };
    var executableMutation = function (file) {
        return useMutation(CREATE_ACTIVITY_FROM_GPX, {
            variables: {
                file: file
            }
        });
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return currentUserId ? (_jsxs("div", __assign({ className: "flex flex-col pb-20" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Добавить тренировки') }), void 0), _jsxs(Tabs, __assign({ sx: { height: '4.4rem' }, value: tabValue, onChange: handleChangeTab }, { children: [_jsx(Tab, { className: "xs:text-xs md:text-xs", sx: { height: '4.4rem' }, label: t('Из источника') }, void 0), _jsx(Tab, { className: "xs:text-xs md:text-xs", sx: { height: '4.4rem' }, label: t('Вручную') }, void 0)] }), void 0), _jsxs("div", __assign({ className: "pt-20" }, { children: [_jsx(TabPanel, __assign({ value: tabValue, index: 0 }, { children: loadingActivities ? (_jsx(LoadingFilesForm, { formText: t('Загрузка GPX файлов'), loadingFiles: loadingActivities, executableMutation: executableMutation }, void 0)) : (_jsx(SelectActivitiesSourceForm, { userId: currentUserId, polarAccountId: polarAccountId, stravaAccountId: stravaAccountId, setLoadingActivities: setLoadingActivities, userActivitiesQueueRequests: userActivitiesQueueRequests || [] }, void 0)) }), void 0), _jsx(TabPanel, __assign({ value: tabValue, index: 1 }, { children: _jsx(CreateActivityManualForm, { userId: currentUserId, handleClose: handleClose }, void 0) }), void 0)] }), void 0)] }), void 0)) : null;
};
export default AddActivitiesDialog;
