var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import clsx from 'clsx';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { TEAM } from 'common/graphql/queries/team/team';
import { USER_TOURNAMENT_TEAM } from 'common/graphql/queries/team/user-tournament-team';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import TeamPageContent from './components/TeamPageContent/TeamPageContent';
import TeamProfilePageHeader from './components/TeamProfilePageHeader/TeamProfilePageHeader';
var TeamPage = function (_a) {
    var name = _a.name;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = userProfile === null || userProfile === void 0 ? void 0 : userProfile.id;
    var _b = useQuery(TEAM, {
        variables: {
            name: name,
            showBasicFields: true,
            showTournaments: true,
            showStandings: true,
            showParticipationSettings: true
        }
    }), data = _b.data, loading = _b.loading, error = _b.error;
    var _c = useLazyQuery(USER_TOURNAMENT_TEAM), getUserTournamentTeam = _c[0], _d = _c[1], userTournamentTeamData = _d.data, userTournamentTeamDataLoading = _d.loading;
    var team = data === null || data === void 0 ? void 0 : data.team;
    var userTournamentTeam = userTournamentTeamData === null || userTournamentTeamData === void 0 ? void 0 : userTournamentTeamData.userTournamentTeam;
    var dispatch = useDispatch();
    var onTeamNotFoundMessage = function () {
        dispatch(openSnackbar({
            message: 'Команда не найдена.',
            severity: SnackbarSeverity.ERROR
        }));
    };
    useEffect(function () {
        var _a, _b;
        if (team && currentUserId) {
            getUserTournamentTeam({
                variables: {
                    tournamentId: (_b = (_a = team.tournaments) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id,
                    userId: currentUserId
                }
            });
        }
    }, [data]);
    useEffect(function () {
        if (error) {
            history.push('/events');
            onTeamNotFoundMessage();
        }
    }, [error]);
    if (loading || userTournamentTeamDataLoading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { className: "tw-event-page-container", breadcrumbs: _jsx(Breadcrumbs, {}, void 0), header: _jsx("div", __assign({ className: clsx('tw-page-container-shadow') }, { children: team && (_jsx(TeamProfilePageHeader, { team: team, isUserTeamParticipant: userTournamentTeam ? true : false }, void 0)) }), void 0), content: team ? _jsx(TeamPageContent, { team: team }, void 0) : _jsx("p", { children: "\u041A\u043E\u043C\u0430\u043D\u0434\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430" }, void 0), innerScroll: false }, void 0));
};
export default TeamPage;
