var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import history from 'store/history';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import Utils from 'utils/Utils';
import ClubParticipantMoreMenu from '../ClubParticipantMoreMenu/ClubParticipantMoreMenu';
var ClubParticipantsSearchResultListItem = function (_a) {
    var userProfile = _a.userProfile, mesureRef = _a.mesureRef, currentUserRole = _a.currentUserRole, handleChangeIsAdminRole = _a.handleChangeIsAdminRole, handleChangeIsOwnerRole = _a.handleChangeIsOwnerRole, handleExpelUser = _a.handleExpelUser;
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    // const isClubMate = userProfile?.sportClubId === currentUserProfile?.sportClub?.id;
    var isMy = (userProfile === null || userProfile === void 0 ? void 0 : userProfile.userId) === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id);
    var getUserInfo = function () {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 pb-2 w-148 sm:w-170" }, { children: [t('Дата вступления'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14" }, { children: userProfile.clubMemberSince
                                ? Utils.getDateWithRuMonth(new Date(userProfile.clubMemberSince), i18n.language)
                                : "-" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex" }, { children: [_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 w-148 sm:w-170" }, { children: [t('Последняя тренировка'), ":"] }), void 0), _jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14" }, { children: userProfile.lastActivityDate
                                ? Utils.getDateWithRuMonth(new Date(userProfile.lastActivityDate), i18n.language)
                                : "-" }), void 0)] }), void 0)] }, void 0));
    };
    return (_jsxs("div", __assign({ className: "flex justify-between xs:py-8 md:py-12 decoration-0", ref: mesureRef }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex items-center w-full space-x-10" }, { children: [_jsx(UserAvatar, { style: "xs:w-48 xs:h-48 xs:text-16", profilePhoto: { url: userProfile.userProfilePhotoUrl || '' }, firstName: userProfile.firstName }, void 0), _jsx("div", __assign({ className: "w-full flex xs:flex-col sm:flex-row justify-between h-full" }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex flex-col sm:flex-row" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                        history.push({
                                                            pathname: '/profile/' + userProfile.userName,
                                                            state: { fromAthletesSearchPage: true }
                                                        });
                                                    }, className: "flex xs:text-14 md:text-16 font-medium py-2 cursor-pointer items-center sm:pr-8" }, { children: [userProfile.firstName, " ", userProfile.lastName] }), void 0), _jsxs("div", __assign({ className: "flex items-center" }, { children: [isMy && (_jsxs("div", __assign({ className: "text-gray xs:text-12 md:text-14 py-2 pr-4" }, { children: ["(", t('Это вы'), ")"] }), void 0)), (userProfile.isAdmin || userProfile.isOwner) && (_jsx("div", __assign({ className: "text-gray xs:text-12 md:text-14 px-2 py-2" }, { children: "".concat(userProfile.isOwner ? t('Владелец') : t('Администратор')) }), void 0))] }), void 0)] }), void 0), _jsx("div", __assign({ className: "hidden sm:flex flex-col" }, { children: getUserInfo() }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex sm:hidden pt-8 flex-col" }, { children: getUserInfo() }), void 0)] }), void 0), !isMy && (_jsx(ClubParticipantMoreMenu, { handleExpelUser: handleExpelUser, userId: userProfile.userId, currentUserRole: currentUserRole, handleChangeIsAdminRole: handleChangeIsAdminRole, handleChangeIsOwnerRole: handleChangeIsOwnerRole, isOwner: userProfile.isOwner, isAdmin: userProfile.isAdmin }, void 0))] }), void 0));
};
export default ClubParticipantsSearchResultListItem;
