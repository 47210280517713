export var DialogTypes;
(function (DialogTypes) {
    DialogTypes["AddTrainingSource"] = "ADD_TRAINING_SOURCE";
    DialogTypes["AddTrainingSourceSuccessInfo"] = "ADD_TRAINING_SOURCE_SUCCESS_INFO";
    DialogTypes["AddClubParticipantRole"] = "ADD_CLUB_PARTICIPANT_ROLE";
    DialogTypes["ActivityValidationChangeLevelDialog"] = "ACTIVITY_VALIDATION_CHANGE_LEVEL_DIALOG";
    DialogTypes["AddActivities"] = "ADD_ACTIVITIES";
    DialogTypes["AddActivityAttachments"] = "ADD_ACTIVITY_ATTACHMENTS";
    DialogTypes["AddStandingsColumn"] = "ADD_STANDINGS_COLUMN";
    DialogTypes["EditStandingsColumn"] = "EDIT_STANDINGS_COLUMN";
    DialogTypes["ActivityDetails"] = "ACTIVITY_DETAILS";
    DialogTypes["CreateSupportRequest"] = "CREATE_SUPPORT_REQUEST";
    DialogTypes["DeleteUserAddressRequest"] = "DELETE_USER_ADDRESS_REQUEST_DIALOG";
    DialogTypes["UpdateActivity"] = "UPDATE_ACTIVITY";
    DialogTypes["DeleteActivity"] = "DELETE_ACTIVITY";
    DialogTypes["UpdateActivityDuplicates"] = "UPDATE_ACTIVITY_DUPLICATES";
    DialogTypes["RequestActivityValidation"] = "REQUEST_ACTIVITY_VALIDATION";
    DialogTypes["ConfirmActivityValidation"] = "CONFIRM_ACTIVITY_VALIDATION";
    DialogTypes["ChangeUserLanguageDialog"] = "CHANGE_USER_LANGUAGE_DIALOG";
    DialogTypes["CreateClub"] = "CREATE_CLUB";
    DialogTypes["CreateSportEvent"] = "CREATE_SPORT_EVENT";
    DialogTypes["CreateSportEventTournament"] = "CREATE_SPORT_EVENT_TOURNAMENT";
    DialogTypes["CreateTournamentStandings"] = "CREATE_TOURNAMENT_STANDINGS";
    DialogTypes["CreateTournamentFeature"] = "CREATE_TOURNAMENT_FEATURE";
    DialogTypes["CreateStandingsSport"] = "CREATE_STANDINGS_SPORT";
    DialogTypes["DeleteStandingsSportRequest"] = "DELETE_STANDINGS_SPORT_REQUEST";
    DialogTypes["TournamentFeatureVirtualPoints"] = "TOURNAMENT_FEATURE_VIRTUAL_POINTS";
    DialogTypes["CreateTournamentTeam"] = "CREATE_TOURNAMENT_TEAM";
    DialogTypes["CreateSportClubSocialLink"] = "CREATE_SPORT_CLUB_SOCIAL_LINK";
    DialogTypes["CreateTeamContact"] = "CREATE_TEAM_CONTACT";
    DialogTypes["DeleteSportClubSocialLink"] = "DELETE_SPORT_CLUB_SOCIAL_LINK";
    DialogTypes["DeleteTeamContact"] = "DELETE_TEAM_CONTACT";
    DialogTypes["FillUserProfile"] = "FILL_USER_PROFILE";
    DialogTypes["VKJoinAccountRequest"] = "VK_JOIN_ACCOUNT_REQUEST";
    DialogTypes["YandexJoinAccountRequest"] = "YANDEX_JOIN_ACCOUNT_REQUEST";
    DialogTypes["ImageZoomDialog"] = "IMAGE_ZOOM_DIALOG";
    DialogTypes["JoinClubRequestDialog"] = "CLUB_JOIN_REQUEST";
    DialogTypes["JoinClubApplyForMememberShipRequestDialog"] = "JOIN_CLUB_APPLY_FOR_MEMBERSHIP_REQUEST";
    DialogTypes["LeaveClubRequestDialog"] = "CLUB_LEAVE_REQUEST";
    DialogTypes["RefreshInvitationLinkRequestDialog"] = "REFRESH_INVITATION_LINK_REQUEST";
    DialogTypes["SportClubDetailsDialog"] = "SPORT_CLUB_DETAILS_DIALOG";
    DialogTypes["SportEventDetailsDialog"] = "SPORT_EVENT_DETAILS_DIALOG";
    DialogTypes["StandingsDetails"] = "STANDINGS_DETAILS_DIALOG";
    DialogTypes["SportEventOwnersDetailsDialog"] = "SPORT_EVENT_OWNERS_DETAILS_DIALOG";
    DialogTypes["SportEventUsersDialog"] = "SPORT_EVENT_USERS_DIALOG";
    DialogTypes["TournamentNominationsDialog"] = "TOURNAMENT_NOMINATIONS_DIALOG";
    DialogTypes["TeamTournamentFeatureConfiguration"] = "TEAM_TOURNAMENT_FEATURE_CONFIGURATION";
    DialogTypes["TournamentTeamsDialog"] = "TOURNAMENT_TEAMS_DIALOG";
    DialogTypes["TournamentTeamUsersDialog"] = "TOURNAMENT_TEAM_USERS_DIALOG";
    DialogTypes["ExpelUserRequestDialog"] = "EXPEL_USER_REQUEST";
    DialogTypes["JoinClubByInvitationLinkRequestDialog"] = "JOIN_CLUB_BY_INVITATION_LINK_REQUEST";
    DialogTypes["JoinTeamRequestDialog"] = "JOIN_TEAM_REQUEST";
    DialogTypes["VirtualPointsBuyTeamCoins"] = "VIRTUAL_POINTS_BUY_TEAM_COINS";
    DialogTypes["VirtualPointsBuyBoosterRequest"] = "VIRTUAL_POINTS_BUY_BOOSTER_REQUEST";
    DialogTypes["VirtualPointsBuyBoosters"] = "VIRTUAL_POINTS_BUY_BOOSTERS";
    DialogTypes["UserProfileDetailsDialog"] = "USER_PROFILE_DETAILS_DIALOG";
    DialogTypes["UserActivitiesSourceError"] = "USER_ACTIVITIES_SOURCE_ERROR";
    DialogTypes["UserNotTeamMemberAlertDialog"] = "USER_NOT_TEAM_MEMBER_ALERT_DIALOG";
    DialogTypes["UserTournamentsAndRewardsDialog"] = "USER_TOURNAMENTS_AND_REWARDS_DIALOG";
    DialogTypes["UserMessageDialog"] = "USER_MESSAGE_DIALOG";
    DialogTypes["ProfilePhotoCropDialog"] = "PROFILE_PHOTO_CROP_DIALOG";
})(DialogTypes || (DialogTypes = {}));
