var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@mui/material';
import GoogleFitIcon from 'assets/logos/GoogleFitIcon';
import PolarIcon from 'assets/logos/PolarIcon';
import StravaIcon from 'assets/logos/StravaIcon';
import { ActivitySources } from 'common/enums/activity/activity-sources.enum';
import { useTranslation } from 'react-i18next';
var ActivitySourceLinkButton = function (_a) {
    var name = _a.name, sourceActivityId = _a.sourceActivityId;
    var stravaHref = "https://www.strava.com/activities/".concat(sourceActivityId);
    var polarHref = "https://flow.polar.com/diary";
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [name === ActivitySources.STRAVA && (_jsx(IconButton, __assign({ href: stravaHref, target: "_blank" }, { children: _jsx(StravaIcon, {}, void 0) }), void 0)), name === ActivitySources.POLAR && (_jsx(IconButton, __assign({ href: polarHref, target: "_blank" }, { children: _jsx(PolarIcon, {}, void 0) }), void 0)), name === ActivitySources.GPX && _jsx(_Fragment, { children: "GPX" }, void 0), name === ActivitySources.GOOGLE_FIT && _jsx(GoogleFitIcon, {}, void 0), name === ActivitySources.MANUAL && _jsx(_Fragment, { children: t('Создана вручную') }, void 0)] }, void 0));
};
export default ActivitySourceLinkButton;
