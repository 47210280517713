import { Sports } from 'common/enums/sport/sports.enum';
import * as Yup from 'yup';
var createActivityValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(1, 'createActivityValidationSchema_name_min')
        .max(256, 'createActivityValidationSchema_name_max')
        .required('createActivityValidationSchema_name_required'),
    description: Yup.string().trim().min(0).max(256, 'createActivityValidationSchema_description_max').nullable(),
    distance: Yup.number()
        .typeError('createActivityValidationSchema_distance_type')
        .min(10, 'createActivityValidationSchema_distance_min')
        .max(1000000, 'createActivityValidationSchema_distance_max')
        .required('createActivityValidationSchema_distance_required'),
    startDate: Yup.date()
        .typeError('createActivityValidationSchema_startDate_type')
        .max(new Date())
        .required('createActivityValidationSchema_startDate_required'),
    elapsedTime: Yup.number()
        .typeError('createActivityValidationSchema_elapsedTime_type')
        .min(10, 'createActivityValidationSchema_elapsedTime_min')
        .max(1000000, 'createActivityValidationSchema_elapsedTime_max')
        .required('createActivityValidationSchema_elapsedTime_required'),
    averageCadence: Yup.number()
        .typeError('createActivityValidationSchema_averageCadence_type')
        .min(0)
        .max(200, 'createActivityValidationSchema_averageCadence_max')
        .nullable(),
    maxSpeed: Yup.number()
        .typeError('createActivityValidationSchema_maxSpeed_type')
        .min(0)
        .max(30, 'createActivityValidationSchema_maxSpeed_max')
        .nullable(),
    avgHeartRate: Yup.number()
        .typeError('createActivityValidationSchema_avgHeartRate_type')
        .min(0)
        .max(200, 'createActivityValidationSchema_avgHeartRate_max')
        .nullable(),
    elevation: Yup.number()
        .typeError('createActivityValidationSchema_elevation_type')
        .min(0)
        .max(3000, 'createActivityValidationSchema_elevation_max')
        .nullable(),
    sport: Yup.mixed().oneOf(Object.values(Sports)).required('createActivityValidationSchema_sport_required')
});
export default createActivityValidationSchema;
