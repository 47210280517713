import backgroundImg from 'assets/images/impulse_main_img.jpg';
import React from 'react';
export var privacyPolicyPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    //backgroundColor: `#262626`,
                    backgroundImage: "url(".concat(backgroundImg, ")"),
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: { xs: '20%', md: '20%', lg: 'center center' }
                },
                navbar: {
                    display: false
                },
                toolbar: {
                    display: true,
                    displayLogo: true,
                    style: '',
                    transparent: true
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/privacy',
            component: React.lazy(function () { return import('./PrivacyPolicyPage'); })
        },
        {
            path: '/privacy-en',
            component: React.lazy(function () { return import('./PrivacyPolicyPageEng'); })
        },
        {
            path: '/terms',
            component: React.lazy(function () { return import('./TermsPage'); })
        },
        {
            path: '/terms-en',
            component: React.lazy(function () { return import('./TermsPageEng'); })
        },
    ]
};
