var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { DialogActions } from '@mui/material';
import { USER } from 'common/graphql/queries/user/user';
import Button from 'components/Buttons/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { navigateToVar } from 'utils/apollo/reactivities/navigateToVar';
var AddTrainingSourceSuccessInfoDialog = function (_a) {
    var handleClose = _a.handleClose, userId = _a.userId;
    var t = useTranslation().t;
    var _b = useLazyQuery(USER, {
        fetchPolicy: 'network-only',
        variables: {
            id: userId,
            showSocialSettings: true
        }
    }), refetchUser = _b[0], userData = _b[1].data;
    useEffect(function () {
        handleClose();
        if (navigateTo) {
            window.location.href = navigateTo;
        }
    }, [userData]);
    var handleRefetchUserData = function () {
        refetchUser();
    };
    var navigateTo = useReactiveVar(navigateToVar).navigateTo;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 items-center" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Импорт тренировок') }), void 0), _jsxs("div", __assign({ className: "text-16 text-center pb-20 dark:text-white" }, { children: [t('importingWorkouts1'), " ", t('importingWorkouts2'), _jsx(Link, __assign({ to: "https://support.polar.com/export-training-sessions-flow" }, { children: t('importingWorkouts3') }), void 0), ' ', t('importingWorkouts4')] }), void 0), _jsx(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: _jsx(Button, __assign({ onClick: function () {
                        handleRefetchUserData();
                    }, type: "primary sm" }, { children: t('Все понятно') }), void 0) }), void 0)] }), void 0));
};
export default AddTrainingSourceSuccessInfoDialog;
