var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, TextField } from '@mui/material';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import DraftEditor from 'components/DraftEditor/DraftEditor';
import PasswordField from 'components/Fields/PasswordField';
import { enUS, ru } from 'date-fns/locale';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LocationSearchAutoComplete from './LocationSearchAutoComplete';
var EditAccordionSettings = function (props) {
    var settingsGroupParams = props.settingsGroupParams, data = props.data, handleChangeData = props.handleChangeData, exitEditMode = props.exitEditMode, errorData = props.errorData;
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    useEffect(function () {
        if (errorData === null || errorData === void 0 ? void 0 : errorData.errorField) {
            formik.setFieldError(errorData.errorField, t(errorData.code));
        }
    }, [errorData]);
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var handleSubmitWrapper = function (e) {
        if (e && Object.keys(e).includes('location') && e.location)
            e = {
                id: e.id,
                location: {
                    id: e.location.id
                }
            };
        handleChangeData(e);
        if (errorData === null || errorData === void 0 ? void 0 : errorData.errorField) {
            formik.setFieldError(errorData.errorField, t(errorData.code));
        }
    };
    var formik = useFormik({
        initialValues: __assign({}, data),
        validationSchema: userValidationSchema.pick(Object.keys(data)),
        onReset: exitEditMode,
        onSubmit: handleSubmitWrapper,
        validateOnChange: false,
        validateOnBlur: false
    });
    var renderParams = function (_a) {
        var value = _a.value, id = _a.id, type = _a.type, textFieldIsMultiline = _a.textFieldIsMultiline, required = _a.required, title = _a.title;
        var fieldValue = formik.values[value];
        var fieldError = formik.errors[value];
        switch (type) {
            case 'textField': {
                return (_jsx(TextField, { id: id, required: required, label: t(title), name: value, value: fieldValue, onChange: handleChangeWrapper, error: Boolean(fieldError), helperText: fieldError, multiline: textFieldIsMultiline || false }, void 0));
            }
            case 'textEditor': {
                return (_jsx(DraftEditor, { value: fieldValue, onChange: function (data) {
                        formik.setFieldError(value, undefined);
                        formik.setFieldValue(value, data);
                    }, required: required, name: value, error: Boolean(fieldError), helperText: fieldError }, void 0));
            }
            case 'location': {
                return (_jsx(LocationSearchAutoComplete, { onChange: function (_event, evalue) {
                        if (evalue !== undefined) {
                            formik.setFieldValue(value, evalue
                                ? {
                                    id: evalue.value
                                }
                                : null);
                        }
                    } }, void 0));
            }
            case 'dateField': {
                return (_jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, locale: i18n.language === 'en' ? enUS : ru }, { children: _jsx(DatePicker, { value: fieldValue ? new Date(fieldValue) : fieldValue, minDate: new Date('1921-01-01'), onChange: function (date) {
                            formik.setFieldError(value, undefined);
                            formik.setFieldValue(value, date);
                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(fieldError), helperText: fieldError }), void 0)); } }, void 0) }), void 0));
            }
            case 'passwordField': {
                return (_jsx(PasswordField, { id: id, title: title, value: fieldValue, name: value, onChange: handleChangeWrapper, isError: Boolean(fieldError), helperText: fieldError }, void 0));
            }
        }
    };
    return (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: data && (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", { children: [settingsGroupParams.values.length > 0 &&
                        settingsGroupParams.values.map(function (value) { return (_jsx("div", { children: renderParams(value) }, value.id)); }), _jsxs(Box, __assign({ sx: { mt: '0.8rem' } }, { children: [_jsx(Button, __assign({ sx: { mr: '1.2rem' }, type: "reset", color: "secondary" }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)] }, void 0) }), void 0)) }), void 0));
};
export default EditAccordionSettings;
