var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { CONSTANCES } from 'common/constances';
import { StandingsParentType } from 'common/enums/standings/standings-parent-type.enum';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import { STANDINGS_COLUMNS } from 'common/graphql/queries/standings/standingsColumns';
import { STANDINGS_DATA } from 'common/graphql/queries/standings/standingsData';
import { USER_TOURNAMENT_TEAM_ID } from 'common/graphql/queries/team/user-tournament-team_id';
import { USER } from 'common/graphql/queries/user/user';
import BasicTable from 'components/BasicTable/BasicTable';
import BasicTableHelper from 'components/BasicTable/BasicTableHelper';
import Loading from 'components/Loading/Loading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import history from 'store/history';
import { standingsPageVar } from 'utils/apollo/reactivities/standingsPageVar';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
import HelperFunctions from 'utils/HelperFunctions';
var StandingsTable = function (_a) {
    var _b, _c, _d;
    var standings = _a.standings, onEmptyMessage = _a.onEmptyMessage, filterByRange = _a.filterByRange, filterByParticipantName = _a.filterByParticipantName;
    var _e = useTranslation(), t = _e.t, i18n = _e.i18n;
    var history = useHistory();
    var standingsParticipantType = standings.participantType;
    var helpers = new HelperFunctions();
    var _f = useState(), redirectToUserProfile = _f[0], setRedirectToUserProfile = _f[1];
    var _g = useLazyQuery(USER), getUser = _g[0], user = _g[1].data;
    var currentUserProfile = useReactiveVar(userProfileDataVar).userProfile;
    var currentUserId = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id;
    var currentUserName = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.userName;
    var currentUserClubId = (_b = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.sportClub) === null || _b === void 0 ? void 0 : _b.id;
    var _h = useReactiveVar(standingsPageVar), showUserDataRowInStatsTableButtonPressed = _h.showUserDataRowInStatsTableButtonPressed, showStandingsPageButtonPressed = _h.showStandingsPageButtonPressed;
    var _j = useState([]), columns = _j[0], setColumns = _j[1];
    var _k = useState([]), rows = _k[0], setRows = _k[1];
    var _l = useState([]), filteredRows = _l[0], setFilteredRows = _l[1];
    var convertStandingsColumnsToBasic = BasicTableHelper.convertStandingsColumnsToBasic;
    var standingsColumnsList = (_c = useQuery(STANDINGS_COLUMNS, {
        variables: { id: standings.id }
    }).data) === null || _c === void 0 ? void 0 : _c.standingsColumns;
    var _m = useLazyQuery(STANDINGS_DATA), getStandingsDataList = _m[0], _o = _m[1], data = _o.data, loading = _o.loading;
    var _p = useLazyQuery(USER_TOURNAMENT_TEAM_ID), getUserTournamentTeam = _p[0], userTournamentTeamData = _p[1].data;
    var onStandingsSelect = function (stats, cellId) {
        var _a, _b;
        var displayNameColumnId = standings.participantType === StandingsParticipantType.User
            ? '0a573c78-2172-449e-af6b-624787c74227'
            : standings.participantType === StandingsParticipantType.SportClub
                ? '062846f9-13ac-4c6e-b7a6-ec23a29d239f'
                : standings.participantType === StandingsParticipantType.Team
                    ? 'd931b295-0de9-4745-b836-5a615f8e548e'
                    : '';
        var participantDisplayNameId = ((_a = standingsColumnsList === null || standingsColumnsList === void 0 ? void 0 : standingsColumnsList.find(function (_a) {
            var columnId = _a.columnId;
            return columnId === displayNameColumnId;
        })) === null || _a === void 0 ? void 0 : _a.id) || '';
        var userTeamColumnId = ((_b = standingsColumnsList === null || standingsColumnsList === void 0 ? void 0 : standingsColumnsList.find(function (_a) {
            var columnId = _a.columnId;
            return columnId === '840a5ef2-f334-48b0-8dab-7cb5114f4a73';
        })) === null || _b === void 0 ? void 0 : _b.id) || '';
        if (cellId != 'collapseIcon' &&
            cellId != 'source' &&
            cellId != participantDisplayNameId &&
            cellId != userTeamColumnId) {
            history.push("/standings-participant/".concat(standings.id, "/").concat(stats._id));
        }
        else if (cellId === participantDisplayNameId) {
            if (standings.participantType === StandingsParticipantType.User) {
                setRedirectToUserProfile({ isRedirect: true, userId: stats._id });
                //history.push(`/profile/${stats._id}`);
            }
            if (standings.participantType === StandingsParticipantType.SportClub) {
                history.push("/club/".concat(stats.SportClubName));
            }
            if (standings.participantType === StandingsParticipantType.Team) {
                history.push("/team/".concat(stats.TeamName));
            }
        }
    };
    useEffect(function () {
        var _a;
        if (standings.id && filterByRange) {
            var variables = helpers.getStandingsVariablesFromFilter(filterByRange, standings.id);
            if (variables) {
                getStandingsDataList({
                    fetchPolicy: 'network-only',
                    variables: variables
                });
            }
            if (standings.parentType === StandingsParentType.Tournament &&
                standings.participantType === StandingsParticipantType.Team &&
                ((_a = standings.tournament) === null || _a === void 0 ? void 0 : _a.id)) {
                getUserTournamentTeam({
                    variables: {
                        tournamentId: standings.tournament.id,
                        userId: currentUserId
                    }
                });
            }
        }
    }, [standings.id, filterByRange]);
    useEffect(function () {
        if (standingsColumnsList === null || standingsColumnsList === void 0 ? void 0 : standingsColumnsList.length) {
            setColumns(convertStandingsColumnsToBasic(standingsColumnsList, i18n.language, true));
        }
    }, [standingsColumnsList]);
    useEffect(function () {
        if (redirectToUserProfile && redirectToUserProfile.isRedirect && redirectToUserProfile.userId) {
            getUser({
                variables: {
                    id: redirectToUserProfile.userId
                }
            });
        }
    }, [redirectToUserProfile]);
    useEffect(function () {
        if ((user === null || user === void 0 ? void 0 : user.user) && (redirectToUserProfile === null || redirectToUserProfile === void 0 ? void 0 : redirectToUserProfile.isRedirect)) {
            history.push("/profile/".concat(user.user.userName));
        }
    }, [user]);
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.standingsData) {
            setRows(data.standingsData);
        }
    }, [data]);
    useEffect(function () {
        if ((data === null || data === void 0 ? void 0 : data.standingsData) && (standingsColumnsList === null || standingsColumnsList === void 0 ? void 0 : standingsColumnsList.length)) {
            var standingsUserData = data.standingsData.find(function (_a) {
                var UserName = _a.UserName;
                return UserName === currentUserName;
            });
            //если  пользователь при регистрации/логине
            //по каким-то причинам не попал в турнирную талицу, то StatsInfoForm не будет отображаться
            if (standingsUserData) {
                var distanceColumnId_1 = '6f4f7486-ebd1-47c7-916b-17b15b33760a';
                var activityNumberColumnId_1 = '487168c5-0070-453c-bd09-a846a282c1c5';
                var distance = standingsColumnsList.find(function (_a) {
                    var columnId = _a.columnId;
                    return columnId === distanceColumnId_1;
                });
                var activityNumber = standingsColumnsList.find(function (_a) {
                    var columnId = _a.columnId;
                    return columnId === activityNumberColumnId_1;
                });
                standingsPageVar(__assign(__assign({}, standingsPageVar()), { standingsUserInfoData: {
                        distance: (distance === null || distance === void 0 ? void 0 : distance.id) && standingsUserData[distance.id],
                        activityNumber: (activityNumber === null || activityNumber === void 0 ? void 0 : activityNumber.id) && standingsUserData[activityNumber.id],
                        rank: standingsUserData['rank']
                    } }));
            }
        }
    }, [data, standingsColumnsList]);
    useEffect(function () {
        var _a;
        //поиск по имени и фамилии
        if (rows.length) {
            var getColumnId_1 = function () {
                if (standingsParticipantType === StandingsParticipantType.SportClub)
                    return CONSTANCES.sportClubDisplayNameColumnId;
                if (standingsParticipantType === StandingsParticipantType.Team)
                    return CONSTANCES.teamDisplayNameColumnId;
                if (standingsParticipantType === StandingsParticipantType.User)
                    return CONSTANCES.userDisplayNameColumnId;
                return '';
            };
            var participantDisplayNameId_1 = ((_a = standingsColumnsList === null || standingsColumnsList === void 0 ? void 0 : standingsColumnsList.find(function (_a) {
                var columnId = _a.columnId;
                return columnId === getColumnId_1();
            })) === null || _a === void 0 ? void 0 : _a.id) || '';
            var participantNameFilter_1 = function (participantName) {
                var input = (filterByParticipantName === null || filterByParticipantName === void 0 ? void 0 : filterByParticipantName.toLowerCase().trim()) || '';
                return (participantName === null || participantName === void 0 ? void 0 : participantName.toLowerCase().includes(input)) || !participantName;
            };
            var filteredRows_1 = rows.filter(function (row) { return participantNameFilter_1(row[participantDisplayNameId_1]); }) || [];
            setFilteredRows(function () { return filteredRows_1; });
        }
        else {
            setFilteredRows(function () { return []; });
        }
    }, [rows, filterByParticipantName]);
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(_Fragment, { children: (data === null || data === void 0 ? void 0 : data.standingsData) && (_jsx(BasicTable, { onEmptyMessage: onEmptyMessage || 'Тренировки за данный промежуток времени не найдены', columns: columns, rows: filteredRows, highlightedRowConditionFunc: function (row) {
                var _a;
                return row['UserName'] === currentUserName ||
                    row['_id'] === currentUserClubId ||
                    row['_id'] === ((_a = userTournamentTeamData === null || userTournamentTeamData === void 0 ? void 0 : userTournamentTeamData.userTournamentTeam) === null || _a === void 0 ? void 0 : _a.id)
                    ? '[&>td]:font-bold'
                    : undefined;
            }, isShowHighlightedRow: showUserDataRowInStatsTableButtonPressed, isShowStandingsPages: showStandingsPageButtonPressed, setShowedHighlightedRow: function (isShowed) {
                standingsPageVar(__assign(__assign({}, standingsPageVar()), { showUserDataRowInStatsTableButtonPressed: isShowed }));
            }, setShowedStandingsPage: function (isShowed) {
                var _a;
                standingsPageVar(__assign(__assign({}, standingsPageVar()), { showStandingsPageButtonPressed: isShowed, showTargetRowId: standings.participantType === StandingsParticipantType.SportClub
                        ? currentUserClubId
                        : standings.participantType === StandingsParticipantType.Team
                            ? (_a = userTournamentTeamData === null || userTournamentTeamData === void 0 ? void 0 : userTournamentTeamData.userTournamentTeam) === null || _a === void 0 ? void 0 : _a.id
                            : currentUserId }));
            }, orderByAndOrderParam: { orderBy: 'startDateLocal', orderParam: 'desc' }, onRowClick: onStandingsSelect, unwrapRowsInMobile: (_d = standings.settings) === null || _d === void 0 ? void 0 : _d.unwrapRowsInMobile, participantType: standings.participantType }, void 0)) }, void 0));
};
export default StandingsTable;
