import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ActivityValidationsPage from './ActivityValidationsPage/ActivityValidationsPage';
import AdministrationPage from './AdministrationPage';
import NotificationManagementPage from './NotificationManagementPage/NotificationManagementPage';
import SupportRequestsAdminPage from './SupportRequestsAdminPage/SupportRequestsAdminPage';
export var administrationPagesConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.staff,
    routes: [
        {
            path: '/administration/activity-validation',
            component: function () { return (_jsx(AdministrationPage, { children: _jsx(ActivityValidationsPage, {}, void 0) }, void 0)); }
        },
        {
            path: '/administration/support-requests',
            component: function () { return (_jsx(AdministrationPage, { children: _jsx(SupportRequestsAdminPage, {}, void 0) }, void 0)); }
        },
        {
            path: '/administration/notification-management',
            component: function () { return (_jsx(AdministrationPage, { children: _jsx(NotificationManagementPage, {}, void 0) }, void 0)); }
        },
        {
            path: '/administration/preview-notification/:notificationInputId',
            component: React.lazy(function () { return import('./NotificationManagementPage/NotificationPreviewPage'); })
        },
        {
            path: '/administration',
            component: function () { return _jsx(Redirect, { to: "/administration/activity-validation" }, void 0); }
        },
        // {
        // 	path: ['/settings/connections'],
        // 	component: () => (
        // 		<AdministrationPage>
        // 			<Connections />
        // 		</AdministrationPage>
        // 	),
        // },
    ]
};
