var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_SPORT_EVENT } from 'common/graphql/mutations/sportEvent/updateSportEvent';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import sportEventSettingsValidationSchema from 'common/validationSchemas/sportEventSettingsValidationSchema';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var SportEventPrivacyAndVisibilitySettingsPage = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var sportEventId = _a.sportEventId;
    var t = useTranslation().t;
    var _h = useState(false), isEditMode = _h[0], setIsEditMode = _h[1];
    var _j = useQuery(SPORT_EVENT, {
        variables: {
            id: sportEventId,
            showVisibilitySettings: true,
            showPrivacySettings: true
        }
    }), data = _j.data, loading = _j.loading;
    var visible = (_c = (_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.visibilitySettings) === null || _c === void 0 ? void 0 : _c.visible;
    var isOpenEvent = (_e = (_d = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _d === void 0 ? void 0 : _d.privacy) === null || _e === void 0 ? void 0 : _e.isOpenEvent;
    var isPrivate = (_g = (_f = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _f === void 0 ? void 0 : _f.privacy) === null || _g === void 0 ? void 0 : _g.isPrivate;
    var defaultFormState = {
        visible: null,
        isOpenEvent: null,
        isPrivate: null
    };
    var updateSportEvent = function (data) {
        return useMutation(UPDATE_SPORT_EVENT, {
            variables: {
                updateSportEventData: {
                    id: sportEventId,
                    visibilitySettings: {
                        visible: data === null || data === void 0 ? void 0 : data.visible
                    },
                    privacy: {
                        isPrivate: data === null || data === void 0 ? void 0 : data.isPrivate,
                        isOpenEvent: data === null || data === void 0 ? void 0 : data.isOpenEvent
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_EVENT,
                    variables: {
                        id: sportEventId,
                        showVisibilitySettings: true,
                        showPrivacySettings: true
                    }
                },
            ]
        });
    };
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Событие обновлено.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateSportEvent, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
        setIsEditMode(false);
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: sportEventSettingsValidationSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    useEffect(function () {
        if (visible !== undefined && isOpenEvent !== undefined && isPrivate !== undefined) {
            formik.setFieldValue('visible', visible);
            formik.setFieldValue('isOpenEvent', isOpenEvent);
            formik.setFieldValue('isPrivate', isPrivate);
        }
    }, [data]);
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0441\u0442\u0438 \u0438 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: visible !== undefined && isOpenEvent !== undefined && isPrivate !== undefined && (_jsxs("form", __assign({ className: "flex flex-col max-w-320", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: isEditMode ? (_jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return setIsEditMode(false); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "button", onClick: function () { return setIsEditMode(!isEditMode); } }, { children: t('Редактировать') }), void 0)) }), void 0), _jsx("div", __assign({ className: "pt-20" }, { children: _jsxs(FormGroup, __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx(FormControlLabel, { disabled: !isEditMode, control: _jsx(Checkbox, { checked: formik.values['visible'] || false, onChange: function (e) {
                                            formik.setFieldError('visible', undefined);
                                            formik.setFieldValue('visible', e.target.checked);
                                        } }, void 0), label: "\u0421\u043E\u0431\u044B\u0442\u0438\u0435 \u0432\u0438\u0434\u043D\u043E \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F\u043C \u0432 \u0441\u043F\u0438\u0441\u043A\u0435 \u0441\u043E\u0431\u044B\u0442\u0438\u0439." }, void 0), _jsx(FormControlLabel, { disabled: !isEditMode, control: _jsx(Checkbox, { checked: formik.values['isOpenEvent'] || false, onChange: function (e) {
                                            formik.setFieldError('isOpenEvent', undefined);
                                            formik.setFieldValue('isOpenEvent', e.target.checked);
                                        } }, void 0), label: "\u0421\u043E\u0431\u044B\u0442\u0438\u0435 \u043E\u0442\u043A\u0440\u044B\u0442\u043E \u0434\u043B\u044F \u0432\u0441\u0435\u0445 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439. \u0415\u0441\u043B\u0438 \u0432\u044B\u043A\u043B\u044E\u0447\u0435\u043D\u043E, \u0442\u043E \u0432 \u0441\u043E\u0431\u044B\u0442\u0438\u0438 \u043C\u043E\u0433\u0443\u0442 \u0443\u0447\u0430\u0441\u0442\u0432\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u0447\u043B\u0435\u043D\u044B \u043A\u043B\u0443\u0431\u043E\u0432-\u0432\u043B\u0430\u0434\u0435\u043B\u044C\u0446\u0435\u0432 \u0441\u043E\u0431\u044B\u0442\u0438\u044F." }, void 0), _jsx(FormControlLabel, { disabled: !isEditMode, control: _jsx(Checkbox, { checked: formik.values['isPrivate'] || false, onChange: function (e) {
                                            formik.setFieldError('isPrivate', undefined);
                                            formik.setFieldValue('isPrivate', e.target.checked);
                                        } }, void 0), label: "\u0421\u043E\u0431\u044B\u0442\u0438\u0435 \u044F\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u044B\u043C. \u041D\u0438\u043A\u0442\u043E \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u0441\u0432\u043E\u0431\u043E\u0434\u043D\u043E \u0443\u0447\u0430\u0441\u0442\u0432\u043E\u0432\u0430\u0442\u044C \u0432 \u0441\u043E\u0431\u044B\u0442\u0438\u0438 (\u0442\u043E\u043B\u044C\u043A\u043E \u043F\u043E \u0437\u0430\u043F\u0440\u043E\u0441\u0443 \u0438\u043B\u0438 \u043F\u0440\u0438\u0432\u0430\u0442\u043D\u043E\u0439 \u0441\u0441\u044B\u043B\u043A\u0435)." }, void 0)] }), void 0) }), void 0)] }), void 0)) }), void 0), innerScroll: false }, void 0));
};
export default SportEventPrivacyAndVisibilitySettingsPage;
