import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import { useJoinSportEventMutation } from 'common/hooks/useJoinSportEventMutation';
import { useJoinTournamentMutation } from 'common/hooks/useJoinTournamentMutation';
import Loading from 'components/Loading/Loading';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import history from 'store/history';
export var JoinSportEvent = function (_a) {
    var sportEvent = _a.sportEvent;
    var dispatch = useDispatch();
    var sportEventId = sportEvent.id;
    var sportEventUrl = sportEvent.url;
    var tournamentId = sportEvent.tournaments[0].id;
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: "\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C! \u0412\u044B \u0441\u0442\u0430\u043B\u0438 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u043C \u0441\u043E\u0431\u044B\u0442\u0438\u044F.",
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var _b = useDynamicMutation(useJoinSportEventMutation), handleExecuteJoinSportEventMutation = _b.handleExecuteMutation, se_loading = _b.loading;
    var _c = useDynamicMutation(useJoinTournamentMutation, onSuccess, onError), handleExecuteJoinTournamentMutation = _c.handleExecuteMutation, t_loading = _c.loading;
    useEffect(function () {
        if (sportEvent.areYouParticipant) {
            sportEventAlreadyJoined(sportEventUrl);
        }
        else {
            handleExecuteJoinSportEventMutation({ sportEventId: sportEventId });
            handleExecuteJoinTournamentMutation({ tournamentId: tournamentId });
        }
    }, [sportEvent]);
    var sportEventAlreadyJoined = function (sportEventUrl) {
        history.push("/event/".concat(sportEventUrl));
        dispatch(openSnackbar({
            message: 'Вы уже участник события!',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    if (se_loading || t_loading)
        return _jsx(Loading, {}, void 0);
    return _jsx(_Fragment, {}, void 0);
};
