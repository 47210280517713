var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useReactiveVar } from '@apollo/client';
import { DateTimePicker } from '@mui/lab';
import { Alert, DialogActions, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_ACTIVITY } from 'common/graphql/mutations/activity/updateActivity/updateActivity';
import { FULL_ACTIVITY } from 'common/graphql/queries/activity/fullActivity';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import activityValidationSchema from 'common/validationSchemas/activityValidationSchema';
import Button from 'components/Buttons/Button';
import SportSelect from 'components/Select/SportSelect';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import { userProfileDataVar } from 'utils/apollo/reactivities/userProfileDataVar';
var UpdateActivityDialog = function (_a) {
    var _b, _c, _d;
    var activity = _a.activity, handleClose = _a.handleClose;
    var userProfile = useReactiveVar(userProfileDataVar).userProfile;
    var isAdmin = !!((_c = (_b = userProfile === null || userProfile === void 0 ? void 0 : userProfile.roles) === null || _b === void 0 ? void 0 : _b.map(function (role) { return role.name; })) === null || _c === void 0 ? void 0 : _c.filter(function (roleName) { return ['Admin', 'SuperAdmin'].includes(roleName); })[0]);
    var defaultFormState = {
        id: activity.id,
        name: activity.name,
        sport: (_d = activity.sport) === null || _d === void 0 ? void 0 : _d.name,
        startDate: activity.startDate
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var updateActivity = function (data) {
        return useMutation(UPDATE_ACTIVITY, {
            variables: {
                updateActivityData: isAdmin
                    ? __assign({}, data) : __assign(__assign({}, data), { startDate: undefined })
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FULL_ACTIVITY,
                    variables: {
                        id: activity.id
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateActivity, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign({}, data));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: activityValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsxs("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-10 p-0 pb-20 md:max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Изменить данные тренировки') }), void 0), _jsx(TextField, { className: "", label: t('Название'), name: "name", value: formik.values['name'], onChange: handleChangeWrapper, error: Boolean(formik.errors['name']), helperText: t(formik.errors['name'] || '') }, void 0), _jsx(Alert, __assign({ severity: "info" }, { children: t('changeSportTypeInfo') }), void 0), _jsx(SportSelect, { value: formik.values['sport'], handleChange: formik.setFieldValue, disableClearable: false, errorMessage: t(formik.errors['sport'] || ''), name: "sport" }, void 0), isAdmin && (_jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: t('Дата и время начала'), value: formik.values['startDate'] && new Date(formik.values['startDate']), maxDate: new Date(), onChange: function (date) {
                            formik.setFieldError('startDate', undefined);
                            formik.setFieldValue('startDate', date);
                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['startDate']), helperText: formik.errors['startDate'] || '' }), void 0)); } }, void 0))] }), void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Подтвердить') }), void 0)] }), void 0)] }), void 0));
};
export default UpdateActivityDialog;
