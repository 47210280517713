import { jsx as _jsx } from "react/jsx-runtime";
import authRoles from 'common/authRoles';
import { Redirect } from 'react-router-dom';
import Account from './account/Account';
import UserAddressSettings from './address/UserAddressSettings';
import Connections from './connections/Connections';
import UserContacts from './contacts/UserContacts';
import DisplayPreferences from './displayPreferences/displayPreferences';
import ProfilePrivacy from './profilePrivacy/ProfilePrivacy';
import SettingsPage from './SettingsPage';
export var settingsPageConfig = {
    settings: {
        layout: {
            config: {
                contentStyle: {
                    backgroundImage: ''
                },
                navbar: {
                    display: true
                },
                toolbar: {
                    display: true,
                    displayLogo: false,
                    transparent: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: ['/settings/account'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(Account, {}, void 0) }, void 0)); }
        },
        {
            path: ['/settings/connections'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(Connections, {}, void 0) }, void 0)); }
        },
        {
            path: ['/settings/profilePrivacy'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(ProfilePrivacy, {}, void 0) }, void 0)); }
        },
        {
            path: ['/settings/displayPreferences'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(DisplayPreferences, {}, void 0) }, void 0)); }
        },
        {
            path: ['/settings/address'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(UserAddressSettings, {}, void 0) }, void 0)); }
        },
        {
            path: ['/settings/contacts'],
            component: function () { return (_jsx(SettingsPage, { children: _jsx(UserContacts, {}, void 0) }, void 0)); }
        },
        {
            path: '/settings',
            component: function () { return _jsx(Redirect, { to: "/settings/account" }, void 0); }
        },
    ]
};
