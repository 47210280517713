var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactiveVar } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { standingsPageVar } from 'utils/apollo/reactivities/standingsPageVar';
var BasicTableRow = function (_a) {
    var index = _a.index, visibleColumns = _a.visibleColumns, collapsedColumns = _a.collapsedColumns, row = _a.row, highlightedRowConditionFunc = _a.highlightedRowConditionFunc, page = _a.page, rowsPerPage = _a.rowsPerPage, onRowClick = _a.onRowClick, setRowHeight = _a.setRowHeight, rowHeight = _a.rowHeight, _b = _a.isAlignRowHeight, isAlignRowHeight = _b === void 0 ? false : _b, _c = _a.unwrapRowsInMobile, unwrapRowsInMobile = _c === void 0 ? false : _c, isShowHighlightedRow = _a.isShowHighlightedRow, isShowStandingsPages = _a.isShowStandingsPages, setShowedHighlightedRow = _a.setShowedHighlightedRow, participantType = _a.participantType;
    var t = useTranslation().t;
    var _d = useState(unwrapRowsInMobile), open = _d[0], setOpen = _d[1];
    var targetRowId = useReactiveVar(standingsPageVar).showTargetRowId;
    var ref = useRef(null);
    var tableCellValue = function (column, index, data, value, rowMeta) {
        switch (column.id) {
            case 'rowNumber': {
                var rowNumber = rowsPerPage * page + index + 1;
                return rowNumber;
            }
            case 'collapseIcon': {
                return (_jsx(IconButton, __assign({ "aria-label": "expand row", size: "small", onClick: function (e) {
                        e.stopPropagation();
                        setOpen(!open);
                    } }, { children: open ? _jsx(KeyboardArrowUpIcon, {}, void 0) : _jsx(KeyboardArrowDownIcon, {}, void 0) }), void 0));
            }
            default: {
                return column.format ? column.format(value, rowMeta) : value;
            }
        }
    };
    var resizeCallback = useCallback(function (node) {
        if (isAlignRowHeight) {
            setRowHeight(0);
        }
        // let current: any = ref?.current;
        // let offsetHeight = current?.offsetHeight;
        // if (row && offsetHeight && ref !== null) {
        // 	// let current: any = ref?.current;
        // 	// let offsetHeight = current?.offsetHeight;
        // 	//const height = current?.getBoundingClientRect()?.height;
        // 	if (0 < offsetHeight) {
        // 		setRowHeight(offsetHeight);
        // 	}
        // }
    }, []);
    useEffect(function () {
        if (isAlignRowHeight) {
            if (!ref.current) {
                return;
            }
            var resizeObserver_1 = new ResizeObserver(resizeCallback);
            resizeObserver_1.observe(ref === null || ref === void 0 ? void 0 : ref.current);
            return function () {
                resizeObserver_1.disconnect();
            };
        }
    }, [ref === null || ref === void 0 ? void 0 : ref.current]);
    useEffect(function () {
        var _a;
        if (isShowHighlightedRow && setShowedHighlightedRow && targetRowId && row._id === targetRowId) {
            (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'instant' });
            setShowedHighlightedRow(!isShowHighlightedRow);
        }
    }, [isShowHighlightedRow, isShowStandingsPages]);
    var handleResize = function () {
        var current = ref === null || ref === void 0 ? void 0 : ref.current;
        var offsetHeight = current === null || current === void 0 ? void 0 : current.offsetHeight;
        if (row && visibleColumns && visibleColumns.length > 0 && ref !== null) {
            // let current: any = ref?.current;
            // let offsetHeight = current?.offsetHeight;
            //const height = current?.getBoundingClientRect()?.height;
            if (rowHeight < offsetHeight) {
                setRowHeight(offsetHeight);
            }
        }
    };
    useLayoutEffect(function () {
        if (isAlignRowHeight) {
            handleResize();
        }
    }, [visibleColumns, rowHeight]);
    // useEffect(() => {
    // 	window.addEventListener('resize', handleResize);
    // 	return () => {
    // 		window.removeEventListener('resize', handleResize);
    // 	};
    // }, []);
    // useEffect(() => {
    // 	let current: any = ref?.current;
    // 		current.addEventListener('change', handleResize);
    // 	return () => {
    // 		current.removeEventListener('change', handleResize);
    // 	};
    // }, []);
    var renderCell = function (row, column, index, collapsed) {
        var data = row[column.data];
        var value = {};
        if (_.isArray(column.value)) {
            column.value.map(function (key) { return (value[key] = _.get(row, key)); });
        }
        else {
            value = _.get(row, column.value);
        }
        var rowMeta = {
            _id: row._id,
            UserName: row['UserName'],
            TeamName: row['TeamName'],
            SportClubName: row['SportClubName']
        };
        return (_jsx(Fragment, { children: !collapsed ? (_jsx(TableCell, __assign({ className: clsx('cursor-pointer  xs:px-2 xs:py-8 md:p-12 border-none ', column.style || ' text-center'), onClick: function () {
                    onRowClick && onRowClick(row);
                } }, { children: column.tooltipTitle ? (_jsx(Tooltip, __assign({ title: column.tooltipTitle(value) }, { children: tableCellValue(column, index, data, value, rowMeta) }), column.id)) : (
                // <div key={column.id} className={clsx(column.cellStyle || "flex justify-center")}>
                _jsx(_Fragment, { children: tableCellValue(column, index, data, value, rowMeta) }, void 0)
                // </div>
                ) }), column.id)) : (_jsx("div", { children: tableCellValue(column, index, data, value, rowMeta) }, void 0)) }, column.id));
    };
    return (_jsxs(Fragment, { children: [_jsx(TableRow
            //ref={rootRef}
            , __assign({ 
                //ref={rootRef}
                ref: ref, sx: { height: rowHeight && rowHeight }, className: highlightedRowConditionFunc && highlightedRowConditionFunc(row), hover: true, tabIndex: -1 }, { children: visibleColumns.map(function (column) { return renderCell(row, column, index); }) }), void 0), open && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ style: { paddingBottom: 0, paddingTop: 0 }, colSpan: visibleColumns.length }, { children: _jsx(Collapse, __assign({ className: "pb-8", "in": open, timeout: "auto", unmountOnExit: true }, { children: collapsedColumns.map(function (column) { return (_jsxs("div", __assign({ className: "flex py-6 items-center" }, { children: [_jsxs(Box, __assign({ className: "flex-1  pl-24 md:max-w-200" }, { children: [t(column.label), ":"] }), void 0), _jsx("div", __assign({ className: "flex-1  md:max-w-200" }, { children: renderCell(row, column, index, true) }), void 0)] }), column.id)); }) }), void 0) }), void 0) }, void 0))] }, index));
};
export default BasicTableRow;
