var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
//import { Button } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var UploadFilesButton = function (_a) {
    var setLoadingFiles = _a.setLoadingFiles, accept = _a.accept, multiple = _a.multiple;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var onUploadMessage = function (_a) {
        var code = _a.code, severity = _a.severity;
        dispatch(openSnackbar({
            message: code,
            severity: severity || SnackbarSeverity.ERROR
        }));
    };
    var handleCapture = function (event) {
        var files = event.target.files;
        if (files.length) {
            var errorFiles_1 = [];
            Array.from(files).forEach(function (file) {
                if (file.size > 50000000) {
                    errorFiles_1.push(file.name);
                    return;
                }
            });
            if (errorFiles_1.length) {
                onUploadMessage({
                    code: t("\u0420\u0430\u0437\u043C\u0435\u0440 \u0444\u0430\u0439\u043B\u0430 \u043D\u0435 \u0434\u043E\u043B\u0436\u0435\u043D \u043F\u0440\u0435\u0432\u044B\u0448\u0430\u0442\u044C") + " 50Mb"
                });
            }
            else {
                setLoadingFiles(files);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { accept: accept, className: "hidden", id: "button-file", multiple: multiple, type: "file", name: "upload", onChange: handleCapture }, void 0), _jsx("label", __assign({ htmlFor: "button-file" }, { children: _jsx("span", __assign({ className: "tw-btn upload-file cursor-pointer" }, { children: multiple ? t('Выбрать файлы') : t('Выбрать файл') }), void 0) }), void 0)] }, void 0));
};
export default UploadFilesButton;
