var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQuery } from '@apollo/client';
import { DateTimePicker } from '@mui/lab';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_SPORT_EVENT } from 'common/graphql/mutations/sportEvent/updateSportEvent';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import sportEventSettingsValidationSchema from 'common/validationSchemas/sportEventSettingsValidationSchema';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var SportEventDatesSettingsPage = function (_a) {
    var _b;
    var sportEventId = _a.sportEventId;
    var t = useTranslation().t;
    var _c = useState(false), isEditMode = _c[0], setIsEditMode = _c[1];
    var _d = useQuery(SPORT_EVENT, {
        variables: {
            id: sportEventId,
            showDatesSettings: true
        }
    }), data = _d.data, loading = _d.loading;
    var datesSettings = (_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.datesSettings;
    var startDate = datesSettings === null || datesSettings === void 0 ? void 0 : datesSettings.startDate;
    var endDate = datesSettings === null || datesSettings === void 0 ? void 0 : datesSettings.endDate;
    var registrationStartDate = datesSettings === null || datesSettings === void 0 ? void 0 : datesSettings.registrationStartDate;
    var registrationEndDate = datesSettings === null || datesSettings === void 0 ? void 0 : datesSettings.registrationEndDate;
    var defaultFormState = {
        startDate: null,
        endDate: null,
        registrationStartDate: null,
        registrationEndDate: null
    };
    var updateSportEvent = function (data) {
        return useMutation(UPDATE_SPORT_EVENT, {
            variables: {
                updateSportEventData: {
                    id: sportEventId,
                    datesSettings: __assign({}, data)
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_EVENT,
                    variables: {
                        id: sportEventId,
                        showDatesSettings: true
                    }
                },
            ]
        });
    };
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Событие обновлено.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
        setDefaultValues();
    };
    var handleExecuteMutation = useDynamicMutation(updateSportEvent, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
        setIsEditMode(false);
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: sportEventSettingsValidationSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var setDefaultValues = function () {
        if (startDate && endDate && registrationStartDate && registrationEndDate) {
            formik.setFieldValue('startDate', new Date(startDate));
            formik.setFieldValue('endDate', new Date(endDate));
            formik.setFieldValue('registrationStartDate', new Date(registrationStartDate));
            formik.setFieldValue('registrationEndDate', new Date(registrationEndDate));
        }
    };
    var handleReset = function () {
        setDefaultValues();
        setIsEditMode(false);
    };
    useEffect(function () {
        setDefaultValues();
    }, [data]);
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0434\u0430\u0442 \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0438\u044F" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: startDate && endDate && registrationStartDate && registrationEndDate && (_jsxs("form", __assign({ className: "flex flex-col max-w-320", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: isEditMode ? (_jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "button", onClick: function () { return setIsEditMode(!isEditMode); } }, { children: t('Редактировать') }), void 0)) }), void 0), _jsxs("div", __assign({ className: "pt-20" }, { children: [_jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430", readOnly: !isEditMode || startDate < new Date(), value: formik.values['startDate'] ? new Date(formik.values['startDate']) : null, minDate: new Date(), onChange: function (date) {
                                    formik.setFieldError('startDate', undefined);
                                    formik.setFieldValue('startDate', date);
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['startDate']), helperText: formik.errors['startDate'] }), void 0)); } }, void 0), _jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F", readOnly: !isEditMode || endDate < new Date(), value: formik.values['endDate'] ? new Date(formik.values['endDate']) : null, minDate: new Date(), onChange: function (date) {
                                    formik.setFieldError('endDate', undefined);
                                    formik.setFieldValue('endDate', date);
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['endDate']), helperText: formik.errors['endDate'] }), void 0)); } }, void 0), _jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438", readOnly: !isEditMode || registrationStartDate < new Date(), value: formik.values['registrationStartDate']
                                    ? new Date(formik.values['registrationStartDate'])
                                    : null, minDate: new Date(new Date(startDate).getDate() - 365), maxDate: new Date(endDate), onChange: function (date) {
                                    formik.setFieldError('registrationStartDate', undefined);
                                    formik.setFieldValue('registrationStartDate', date);
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['registrationStartDate']), helperText: formik.errors['registrationStartDate'] }), void 0)); } }, void 0), _jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438", readOnly: !isEditMode || registrationEndDate < new Date(), value: formik.values['registrationEndDate']
                                    ? new Date(formik.values['registrationEndDate'])
                                    : null, minDate: new Date(startDate), maxDate: new Date(endDate), onChange: function (date) {
                                    formik.setFieldError('registrationEndDate', undefined);
                                    formik.setFieldValue('registrationEndDate', date);
                                }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { error: Boolean(formik.errors['registrationEndDate']), helperText: formik.errors['registrationEndDate'] }), void 0)); } }, void 0)] }), void 0)] }), void 0)) }), void 0), innerScroll: false }, void 0));
};
export default SportEventDatesSettingsPage;
