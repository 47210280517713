var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import DuckError from 'assets/images/duck_errror';
import history from 'store/history';
var ErrorPage = function (props) {
    var _a, _b;
    return (_jsxs("div", __assign({ className: "flex flex-col items-center basis-full justify-center p-8" }, { children: [_jsx(DuckError, {}, void 0), _jsxs(Typography, __assign({ sx: { fontSize: '1.4rem', fontWeight: 400, textAlign: 'center', paddingTop: '2rem' } }, { children: ["\u041A\u0440\u044F-\u043A\u0440\u044F. \u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A. \u0421\u043E\u043E\u0431\u0449\u0438\u0442\u0435 \u043D\u0430\u043C \u043E\u0431 \u043E\u0448\u0438\u0431\u043A\u0435 \u0432", ' ', _jsx("a", __assign({ href: "https://t.me/impulse_chat", target: "_blank" }, { children: "\u0447\u0430\u0442\u0435 Impulse" }), void 0), ' ', "\u0438 \u043C\u044B \u0431\u044B\u0441\u0442\u0440\u043E \u0435\u0451 \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u043C. \u0421\u043F\u0430\u0441\u0438\u0431\u043E!"] }), void 0), _jsx("div", __assign({ className: "tw-link-btn p-4", onClick: function () {
                    props && props.resetErrorBoundary && props.resetErrorBoundary();
                    history.push('/login');
                    window.location.reload();
                } }, { children: "\u0412\u0435\u0440\u043D\u0443\u0442\u044C\u0441\u044F \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E." }), void 0), props && ((_a = props.error) === null || _a === void 0 ? void 0 : _a.message) && (_jsxs("div", __assign({ style: { color: 'red' } }, { children: [_jsx("p", { children: "\u041E\u0448\u0438\u0431\u043A\u0430:" }, void 0), _jsx("p", { children: (_b = props === null || props === void 0 ? void 0 : props.error) === null || _b === void 0 ? void 0 : _b.message }, void 0)] }), void 0))] }), void 0));
};
export default ErrorPage;
