var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
import SportEventTournamentListItem from './SportEventTournamentListItem';
var SportEventTournamentsSettingsPage = function (_a) {
    var _b;
    var sportEventId = _a.sportEventId;
    var _c = useQuery(SPORT_EVENT, {
        variables: {
            id: sportEventId,
            showTournaments: true
        }
    }), data = _c.data, loading = _c.loading;
    var tournaments = (_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.tournaments;
    var dispatch = useDispatch();
    var openCreateSportEventTournamentDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.CreateSportEventTournament,
            dialogProps: {
                sportEventId: sportEventId
            }
        }));
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0442\u0443\u0440\u043D\u0438\u0440\u0430\u043C\u0438" }), void 0) }), void 0), content: _jsxs("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: _jsx(Button, __assign({ type: "button", onClick: openCreateSportEventTournamentDialog }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0442\u0443\u0440\u043D\u0438\u0440" }), void 0) }), void 0), tournaments && (tournaments === null || tournaments === void 0 ? void 0 : tournaments.length) > 0 && (_jsx("div", __assign({ className: "flex flex-col space-y-10" }, { children: tournaments.map(function (tournament) {
                        return _jsx(SportEventTournamentListItem, { tournament: tournament }, tournament.id);
                    }) }), void 0))] }), void 0), innerScroll: false }, void 0));
};
export default SportEventTournamentsSettingsPage;
