var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { CREATE_ACTIVITY_ATTACHMENT } from 'common/graphql/mutations/activityAttachment/create-activity-attachment';
import { ACTIVITY_ATTACHMENTS } from 'common/graphql/queries/activity/activity-attachments';
import UploadFilesButton from 'components/Buttons/UploadFilesButton';
import LoadingFilesForm from 'components/Forms/LoadingFilesForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var AddActivityAttachments = function (_a) {
    var activityId = _a.activityId;
    var t = useTranslation().t;
    var _b = useState(undefined), loadingFiles = _b[0], setLoadingFiles = _b[1];
    var executableMutation = function (file) {
        return useMutation(CREATE_ACTIVITY_ATTACHMENT, {
            variables: {
                createActivityAttachmentData: {
                    activityId: activityId
                },
                file: file
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ACTIVITY_ATTACHMENTS,
                    variables: {
                        id: activityId
                    }
                },
            ]
        });
    };
    return (_jsxs("div", __assign({ className: "flex flex-col items-center" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Добавить вложения к тренировке') }), void 0), _jsx("div", __assign({ className: "flex p-8" }, { children: loadingFiles ? (_jsx(LoadingFilesForm, { formText: t('Загрузка файлов'), loadingFiles: loadingFiles, executableMutation: executableMutation }, void 0)) : (_jsx(UploadFilesButton, { setLoadingFiles: setLoadingFiles, accept: "image/*", multiple: true }, void 0)) }), void 0)] }), void 0));
};
export default AddActivityAttachments;
