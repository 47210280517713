var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { UPDATE_STANDINGS } from 'common/graphql/mutations/standings/update-standings';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import { STANDINGS_COLUMNS } from 'common/graphql/queries/standings/standingsColumns';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Loading from 'components/Loading/Loading';
import { useFormik } from 'formik';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
import useAdministrateStandingsData from '../useAdministrateStandingsData';
import AdditionalStandingsSettings from './components/AdditionalStandingsSettings/AdditionalStandingsSettings';
import AddStandingsColumnButton from './components/AddStandingsColumnButton/AddStandingsColumnButton';
import StandingsColumnsList from './components/StandingsColumnsList/StandingsColumnsList';
var StandingsColumnsSettings = function (_a) {
    var standingsId = _a.standingsId;
    var _b = useLazyQuery(STANDINGS, {
        variables: {
            showBasicFields: true,
            showActivitySettings: true,
            showSport: true,
            showSports: true,
            showColumns: true,
            showSettings: true
        }
    }), getStandings = _b[0], _c = _b[1], standings = _c.data, error = _c.error;
    var _d = useLazyQuery(STANDINGS_COLUMNS), getStandingsColumnsList = _d[0], _e = _d[1], loading = _e.loading, data = _e.data;
    var convertStandingsColumns = useAdministrateStandingsData().convertStandingsColumns;
    //const allColumnsList = useQuery<{ standingsColumns: StandingsColumn[] }>(COLUMNS).data?.standingsColumns;
    var defaultFormState = {
        standingsColumns: []
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validateOnChange: false,
        onSubmit: function () { },
        validateOnBlur: false
    });
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t('Данные сохранены'),
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var updateStandings = function (data) {
        return useMutation(UPDATE_STANDINGS, {
            variables: {
                updateStandingsData: __assign({ id: standingsId }, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: STANDINGS,
                    variables: {
                        id: standingsId,
                        showSettings: true
                    }
                },
            ]
        });
    };
    var handleExecuteMutation = useDynamicMutation(updateStandings, onSuccess, onError).handleExecuteMutation;
    useEffect(function () {
        if (standingsId) {
            getStandingsColumnsList({
                variables: { id: standingsId }
            });
            getStandings({
                variables: { id: standingsId }
            });
        }
    }, [standingsId]);
    useEffect(function () {
        if ((data === null || data === void 0 ? void 0 : data.standingsColumns) && (data === null || data === void 0 ? void 0 : data.standingsColumns.length) > 0) {
            formik.setFieldValue('standingsColumns', convertStandingsColumns(data === null || data === void 0 ? void 0 : data.standingsColumns));
        }
    }, [data]);
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0441\u0442\u043E\u043B\u0431\u0446\u043E\u0432" }, void 0), content: _jsx("div", { children: _jsxs("div", __assign({ className: "py-8" }, { children: [_jsx("div", __assign({ className: "flex justify-end w-full py-4" }, { children: _jsx(AddStandingsColumnButton, { standingsParticipantType: standings === null || standings === void 0 ? void 0 : standings.standings.participantType, standingsParentType: standings === null || standings === void 0 ? void 0 : standings.standings.parentType, standingsSports: (standings === null || standings === void 0 ? void 0 : standings.standings.sports) || [], standingsId: standingsId }, void 0) }), void 0), _jsx(StandingsColumnsList, { standingsColumns: formik.values['standingsColumns'], setStandingsColumns: function (standingsColumns) {
                            formik.setFieldValue('standingsColumns', standingsColumns);
                        }, standingsId: standingsId, standingsParticipantType: standings === null || standings === void 0 ? void 0 : standings.standings.participantType, standingsParentType: standings === null || standings === void 0 ? void 0 : standings.standings.parentType, standingsSports: (standings === null || standings === void 0 ? void 0 : standings.standings.sports) || [] }, void 0), _jsx("div", __assign({ className: "pt-40" }, { children: (standings === null || standings === void 0 ? void 0 : standings.standings) && (data === null || data === void 0 ? void 0 : data.standingsColumns) && (_jsx(AdditionalStandingsSettings, { standings: standings.standings, standingsColumns: data === null || data === void 0 ? void 0 : data.standingsColumns, handleExecuteMutation: handleExecuteMutation }, void 0)) }), void 0)] }), void 0) }, void 0) }, void 0));
};
export default StandingsColumnsSettings;
