import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import { Sports } from 'common/enums/sport/sports.enum';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
function UseStandingsPageUrlParamsHelper() {
    var _a = useLocation(), search = _a.search, pathname = _a.pathname;
    var history = useHistory();
    // useEffect(() => {
    // 	if (history.action === 'PUSH') {
    // 		return;
    // 	}
    // 	let { hash, pathname } = location;
    // 	if (hash) {
    // 		let element = document.querySelector(hash);
    // 		if (element) {
    // 			element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    // 		}
    // 	}
    // 	//else if (!dontScrollIntoViewOnPaths.includes(pathname)) {
    // 	// 	window.scrollTo(0, 0);
    // 	// }
    // });
    var useQueryParams = function () {
        return useMemo(function () { return new URLSearchParams(search); }, [search]);
    };
    var query = useQueryParams();
    var handleQueryParams = function (params) {
        if (params.length) {
            var searchParams_1 = new URLSearchParams(search);
            params.map(function (_a) {
                var key = _a.key, value = _a.value;
                if (value || value === 0) {
                    searchParams_1.set(key, value);
                }
                else if (query.get(key)) {
                    searchParams_1["delete"](key);
                }
            });
            history.push({
                pathname: pathname,
                search: searchParams_1.toString()
            });
            // var x = window.scrollX;
            // var y = window.scrollY;
            // window.onscroll = function () {
            // 	window.scrollTo(x, y);
            // };
        }
    };
    var getInitialFiltersByUrlParams = function () {
        var sport = query.get('sport');
        var startDate = query.get('startDate');
        var endDate = query.get('endDate');
        var year = query.get('year');
        var month = query.get('month');
        var datRange = query.get('dateRange');
        var search = query.get('search');
        return {
            userNameInput: search || '',
            sport: (sport && [Sports[sport]]) || [Sports.Run],
            range: {
                dateRange: datRange || DateRangeFilterItems.allTime,
                year: (year && parseInt(year)) || undefined,
                month: ((month || month === 0) && parseInt(month)) || undefined,
                period: {
                    startDate: (typeof startDate === 'string' && !isNaN(Date.parse(startDate)) && new Date(startDate)) ||
                        undefined,
                    endDate: (typeof endDate === 'string' && !isNaN(Date.parse(endDate)) && new Date(endDate)) || undefined
                }
            }
        };
    };
    return {
        query: query,
        handleQueryParams: handleQueryParams,
        getInitialFiltersByUrlParams: getInitialFiltersByUrlParams
    };
}
export default UseStandingsPageUrlParamsHelper;
