import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
var AddClubAdminRoleCheckbox = function (_a) {
    var value = _a.value, name = _a.name, onChange = _a.onChange, disabled = _a.disabled, userId = _a.userId;
    return (_jsx(FormControlLabel, { label: "\u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440", control: _jsx(Checkbox, { name: name, disabled: disabled, checked: value, onChange: function (e) {
                onChange(e, userId);
            } }, void 0) }, void 0));
};
export default AddClubAdminRoleCheckbox;
