var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { FeatureTypeIds } from 'common/const/feature-ids.const';
import { TournamentFeatureActionTarget } from 'common/enums/tournament/tournament-feature-action-target.enum';
import { TournamentFeatureActionType } from 'common/enums/tournament/tournament-feature-action-type.enum';
import { TOURNAMEN_FEATURE_RO_ACTION } from 'common/graphql/queries/tournament/perform-tournament-feature-read-only-action';
import Loading from 'components/Loading/Loading';
import BoosterWithPriceListItem from './BoosterWithPriceListItem';
var VirtualPointsBuyBoostersDialog = function (_a) {
    var _b;
    var teamId = _a.teamId, tournamentId = _a.tournamentId, teamCoins = _a.teamCoins, isCanBuy = _a.isCanBuy;
    var _c = useQuery(TOURNAMEN_FEATURE_RO_ACTION, {
        variables: {
            performTournamentFeatureActionData: {
                actionTarget: TournamentFeatureActionTarget.Team,
                actionTargetId: teamId,
                actionType: TournamentFeatureActionType.VirtualPointsGetTeamBoosterPrices,
                tournamentId: tournamentId,
                featureId: FeatureTypeIds.virtualPoints
            }
        }
    }), data = _c.data, loading = _c.loading;
    var pricesData = ((_b = data === null || data === void 0 ? void 0 : data.performTournamentFeatureReadOnlyAction) === null || _b === void 0 ? void 0 : _b.actionData) || [];
    if (loading)
        return _jsx(Loading, {}, void 0);
    return pricesData.length > 0 ? (_jsx("div", __assign({ className: "flex flex-col space-y-40" }, { children: pricesData.map(function (boosterPriceObj) {
            return (_jsx(BoosterWithPriceListItem, { teamId: teamId, tournamentId: tournamentId, boosterName: boosterPriceObj.boosterName, price: boosterPriceObj.intotal, teamCoins: teamCoins, isCanBuy: isCanBuy }, boosterPriceObj.boosterName));
        }) }), void 0)) : (_jsx("p", { children: "\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F \u0434\u0430\u043D\u043D\u044B\u0445" }, void 0));
};
export default VirtualPointsBuyBoostersDialog;
