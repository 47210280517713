var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { TEAM_CONTACTS } from 'common/graphql/queries/team/team-contacts';
import Button from 'components/Buttons/Button';
import Loading from 'components/Loading/Loading';
import SettingsForm from 'main/pages/SettingsPage/components/SettingsForm/SettingsForm';
import { useDispatch } from 'react-redux';
import { openDialog } from 'store/actions';
import TeamContactData from './TeamContactData';
var TeamContactsSettingsPage = function (_a) {
    var teamId = _a.teamId;
    var dispatch = useDispatch();
    var _b = useQuery(TEAM_CONTACTS, {
        variables: {
            teamId: teamId
        }
    }), data = _b.data, loading = _b.loading;
    var teamContacts = data === null || data === void 0 ? void 0 : data.teamContacts;
    var openCreateTeamContactDialog = function () {
        dispatch(openDialog({
            dialogType: DialogTypes.CreateTeamContact,
            dialogProps: {
                teamId: teamId
            }
        }));
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(SettingsForm, { header: _jsx(_Fragment, { children: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B \u043A\u043E\u043C\u0430\u043D\u0434\u044B" }, void 0), content: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [teamContacts && teamContacts.length > 0 && (_jsx("div", __assign({ className: "flex flex-col" }, { children: teamContacts.map(function (teamContact) {
                        return (_jsx("div", __assign({ className: "flex" }, { children: _jsx(TeamContactData, { teamContact: teamContact, teamId: teamId }, teamContact.id) }), teamContact.id));
                    }) }), void 0)), _jsx("div", { children: _jsx(Button, __assign({ className: "xs:my-12", type: "primary", onClick: openCreateTeamContactDialog }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), void 0) }, void 0)] }), void 0) }, void 0));
};
export default TeamContactsSettingsPage;
