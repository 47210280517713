import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
var JoinClubViaInvitationLinkCheckbox = function (_a) {
    var value = _a.value, name = _a.name, onChange = _a.onChange, disabled = _a.disabled;
    var t = useTranslation().t;
    return (_jsx(FormControlLabel, { label: t('Вступление через ссылку-приглашение'), control: _jsx(Checkbox, { name: name, disabled: disabled, checked: value, onChange: onChange }, void 0) }, void 0));
};
export default JoinClubViaInvitationLinkCheckbox;
