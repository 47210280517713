var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery } from '@apollo/client';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { ActivityValidationStates } from 'common/enums/activity/activity-validation-states.enum';
import { ActivityValidationTypes } from 'common/enums/activity/activity-validation-types.enum';
import { ACTIVITY_VALIDATION_SPORT_EVENT } from 'common/graphql/queries/activity-validations/activity-validation-sport-event';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
var ValidationStatusTooltip = function (_a) {
    var validationId = _a.validationId, validationLevel = _a.validationLevel, text = _a.text, color = _a.color, isShowSeeDetailsText = _a.isShowSeeDetailsText;
    var t = useTranslation().t;
    var _b = useState(text), tooltipTitle = _b[0], setTooltipTitle = _b[1];
    var _c = useState(false), openValidationStatusTooltip = _c[0], setOpenValidationStatusTooltip = _c[1];
    var _d = useLazyQuery(ACTIVITY_VALIDATION_SPORT_EVENT), getValidationSportEvent = _d[0], data = _d[1].data;
    useEffect(function () {
        if (validationLevel === ActivityValidationLevels.SportEvent && validationId) {
            getValidationSportEvent({
                variables: {
                    id: validationId
                }
            });
        }
    }, [validationLevel]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        if ((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.activityValidation) === null || _a === void 0 ? void 0 : _a.sportEvent) === null || _b === void 0 ? void 0 : _b.mainSettings) === null || _c === void 0 ? void 0 : _c.displayName) {
            setTooltipTitle(tooltipTitle +
                '. ' +
                t('Событие') +
                ': ' +
                ((_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.activityValidation) === null || _d === void 0 ? void 0 : _d.sportEvent) === null || _e === void 0 ? void 0 : _e.mainSettings) === null || _f === void 0 ? void 0 : _f.displayName));
        }
    }, [data]);
    var title = isShowSeeDetailsText ? tooltipTitle + '. ' + t('Подробности на странице тренировки.') : tooltipTitle;
    return (_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenValidationStatusTooltip(true); }, onMouseLeave: function () { return setOpenValidationStatusTooltip(false); }, onClose: function () { return setOpenValidationStatusTooltip(false); }, open: openValidationStatusTooltip, title: title, leaveTouchDelay: 10000, leaveDelay: 700 }, { children: _jsx(CircleIcon, { style: { fontSize: '14' }, sx: { color: color } }, void 0) }), void 0));
};
var ValidationStatus = function (_a) {
    var validations = _a.validations, isShowTapText = _a.isShowTapText, isShowSeeDetailsText = _a.isShowSeeDetailsText;
    var t = useTranslation().t;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('md'));
    var getState = function (validation) {
        var levelText = validation.validationLevel === ActivityValidationLevels.Base
            ? ' ' + t('на уровне вида спорта')
            : validation.validationLevel === ActivityValidationLevels.SportEvent
                ? ' ' + t('на уровне вида события')
                : '';
        if (!validation) {
            return {
                color: yellow[800],
                text: t('Тренировка ожидает валидации, проверьте что задан вид спорта')
            };
        }
        if (validation.approved === false && validation.validationState === ActivityValidationStates.PENDING) {
            return {
                color: yellow[600],
                text: t('Тренировка ожидает валидации')
            };
        }
        if (validation.approved === true && validation.validationState === ActivityValidationStates.PENDING) {
            return {
                color: yellow[600],
                text: t('Валидация рассматривается. Изменения ожидают подтверждения администратором.')
            };
        }
        if (validation.approved === false && validation.validationState === ActivityValidationStates.NOT_PASSED) {
            return {
                color: red[400],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t("\u0422\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0430 \u043D\u0435 \u043F\u0440\u043E\u0448\u043B\u0430 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044E") + levelText
                    : t("\u0422\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0430 \u043E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u0430 \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440\u043E\u043C") + levelText
            };
        }
        if (validation.approved && validation.validationState === ActivityValidationStates.PASSED) {
            return {
                color: green[500],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t('Тренировка прошла автоматическую валидацию') + levelText
                    : t('Тренировка зачтена администратором') + levelText
            };
        }
        if (validation.approved && validation.validationState === ActivityValidationStates.PARTIALLY_PASSED) {
            return {
                color: yellow[500],
                text: validation.validationType === ActivityValidationTypes.Auto
                    ? t('Тренировка скорректирована и зачтена автоматически') + levelText
                    : t('Тренировка скорректирована и зачтена администратором') + levelText
            };
        }
        if (validation.validationState === ActivityValidationStates.DECLINED) {
            return {
                color: green[800],
                text: t('Выполнение этой валидации администратором не требуется, актуальная валидация подтверждена')
            };
        }
        return {
            color: '',
            text: ''
        };
    };
    var filteredValidations = validations.filter(function (validation) { return !validation.endDate; }) || [];
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-5 xs:items-start md:items-center" }, { children: [filteredValidations.length > 0 && (_jsx("div", __assign({ className: "flex flex-row space-x-5 items-center" }, { children: filteredValidations.map(function (validation) {
                    return (_jsx(ValidationStatusTooltip, __assign({ validationId: validation.id, validationLevel: validation.validationLevel, isShowSeeDetailsText: isShowSeeDetailsText }, getState(validation)), validation.id));
                }) }), void 0)), isMobile && isShowTapText && (_jsx("p", __assign({ className: "text-grey text-12" }, { children: t('Наведите на кружок, чтобы увидеть подробности') }), void 0))] }), void 0));
};
export default ValidationStatus;
