var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@apollo/client';
import { appHost, appScheme } from 'common/appURLSetup';
import { SPORT_EVENT } from 'common/graphql/queries/sportEvent/sportEvent';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
var SportEventParticipantionSettingsPage = function (_a) {
    var _b;
    var sportEventId = _a.sportEventId;
    var _c = useQuery(SPORT_EVENT, {
        variables: {
            id: sportEventId
        }
    }), data = _c.data, loading = _c.loading;
    var eventId = (_b = data === null || data === void 0 ? void 0 : data.sportEvent) === null || _b === void 0 ? void 0 : _b.id;
    if (loading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430\u043C\u0438" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: eventId && (_jsxs("div", __assign({ className: "flex flex-col space-y-10" }, { children: [_jsx("p", { children: "\u041F\u0443\u0431\u043B\u0438\u0447\u043D\u0430\u044F \u0441\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F \u0432\u0445\u043E\u0434\u0430 \u0432 \u0441\u043E\u0431\u044B\u0442\u0438\u0435:" }, void 0), _jsx("p", { children: "".concat(appScheme, "://").concat(appHost, "/event-join/").concat(eventId) }, void 0)] }), void 0)) }), void 0), innerScroll: false }, void 0));
};
export default SportEventParticipantionSettingsPage;
