var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from '@mui/material';
import userValidationSchema from 'common/validationSchemas/userValidationSchema';
import PasswordField from 'components/Fields/PasswordField';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { saveNewPassword } from 'store/actions/';
var InputNewPasswordForm = function (props) {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var verificationKey = props === null || props === void 0 ? void 0 : props.verificationKey;
    var verificationId = props === null || props === void 0 ? void 0 : props.verificationId;
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var defaultFormState = {
        password: '',
        confirmPassword: ''
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: userValidationSchema.pick(Object.keys(defaultFormState)),
        validateOnChange: false,
        onSubmit: function (value) {
            dispatch(saveNewPassword({
                password: value.password,
                verificationId: verificationId,
                key: verificationKey
            }));
        },
        validateOnBlur: false
    });
    return (_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '32rem' } }, { children: [_jsx(Box, __assign({ sx: { mb: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' } }, { children: _jsx(Typography, __assign({ sx: { fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 500, lineHeight: 1 } }, { children: t('Введите новый пароль') }), void 0) }), void 0), _jsx(Box, __assign({ sx: { width: '32rem' } }, { children: _jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }, { children: [_jsx(PasswordField, { id: "password", title: t('Пароль'), value: formik.values['password'], name: "password", onChange: handleChangeWrapper, isError: Boolean(formik.errors['password']), helperText: formik.errors['password'] }, void 0), _jsx(PasswordField, { id: "confirmPassword", title: t('Подтвердите пароль'), value: formik.values['confirmPassword'], name: "confirmPassword", onChange: handleChangeWrapper, isError: Boolean(formik.errors['confirmPassword']), helperText: formik.errors['confirmPassword'] }, void 0)] }), void 0), _jsx(Box, __assign({ sx: { width: '32rem', mt: '1rem' } }, { children: _jsx(Button, __assign({ sx: { width: '32rem' }, type: "submit" }, { children: t('Продолжить0') }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
};
export default InputNewPasswordForm;
