var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import { DateRangeFilterItems } from 'common/enums/components/date-range-filter-items.enum';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
var ConvertedDateRange = function (_a) {
    var setEditDateRange = _a.setEditDateRange, activityStartDate = _a.activityStartDate, activityEndDate = _a.activityEndDate, filterBy = _a.filterBy;
    var isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    var monthNames = i18n.language === 'en'
        ? [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'Septemper',
            'October',
            'November',
            'December',
        ]
        : [
            'января',
            'февраля',
            'марта',
            'ареля',
            'мая',
            'июня',
            'июля',
            'августа',
            'сентября',
            'октября',
            'ноября',
            'декабря',
        ];
    var convertDate = function () {
        var _a, _b, _c, _d;
        if (activityStartDate) {
            var activityStart = activityStartDate ? new Date(activityStartDate) : new Date();
            var filterByYear = filterBy.year;
            var filterByMonth = filterBy.month;
            var isFilterByCurrentYear = new Date().getFullYear() === filterByYear;
            var isFilterByCurrentMonth = new Date().getMonth() === filterByMonth;
            var isFilterByActivityCreatedYear = activityStart.getFullYear() === filterByYear;
            var startEndDate = void 0;
            switch (filterBy.dateRange) {
                case DateRangeFilterItems.allTime: {
                    startEndDate = startEndDateToString(activityStart, _.max([
                        activityEndDate ? _.min([new Date(activityEndDate), new Date()]) : new Date(),
                        activityStart,
                    ]));
                    return startEndDate;
                }
                case DateRangeFilterItems.year: {
                    //1. за год в котором активность была создана x=activityStart y=new Date(filterByYear,11 , 31)
                    //2. за год в котором активность была создана в текущем x=activityStart x=activityStart y=new Date()
                    //3. за текущий год x=new Date(filterByYear, 0, 1) y=new Date()
                    //4. начало и конец любого года x=new Date(filterByYear, 0, 1),  y=new Date(filterByYear, 11, 31),
                    if (filterByYear) {
                        var x = isFilterByActivityCreatedYear ? activityStart : new Date(filterByYear, 0, 1);
                        var y = isFilterByCurrentYear ? new Date() : new Date(filterByYear, 11, 31);
                        startEndDate = startEndDateToString(x, _.max([x, y]));
                    }
                    return startEndDate;
                }
                case DateRangeFilterItems.month: {
                    //1. за месяц в котором активность была создана x=activityStart y=new Date(filterByYear, filterByMonth + 1, 0)
                    //2. за текущий месяц x=new Date(filterByYear, filterByMonth, 1) y=new Date()
                    //3. дата создания активности в текущем месяце  x=activityStart y=new Date()
                    //4. начало и конец любого месяца x=new Date(filterByYear, filterByMonth, 1),  y=new Date(filterByYear, filterByMonth + 1, 0),
                    if (typeof filterByYear === 'number' && typeof filterByMonth === 'number') {
                        var x = isFilterByActivityCreatedYear && activityStart.getMonth() === filterByMonth
                            ? activityStart
                            : new Date(filterByYear, filterByMonth, 1);
                        var y = isFilterByCurrentMonth && isFilterByCurrentYear
                            ? activityEndDate
                                ? _.min([new Date(activityEndDate), new Date()]) || new Date()
                                : new Date()
                            : activityEndDate && new Date(activityEndDate).getMonth() === filterByMonth
                                ? new Date(activityEndDate)
                                : new Date(filterByYear, filterByMonth + 1, 0);
                        startEndDate = startEndDateToString(x, _.max([x, y]));
                    }
                    return startEndDate;
                }
                case DateRangeFilterItems.period: {
                    var x = ((_a = filterBy === null || filterBy === void 0 ? void 0 : filterBy.period) === null || _a === void 0 ? void 0 : _a.startDate) ? new Date((_b = filterBy === null || filterBy === void 0 ? void 0 : filterBy.period) === null || _b === void 0 ? void 0 : _b.startDate) : new Date();
                    var y = ((_c = filterBy === null || filterBy === void 0 ? void 0 : filterBy.period) === null || _c === void 0 ? void 0 : _c.endDate)
                        ? new Date((_d = filterBy === null || filterBy === void 0 ? void 0 : filterBy.period) === null || _d === void 0 ? void 0 : _d.endDate)
                        : activityEndDate
                            ? _.min([new Date(activityEndDate), new Date()]) || new Date()
                            : new Date();
                    startEndDate = startEndDateToString(x, _.max([x, y]));
                    return startEndDate;
                }
                default: {
                    return 'Todo';
                }
            }
        }
    };
    var startEndDateToString = function (startDate, endDate) {
        var convertMonth = function (month) {
            return ++month < 10 ? "0".concat(month) : "".concat(month);
        };
        var convertDate = function (date, isEndDate, sameYear) {
            var month = isMobile ? convertMonth(date.getMonth()) : monthNames[date.getMonth()];
            var day = date.getDate();
            var year = date.getFullYear();
            if (isEndDate) {
                return isMobile ? "- ".concat(day, ".").concat(month, ".").concat(year) : "- ".concat(day, " ").concat(month, " ").concat(year);
            }
            else if (sameYear && !isEndDate) {
                return isMobile ? "".concat(day, ".").concat(month, " ").concat(year) : "".concat(day, " ").concat(month);
            }
            else if (date) {
                return isMobile ? "".concat(day, ".").concat(month, ".").concat(year) : "".concat(day, " ").concat(month, " ").concat(year);
            }
        };
        var isSameYear = startDate.getFullYear() === (endDate === null || endDate === void 0 ? void 0 : endDate.getFullYear());
        var start = convertDate(startDate, false, isSameYear);
        var end = endDate ? convertDate(endDate, true, isSameYear) : '';
        return "".concat(start, " ").concat(end);
    };
    return (_jsxs("div", __assign({ className: "tw-date-range-container", onClick: function (event) {
            setEditDateRange(event.currentTarget);
        } }, { children: [convertDate(), _jsx(ExpandMoreIcon, { className: "xs:ml-4 xs:w-11 xs:h-12 xs:w-12 md:ml-8 md:w-18 md:h-18" }, void 0)] }), void 0));
};
export default ConvertedDateRange;
