var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@apollo/client';
import { DialogActions, TextField, Typography } from '@mui/material';
import { ActivityValidationLevels } from 'common/enums/activity/activity-validation-levels.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { REQUEST_ACTIVITY_VALIDATION } from 'common/graphql/mutations/activity/activityValidation/requestActivityValidation';
import { USER_ACTIVITY_VALIDATION_REQUESTS } from 'common/graphql/queries/activity-validations/userActivityValidationRequests';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import activityValidationSchema from 'common/validationSchemas/activityValidationSchema';
import Button from 'components/Buttons/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var RequestActivityValidationDialog = function (_a) {
    var activity = _a.activity, handleClose = _a.handleClose;
    var defaultFormState = {
        activityId: activity.id,
        userMessage: '',
        level: ActivityValidationLevels.Base
    };
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var requestActivityValidation = function (data) {
        return useMutation(REQUEST_ACTIVITY_VALIDATION, {
            variables: {
                requestActivityValidationData: __assign({}, data)
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: USER_ACTIVITY_VALIDATION_REQUESTS,
                    variables: {
                        activityId: activity.id,
                        onlyActive: true
                    }
                },
            ]
        });
    };
    var handleChangeWrapper = function (e) {
        formik.setFieldError(e.target.name, undefined); // <- reset error on the field
        formik.handleChange(e); // <- call original Formik's change handler
    };
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: t("\u0417\u0430\u043F\u0440\u043E\u0441 \u043D\u0430 \u0432\u0430\u043B\u0438\u0434\u0430\u0446\u0438\u044E \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043A\u0438 \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440\u043E\u043C \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D"),
            severity: SnackbarSeverity.SUCCESS
        }));
        handleClose();
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(requestActivityValidation, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        //Todo
        handleExecuteMutation(__assign({}, data));
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: activityValidationSchema.pick(Object.keys(defaultFormState)),
        onReset: handleClose,
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    return (_jsx("form", __assign({ onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: _jsxs("div", __assign({ className: "flex flex-col space-y-5 p-0 pb-20 max-w-320" }, { children: [_jsx("div", __assign({ className: "tw-dialog-header" }, { children: t('Запросить валидацию тренировки администратором') }), void 0), _jsx(Typography, __assign({ className: "text-16 text-center pb-20 text-grey" }, { children: t('requestActivityValidationConfirm') }), void 0), _jsx(TextField, { className: "", label: t('Описание запроса'), name: "userMessage", value: formik.values['userMessage'], onChange: handleChangeWrapper, error: Boolean(formik.errors['userMessage']), helperText: t(formik.errors['userMessage'] || ''), multiline: true }, void 0), _jsxs(DialogActions, __assign({ className: "flex justify-between p-0" }, { children: [_jsx(Button, __assign({ type: "reset", onClick: handleClose }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ isSubmitButton: true, type: "primary sm" }, { children: t('Подтвердить') }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default RequestActivityValidationDialog;
