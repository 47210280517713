import { InMemoryCache } from '@apollo/client';
import { athletesSearchPageVar } from './reactivities/athletesSearchPageVar';
import { clubsPageVar } from './reactivities/clubsPageVar';
import { standingsPageVar } from './reactivities/standingsPageVar';
import { statsPageVar } from './reactivities/statsPageVar';
import { tournamentPageVar } from './reactivities/tournamentPageVar';
import { tournamentStandingsPageVar } from './reactivities/tournamentStandingsPageVar';
import { userActivitiesDataVar } from './reactivities/userActivities';
import { userProfileDataVar } from './reactivities/userProfileDataVar';
import { userSportClubDataVar } from './reactivities/userSportClubDataVar';
import { clubSettingsPageVar } from './reactivities/clubSettingsPageVar';
import { userProfilePageVar } from './reactivities/userProfilePageVar';
export var cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                athletesSearchPage: {
                    read: function () {
                        return athletesSearchPageVar();
                    }
                },
                clubsPage: {
                    read: function () {
                        return clubsPageVar();
                    }
                },
                clubSettings: {
                    read: function () {
                        return clubSettingsPageVar();
                    }
                },
                statsPage: {
                    read: function () {
                        return statsPageVar();
                    }
                },
                standingsPage: {
                    read: function () {
                        return standingsPageVar();
                    }
                },
                tournamentPage: {
                    read: function () {
                        return tournamentPageVar();
                    }
                },
                tournamentStandingsPage: {
                    read: function () {
                        return tournamentStandingsPageVar();
                    }
                },
                userProfileData: {
                    read: function () {
                        return userProfileDataVar();
                    }
                },
                userProfilePage: {
                    read: function () {
                        return userProfilePageVar();
                    }
                },
                userSportClubData: {
                    read: function () {
                        return userSportClubDataVar();
                    }
                },
                displayPreferences: {
                    read: function () {
                        return userProfileDataVar();
                    }
                },
                userActivities: {
                    read: function () {
                        return userActivitiesDataVar();
                    }
                }
            }
        },
        User: {
            fields: {
                mainSettings: {
                    merge: true
                }
            }
        }
    }
});
