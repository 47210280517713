var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemText, MenuItem, Popover } from '@mui/material';
import { useState } from 'react';
var TeamParticipantMoreMenu = function (_a) {
    var handleExpelUser = _a.handleExpelUser, handleUpdateTeamUser = _a.handleUpdateTeamUser, isCaptain = _a.isCaptain, userId = _a.userId;
    var _b = useState(null), userMenu = _b[0], setUserMenu = _b[1];
    var userMenuClick = function (event) {
        setUserMenu(event.currentTarget);
    };
    var userMenuClose = function () {
        setUserMenu(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(MoreVertIcon, { color: "disabled", onClick: userMenuClick }, void 0), _jsx(Popover, __assign({ open: Boolean(userMenu), anchorEl: userMenu, onClose: userMenuClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                } }, { children: _jsxs(_Fragment, { children: [_jsx(MenuItem, __assign({ onClick: function () {
                                handleExpelUser(userId);
                                userMenuClose();
                            } }, { children: _jsx(ListItemText, { primary: "\u0418\u0441\u043A\u043B\u044E\u0447\u0438\u0442\u044C" }, void 0) }), void 0), _jsx(MenuItem, __assign({ onClick: function () {
                                handleUpdateTeamUser({ userId: userId, isCaptain: !isCaptain });
                                userMenuClose();
                            } }, { children: _jsx(ListItemText, { primary: isCaptain === true ? 'Отобрать капитанство' : 'Сделать капитаном' }, void 0) }), void 0)] }, void 0) }), void 0)] }, void 0));
};
export default TeamParticipantMoreMenu;
