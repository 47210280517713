var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip } from '@mui/material';
import { appHost, appScheme } from 'common/appURLSetup';
import { DialogTypes } from 'common/enums/components/dialog-types.enum';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { GENERATE_SPORT_CLUB_JOIN_LINK_STRING } from 'common/graphql/mutations/sportClub/generateSportClubJoinLinkString';
import { SPORT_CLUB_JOIN_LINK } from 'common/graphql/queries/sportClub/sportClubJoinLink';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import Loading from 'components/Loading/Loading';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog, openSnackbar } from 'store/actions';
var InvitationLink = function (_a) {
    var _b, _c;
    var sportClubId = _a.sportClubId;
    var _d = useLazyQuery(SPORT_CLUB_JOIN_LINK), getSportClubJoinLinkString = _d[0], _e = _d[1], sportClubWithLink = _e.data, loading = _e.loading, error = _e.error;
    var _f = useState(false), openCopyTextButtonTooltip = _f[0], setOpenCopyTextButtonTooltip = _f[1];
    var _g = useState(false), openRefreshTextButtonTooltip = _g[0], setOpenRefreshTextButtonTooltip = _g[1];
    useEffect(function () {
        if (sportClubId) {
            getSportClubJoinLinkString({
                variables: {
                    id: sportClubId
                }
            });
        }
    }, [sportClubId]);
    var dispatch = useDispatch();
    var onInvitationLinkCopy = function () {
        dispatch(openSnackbar({
            message: 'Ссылка-приглашение успешно скопирована.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onRefreshSuccess = function () {
        dispatch(openSnackbar({
            message: 'Ссылка-приглащение обновлена. Прежняя ссылка более невалидна.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code;
        dispatch(openSnackbar({
            message: code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(function (clubId) {
        return useMutation(GENERATE_SPORT_CLUB_JOIN_LINK_STRING, {
            variables: {
                id: clubId
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: SPORT_CLUB_JOIN_LINK,
                    variables: {
                        id: clubId
                    }
                },
            ]
        });
    }, onRefreshSuccess, onError).handleExecuteMutation;
    var handleRefresh = function () {
        handleExecuteMutation(sportClubId);
    };
    if (loading)
        return _jsx(Loading, {}, void 0);
    var invitationLink = "".concat(appScheme, "://").concat(appHost, "/join-club-link/").concat(sportClubId, "/").concat((_c = (_b = sportClubWithLink === null || sportClubWithLink === void 0 ? void 0 : sportClubWithLink.sportClub) === null || _b === void 0 ? void 0 : _b.mainSettings) === null || _c === void 0 ? void 0 : _c.joinLinkString);
    return (_jsx("div", __assign({ className: "flex flex-wrap items-center" }, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex pb-8" }, { children: [_jsx("div", __assign({ className: "dark:text-grey" }, { children: "\u0421\u0441\u044B\u043B\u043A\u0430-\u043F\u0440\u0438\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u0435:" }), void 0), _jsxs("div", __assign({ className: "flex xs:ml-16" }, { children: [_jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenCopyTextButtonTooltip(true); }, onMouseLeave: function () { return setOpenCopyTextButtonTooltip(false); }, onClose: function () { return setOpenCopyTextButtonTooltip(false); }, open: openCopyTextButtonTooltip, title: 'Копировать' }, { children: _jsx(ContentCopyIcon, { className: "w-24 h-24 xs:mr-8 cursor-pointer", color: "primary", onClick: function () {
                                            navigator.clipboard.writeText(invitationLink);
                                            onInvitationLinkCopy();
                                        } }, void 0) }), void 0), _jsx(Tooltip, __assign({ onMouseOver: function () { return setOpenRefreshTextButtonTooltip(true); }, onMouseLeave: function () { return setOpenRefreshTextButtonTooltip(false); }, onClose: function () { return setOpenRefreshTextButtonTooltip(false); }, open: openRefreshTextButtonTooltip, title: 'Обновить' }, { children: _jsx(RefreshIcon, { className: "w-24 h-24 cursor-pointer", color: "primary", onClick: function () {
                                            dispatch(openDialog({
                                                dialogType: DialogTypes.RefreshInvitationLinkRequestDialog,
                                                dialogProps: { handleRefresh: handleRefresh }
                                            }));
                                        } }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "font-semibold break-all" }, { children: invitationLink }), void 0)] }), void 0) }), void 0));
};
export default InvitationLink;
