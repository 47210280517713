var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DateTimePicker } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { SnackbarSeverity } from 'common/enums/components/snackbar-severity.enum';
import { StandingsParentType } from 'common/enums/standings/standings-parent-type.enum';
import { StandingsParticipantType } from 'common/enums/standings/standings-participant-type.enum';
import { UPDATE_STANDINGS } from 'common/graphql/mutations/standings/update-standings';
import { STANDINGS } from 'common/graphql/queries/standings/standings';
import { TEAM } from 'common/graphql/queries/team/team';
import { TOURNAMENT } from 'common/graphql/queries/tournament/tournament';
import useDynamicMutation from 'common/hooks/useDynamicMutation';
import standingsValidationSchema from 'common/validationSchemas/standingsValidationSchema';
import Loading from 'components/Loading/Loading';
import PageSimple from 'components/PageLayouts/simple/PageSimple';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions';
var StandingsActivitySettings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var standingsId = _a.standingsId;
    var t = useTranslation().t;
    var _p = useState(false), isEditMode = _p[0], setIsEditMode = _p[1];
    var _q = useQuery(STANDINGS, {
        variables: {
            id: standingsId,
            showBasicFields: true,
            showTournament: true,
            showTeam: true,
            showActivitySettings: true
        }
    }), data = _q.data, loading = _q.loading;
    var _r = useLazyQuery(TOURNAMENT), getTournament = _r[0], _s = _r[1], tournamentData = _s.data, tournamentDataLoading = _s.loading;
    var _t = useLazyQuery(TEAM), getTeamData = _t[0], _u = _t[1], teamData = _u.data, teamDataLoading = _u.loading;
    var activitySettings = (_b = data === null || data === void 0 ? void 0 : data.standings) === null || _b === void 0 ? void 0 : _b.activitySettings;
    var tournamentStartDate = (_d = (_c = tournamentData === null || tournamentData === void 0 ? void 0 : tournamentData.tournament) === null || _c === void 0 ? void 0 : _c.datesSettings) === null || _d === void 0 ? void 0 : _d.startDate;
    var tournamentEndDate = (_f = (_e = tournamentData === null || tournamentData === void 0 ? void 0 : tournamentData.tournament) === null || _e === void 0 ? void 0 : _e.datesSettings) === null || _f === void 0 ? void 0 : _f.endDate;
    var defaultFormState = {
        activityStartDate: null,
        activityEndDate: null,
        activityStartDateSinceParticipantJoined: null,
        activityStartDateSinceUserJoinedParticipantEntity: null
    };
    var updateStandings = function (data) {
        return useMutation(UPDATE_STANDINGS, {
            variables: {
                updateStandingsData: {
                    id: standingsId,
                    activitySettings: __assign({}, data)
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: STANDINGS,
                    variables: {
                        id: standingsId,
                        showTournament: true,
                        showActivitySettings: true
                    }
                },
            ]
        });
    };
    var dispatch = useDispatch();
    var onSuccess = function () {
        dispatch(openSnackbar({
            message: 'Турнирная таблица обновлена.',
            severity: SnackbarSeverity.SUCCESS
        }));
    };
    var onError = function (_a) {
        var code = _a.code, errorDetails = _a.errorDetails;
        dispatch(openSnackbar({
            message: errorDetails || code,
            severity: SnackbarSeverity.ERROR
        }));
    };
    var handleExecuteMutation = useDynamicMutation(updateStandings, onSuccess, onError).handleExecuteMutation;
    var handleSubmit = function (data) {
        handleExecuteMutation(__assign({}, data));
        setIsEditMode(false);
    };
    var formik = useFormik({
        initialValues: __assign({}, defaultFormState),
        validationSchema: standingsValidationSchema.pick(Object.keys(defaultFormState)),
        onSubmit: handleSubmit,
        validateOnChange: false,
        validateOnBlur: false
    });
    var setFields = function () {
        if (activitySettings) {
            formik.setFieldValue('activityStartDate', new Date(activitySettings.activityStartDate));
            formik.setFieldValue('activityEndDate', new Date(activitySettings.activityEndDate));
            formik.setFieldValue('activityStartDateSinceParticipantJoined', activitySettings.activityStartDateSinceParticipantJoined);
            formik.setFieldValue('activityStartDateSinceUserJoinedParticipantEntity', activitySettings.activityStartDateSinceUserJoinedParticipantEntity);
        }
    };
    var handleReset = function () {
        setFields();
        setIsEditMode(false);
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        setFields();
        if (((_a = data === null || data === void 0 ? void 0 : data.standings) === null || _a === void 0 ? void 0 : _a.parentType) === StandingsParentType.Tournament && ((_c = (_b = data === null || data === void 0 ? void 0 : data.standings) === null || _b === void 0 ? void 0 : _b.tournament) === null || _c === void 0 ? void 0 : _c.id)) {
            getTournament({
                variables: {
                    id: (_e = (_d = data === null || data === void 0 ? void 0 : data.standings) === null || _d === void 0 ? void 0 : _d.tournament) === null || _e === void 0 ? void 0 : _e.id,
                    showDatesSettings: true
                }
            });
        }
        if (((_f = data === null || data === void 0 ? void 0 : data.standings) === null || _f === void 0 ? void 0 : _f.parentType) === StandingsParentType.Team && ((_h = (_g = data === null || data === void 0 ? void 0 : data.standings) === null || _g === void 0 ? void 0 : _g.team) === null || _h === void 0 ? void 0 : _h.id)) {
            getTeamData({
                variables: {
                    id: (_k = (_j = data === null || data === void 0 ? void 0 : data.standings) === null || _j === void 0 ? void 0 : _j.team) === null || _k === void 0 ? void 0 : _k.id,
                    showTournaments: true
                }
            });
        }
    }, [data]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        if ((_c = (_b = (_a = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _a === void 0 ? void 0 : _a.tournaments) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) {
            getTournament({
                variables: {
                    id: (_f = (_e = (_d = teamData === null || teamData === void 0 ? void 0 : teamData.team) === null || _d === void 0 ? void 0 : _d.tournaments) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.id,
                    showDatesSettings: true
                }
            });
        }
    }, [teamData]);
    if (loading || tournamentDataLoading || teamDataLoading)
        return _jsx(Loading, {}, void 0);
    return (_jsx(PageSimple, { header: _jsx("div", __assign({ className: "h-full tw-page-container-shadow flex justify-between items-center" }, { children: _jsx("div", __assign({ className: "tw-page-container-header-title" }, { children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u043F\u0440\u0438\u0451\u043C\u0430 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043E\u043A" }), void 0) }), void 0), content: _jsx("div", __assign({ className: "flex tw-page-container-shadow flex-col space-y-40 w-full" }, { children: activitySettings && (_jsxs("form", __assign({ className: "flex flex-col max-w-320", onSubmit: formik.handleSubmit, onReset: formik.handleReset }, { children: [_jsx("div", __assign({ className: "max-w-288" }, { children: isEditMode ? (_jsxs("div", __assign({ className: "flex flex-row space-x-10" }, { children: [_jsx(Button, __assign({ type: "reset", color: "secondary", onClick: function () { return handleReset(); } }, { children: t('Отмена') }), void 0), _jsx(Button, __assign({ type: "submit" }, { children: t('Сохранить') }), void 0)] }), void 0)) : (_jsx(Button, __assign({ type: "button", onClick: function () { return setIsEditMode(!isEditMode); } }, { children: t('Редактировать') }), void 0)) }), void 0), _jsxs("div", __assign({ className: "pt-20" }, { children: [_jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430 \u043F\u0440\u0438\u0451\u043C\u0430 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043E\u043A", readOnly: !isEditMode, value: ((_g = formik.values) === null || _g === void 0 ? void 0 : _g.activityStartDate)
                                    ? new Date((_h = formik.values) === null || _h === void 0 ? void 0 : _h.activityStartDate)
                                    : null, minDate: tournamentStartDate ? new Date(tournamentStartDate) : undefined, maxDate: tournamentEndDate ? new Date(tournamentEndDate) : undefined, onChange: function (date) {
                                    formik.setFieldError('activityStartDate', undefined);
                                    formik.setFieldValue('activityStartDate', date);
                                }, renderInput: function (params) {
                                    var _a, _b;
                                    return (_jsx(TextField, __assign({}, params, { error: Boolean((_a = formik.errors) === null || _a === void 0 ? void 0 : _a.activityStartDate), helperText: (_b = formik.errors) === null || _b === void 0 ? void 0 : _b.activityStartDate }), void 0));
                                } }, void 0), _jsx(DateTimePicker, { mask: "__/__/____ __:__", inputFormat: "dd/MM/yyyy HH:mm", label: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F \u043F\u0440\u0438\u0451\u043C\u0430 \u0442\u0440\u0435\u043D\u0438\u0440\u043E\u0432\u043E\u043A", readOnly: !isEditMode, value: ((_j = formik.values) === null || _j === void 0 ? void 0 : _j.activityEndDate) ? new Date((_k = formik.values) === null || _k === void 0 ? void 0 : _k.activityEndDate) : null, minDate: tournamentStartDate ? new Date(tournamentStartDate) : undefined, maxDate: tournamentEndDate ? new Date(tournamentEndDate) : undefined, onChange: function (date) {
                                    formik.setFieldError('activityEndDate', undefined);
                                    formik.setFieldValue('activityEndDate', date);
                                }, renderInput: function (params) {
                                    var _a, _b;
                                    return (_jsx(TextField, __assign({}, params, { error: Boolean((_a = formik.errors) === null || _a === void 0 ? void 0 : _a.activityEndDate), helperText: (_b = formik.errors) === null || _b === void 0 ? void 0 : _b.activityEndDate }), void 0));
                                } }, void 0), _jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { disabled: !isEditMode, checked: ((_l = formik.values) === null || _l === void 0 ? void 0 : _l.activityStartDateSinceParticipantJoined) || false, onChange: function (e) {
                                        formik.setFieldError('activityStartDateSinceParticipantJoined', undefined);
                                        formik.setFieldValue('activityStartDateSinceParticipantJoined', e.target.checked);
                                    } }, void 0), label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430 \u043F\u0440\u0438\u0451\u043C\u0430 \u0441 \u043C\u043E\u043C\u0435\u043D\u0442\u0430 \u0432\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F \u0432 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u0443\u044E \u0442\u0430\u0431\u043B\u0438\u0446\u0443" }, void 0), ((_m = data === null || data === void 0 ? void 0 : data.standings) === null || _m === void 0 ? void 0 : _m.participantType) !== StandingsParticipantType.User && (_jsx(FormControlLabel, { className: "dark:text-white", control: _jsx(Checkbox, { disabled: !isEditMode, checked: ((_o = formik.values) === null || _o === void 0 ? void 0 : _o.activityStartDateSinceUserJoinedParticipantEntity) ||
                                        false, onChange: function (e) {
                                        formik.setFieldError('activityStartDateSinceUserJoinedParticipantEntity', undefined);
                                        formik.setFieldValue('activityStartDateSinceUserJoinedParticipantEntity', e.target.checked);
                                    } }, void 0), label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430 \u043F\u0440\u0438\u0451\u043C\u0430 \u0441 \u043C\u043E\u043C\u0435\u043D\u0442\u0430 \u0432\u0441\u0442\u0443\u043F\u043B\u0435\u043D\u0438\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F \u0432 \u0441\u0443\u0449\u043D\u043E\u0441\u0442\u044C \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430 \u0442\u0443\u0440\u043D\u0438\u0440\u043D\u043E\u0439 \u0442\u0430\u0431\u043B\u0438\u0446\u044B" }, void 0))] }), void 0)] }), void 0)) }), void 0), innerScroll: false }, void 0));
};
export default StandingsActivitySettings;
