import { TeamPaidParticipationTypes } from 'common/enums/team/team-paid-participation-types.enum';
import * as Yup from 'yup';
export var minTournamentDate = new Date();
var teamValidationSchema = Yup.object().shape({
    name: Yup.string()
        .max(20, 'Слишком длинное название. Пожалуйста, введите до 20 символов')
        .required('Не указано уникальное имя')
        .matches(/^([a-zA-Z0-9_]+)$/, 'Уникальное имя может состоять из латинских букв, цифр и подчеркивания')
        .matches(/^([a-zA-Z0-9_]{5,})$/, 'Минимальная длина уникального имени 5 символов')
        .matches(/^(?:[0-9_]*[a-zA-Z]){3}/, 'Уникальное имя должно содержать не менее 3 латинских букв'),
    displayName: Yup.string()
        .trim()
        .min(1, 'Слишком короткое наименование. Пожалуйста, введите от 1 до 128 символов.')
        .max(128, 'Слишком длинное наименование. Пожалуйста, введите от 1 до 128 символов.')
        .required('Не указано наименование команды.'),
    joinCode: Yup.string()
        .trim()
        .min(4, 'Код должен быть не менее 4 символов и не более 12.')
        .max(12, 'Код должен быть не менее 4 символов и не более 12.')
        .matches(/^([a-zA-Z0-9]+)$/, 'Код может состоять из латинских букв и цифр')
        .optional()
        .nullable(),
    maxParticipants: Yup.number().min(0).max(10000).optional().nullable(),
    basicInfoIsPublicAvailable: Yup.boolean().optional().nullable(),
    participationConditionsConfig: Yup.object()
        .shape({
        isPaidParticipation: Yup.boolean().required(),
        paidParticipationConfig: Yup.object()
            .shape({
            type: Yup.mixed()
                .oneOf(Object.values(TeamPaidParticipationTypes))
                .when('isPaidParticipation', {
                is: true,
                then: Yup.string().required('Тип стоимости')
            }),
            paidParticipationInfoText: Yup.string()
                .trim()
                .min(1, 'Слишком короткий текст')
                .max(512, 'Слишком длинный текст')
                .when('isPaidParticipation', {
                is: true,
                then: Yup.string().required('Описание платного участия')
            }),
            price: Yup.number()
                .min(1, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .when('type', {
                is: TeamPaidParticipationTypes.fixedPrice,
                then: Yup.number().required('Необходимо задать значение')
            }),
            prices: Yup.array()
                .of(Yup.number().min(1, 'Значение от 1 до 100000').max(100000, 'Значение от 1 до 100000'))
                .when('type', {
                is: TeamPaidParticipationTypes.descretePrices,
                then: Yup.array().required('Необходимо задать значение')
            }),
            min: Yup.number()
                .min(1, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .when('type', {
                is: TeamPaidParticipationTypes.minMaxPrice,
                then: Yup.number().required('Необходимо задать значение')
            }),
            max: Yup.number()
                .min(1, 'Значение от 1 до 100000')
                .max(100000, 'Значение от 1 до 100000')
                .when('type', {
                is: TeamPaidParticipationTypes.minMaxPrice,
                then: Yup.number().required('Необходимо задать значение')
            })
        })
            .nullable()["default"]({})
            .optional()
    })
        .nullable()["default"]({})
        .optional()
});
export default teamValidationSchema;
