var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InstagramContact from 'assets/icons/contacts/Instagram';
import TelegramContact from 'assets/icons/contacts/telegram';
import VKContact from 'assets/icons/contacts/VK';
import clsx from 'clsx';
import { SocialLinkTypes } from 'common/enums/sport-club/social-link-types.enum';
import ReadOnlyEditor from 'components/DraftEditor/ReadOnlyEditor/ReadOnlyEditor';
import { Link } from 'react-router-dom';
var SportClubDetailsDialog = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var sportClub = _a.sportClub;
    return (_jsxs("div", __assign({ className: "flex flex-col p-0 pb-20 max-w-320 space-y-5" }, { children: [_jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx("div", __assign({ className: "tw-dialog-header" }, { children: sportClub.name }), void 0) }), void 0), sportClub.createdAt && (_jsxs("div", __assign({ className: "flex flex-row space-x-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: ['Дата создания:', "\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: new Date(sportClub.createdAt).toLocaleString() }), void 0)] }), void 0)), ((_b = sportClub.mainSettings) === null || _b === void 0 ? void 0 : _b.description) && ((_c = sportClub.mainSettings) === null || _c === void 0 ? void 0 : _c.description) !== '<p></p>' && (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: ['Информация:', "\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: _jsx(ReadOnlyEditor, { value: sportClub.mainSettings.description }, void 0) }), void 0)] }), void 0)), ((_e = (_d = sportClub.mainSettings) === null || _d === void 0 ? void 0 : _d.rules) === null || _e === void 0 ? void 0 : _e.acceptenceCondition) &&
                ((_g = (_f = sportClub.mainSettings) === null || _f === void 0 ? void 0 : _f.rules) === null || _g === void 0 ? void 0 : _g.acceptenceCondition) !== '<p></p>' && (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: ['Условия принятия:', "\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: _jsx(ReadOnlyEditor, { value: (_j = (_h = sportClub.mainSettings) === null || _h === void 0 ? void 0 : _h.rules) === null || _j === void 0 ? void 0 : _j.acceptenceCondition }, void 0) }), void 0)] }), void 0)), ((_l = (_k = sportClub.mainSettings) === null || _k === void 0 ? void 0 : _k.rules) === null || _l === void 0 ? void 0 : _l.participationCondition) &&
                ((_o = (_m = sportClub.mainSettings) === null || _m === void 0 ? void 0 : _m.rules) === null || _o === void 0 ? void 0 : _o.participationCondition) !== '<p></p>' && (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: ['Условия участия:', "\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: _jsx(ReadOnlyEditor, { value: (_q = (_p = sportClub.mainSettings) === null || _p === void 0 ? void 0 : _p.rules) === null || _q === void 0 ? void 0 : _q.participationCondition }, void 0) }), void 0)] }), void 0)), ((_s = (_r = sportClub.mainSettings) === null || _r === void 0 ? void 0 : _r.rules) === null || _s === void 0 ? void 0 : _s.exclusionCondition) &&
                ((_u = (_t = sportClub.mainSettings) === null || _t === void 0 ? void 0 : _t.rules) === null || _u === void 0 ? void 0 : _u.exclusionCondition) !== '<p></p>' && (_jsxs("div", __assign({ className: "flex flex-col space-y-5" }, { children: [_jsxs("div", __assign({ className: "w-180 text-14 text-gray" }, { children: ['Условия исключения:', "\u00A0"] }), void 0), _jsx("div", __assign({ className: "flex md:max-w-840 items-center dark:text-white" }, { children: _jsx(ReadOnlyEditor, { value: (_w = (_v = sportClub.mainSettings) === null || _v === void 0 ? void 0 : _v.rules) === null || _w === void 0 ? void 0 : _w.exclusionCondition }, void 0) }), void 0)] }), void 0)), (sportClub === null || sportClub === void 0 ? void 0 : sportClub.socialLinks) &&
                ((_x = sportClub === null || sportClub === void 0 ? void 0 : sportClub.socialLinks) === null || _x === void 0 ? void 0 : _x.length) > 0 &&
                sportClub.socialLinks.map(function (socialLink) {
                    return (_jsxs("div", __assign({ className: clsx('flex items-center xs:px-8 py-4') }, { children: [socialLink.type === SocialLinkTypes.Telegram && (_jsx(TelegramContact, { className: "dark:fill-white" }, void 0)), socialLink.type === SocialLinkTypes.VK && _jsx(VKContact, { className: "dark:fill-white" }, void 0), socialLink.type === SocialLinkTypes.Instagram && (_jsx(InstagramContact, { className: "dark:fill-white" }, void 0)), _jsx(Link, __assign({ className: "tw-club-contact-link", to: { pathname: socialLink.url }, target: "_blank" }, { children: socialLink.text }), void 0)] }), socialLink.id));
                })] }), void 0));
};
export default SportClubDetailsDialog;
