var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
var SportTypeSelect = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'sportType' : _b, value = _a.value, errorMessage = _a.errorMessage, disableClearable = _a.disableClearable, handleChange = _a.handleChange, standingsSports = _a.standingsSports;
    var _c = useState(standingsSports), standingsSportsList = _c[0], setStandingsSportsList = _c[1];
    var _d = useState(null), fieldValue = _d[0], setFieldValue = _d[1];
    useEffect(function () {
        if (!!!value && standingsSportsList.length > 0) {
            handleChange(name, standingsSportsList[0]);
        }
        else if (value && standingsSportsList.length > 0) {
            var val = standingsSportsList.find(function (sport) { return sport.name === value; });
            val && setFieldValue(val);
        }
    }, [standingsSportsList, value]);
    return (_jsx(Autocomplete, { options: standingsSportsList || [], value: fieldValue || null, disableClearable: disableClearable, renderInput: function (params) { return (_jsx(TextField, __assign({ name: name, error: !!errorMessage, helperText: errorMessage, label: 'Вид спорта' }, params), void 0)); }, onChange: function (_event, column) {
            if ((column === null || column === void 0 ? void 0 : column.id) || !disableClearable) {
                handleChange(name, column);
            }
        }, getOptionLabel: function (option) { return option.displayName || ''; }, renderOption: function (props, option) { return (_jsx(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: option.displayName }), void 0)); } }, void 0));
};
export default SportTypeSelect;
